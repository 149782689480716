/* ==============
  Account Pages
===================*/

.home-btn {
  position: absolute;
  top: 15px;
  right: 25px;
  z-index: 9;
}
.login-page {
  background: lighten($primary, 40%);
  width: 100%;
  height: 100vh;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin: 0;
  > .row {
    width: 80vw;
    max-width: 65rem;
    max-height: 40rem;
    height: 62vh;
    background: $white;
    box-shadow: 0px 0px 0.5rem 0px rgb(0 0 0 / 15%);
    border-radius: 1.5rem;
    padding: 2rem;
    padding-right: 0 !important;    
    .login-img {
      border-radius: 1.3rem;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      height: 100%;
      background: #ccc;
      img {
        height: 100%;
      }
    }
    .login-main {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      padding: 1rem 0;   
      overflow: auto;   
      .loging-head {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        img {
          max-height: 4.5rem;
        }
      }
      .loging-content {
        width: 90%;
        padding-left: 1.5rem;
        height: auto;
        h2 {
          margin: 0 0 0.5rem 0;
          display: inline-block;
          width: 100%;
          font-size: 2rem;
          color: $primary;
          text-align: center;
        }
        p {
          margin: 0 0 1.5rem;
          padding: 0 0.51rem;
          width: 100%;
          color: #67748e;
          line-height: inherit;
          text-align: center;
        }
        button {
          border-radius: .5rem;
        }
        .btn-link {
          text-decoration: none;
          &:hover, &:focus {
            color: $info;
            text-decoration: underline;
          }
        }
      }
            .form-control {
              -webkit-appearance: auto;
              appearance: auto;
      
              &::placeholder {
                color: #b4b4b4 !important;
                display: block;
              }
            }
    }
  }
  .login-form {
    height: 100%;
    position: relative;
    .form-login {
      width: 90%;
      display: block;
      margin: 1rem auto;
      .form-group {
        display: inline-block;
        position: relative;
        width: 100%;
        margin-bottom: 1.2rem;
        .form-control {
          display: inline-block;
          width: 100%;
          padding: 0.5rem 0.75rem;
          font-size: .875rem;
          font-weight: 400;
          color: #212121;
          height: 3rem;
          background-color: #fff;
          background-clip: padding-box;
          border: 1px solid #cccccc;
          -webkit-appearance: none;
          appearance: none;
          border-radius: 0.5rem;
          transition: box-shadow .15s ease,border-color .15s ease;
          outline: 0;
          &::placeholder {
            color: transparent;
          }
          &:focus, &:-webkit-autofill {
            border-color: $primary;
            -webkit-box-shadow: 0 0 0px 1000px #fafafa inset;
            ~ .form-label {
              position: absolute;
              top: calc(0% - 0.5rem) !important;
              display: block;
              transition: 0.2s;
              font-size: .9rem;
              padding: 0 0.6rem;
              background: #fff;
              color: $primary;
            }
          }
          &:placeholder-shown ~ .form-label {
            cursor: text;
            top: 0.8rem;
            background: #fff;
            margin: 0;
            left: 1rem;
            color: #808080;
            font-weight: 400;
            padding: 0 0.6rem;
          }
        }
        .form-label {
          position: absolute;
          top: 0.8rem;
          left: 1.6rem;
          display: block;
          transition: 0.2s;
          color: #383739;
        }
        .srv-validation-message {
          font-size: 0.75rem;
        }
      }
      .verify_code_input{
        .react-code-input{
          display: flex !important;
          justify-content: space-between;
          input[type=number]::-webkit-inner-spin-button, 
          input[type=number]::-webkit-outer-spin-button { 
              -webkit-appearance: none;
              -moz-appearance: none;
              appearance: none;
              margin: 0; 
          }
          input[type=number]{
            font-size: 1.5rem !important;
            caret-color: #00000066;
            outline: none;
            text-align: center;
            padding: 0 !important;
            &:focus {
              border-color: $primary !important;
            }
          }
        }
      }
      .form-label{
        &:required,&:invalid { box-shadow:none; }
      }
      .custom-control.custom-checkbox {
        margin: 0;
        display: flex;
        align-items: center;
        .custom-control-label {
          line-height: 1.7;
        }
      }
      .forgot_password {
        color: #344767;
        &:hover, &:focus {
          color: #673ab7;
          text-decoration: underline;
        }
      }
      .custom-control-input:focus ~ .custom-control-label::before {
        box-shadow: none !important;
      }
      .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
        border-color: #383739;
      }
      .btn-primary {
        padding: 0.5rem;
        font-size: 1rem;
        border-radius: 5px;
        outline: none;
        box-shadow: none;
        &:hover,&:focus {
          opacity: 0.8;
        }
      }
      .backto_login {
        line-height: normal;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          width: 0.9rem;
        }
      }
    }
    .copy-right {
      padding-top: 0.4rem;      
      p {
        margin: 0;        
      }
    }
    .policy-links {         
      position: absolute;
      bottom: 0.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      left: 0;
      right: 0;    
      a {
        text-decoration: none;
        padding: 0 0.8rem;
        transition: all 0.2s ease;
        color: $primary;
        font-size: 0.9rem;
  
            &:first-child {
              border-right: 1px solid #000;
              text-align: end;
            }
  
            &:hover,
            &:focus {
              text-decoration: underline;
              color: $info;
            }
      }
    }
  }       
}
.account-card{
  .account-card-content{
    padding: 40px;
  }
}


.user-thumb {
  position: relative;
  z-index: 999;
  img {
    height: 88px;
    margin: 0 auto;
    width: 88px;
  }
}

.ex-page-content {
  h1 {
    font-size: 98px;
    font-weight: 500;
    line-height: 150px;
  }
}

.accountbg {
  //background: url("../assets/images/bg.jpg");
  position: absolute;
  background-size: cover;
  background-position: center;
  height: 100%;
  width: 100%;
  top: 0;
}

.account-page-full {
  left: 0;
  position: absolute;
  height: 100%;
  margin: 0;
  width: 420px;
  background-color: $white;
  .logo{
    line-height: 70px;
  }
  .card {
    box-shadow: none;
  }
}



/*===========================================================================================================================*/

/*------------------------------------------------   forntend   -----------------------------------------------------------------*/

/*===========================================================================================================================*/


.front_login-page {
  // background: lighten($primary, 40%);
  background: url(../assets/images/front_login_bg-1.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100vh;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin: 0;
  > .row {
    width: 70vw;
    max-width: 60rem;
    background: $white;
    box-shadow: 0px 0px 0.5rem 0px rgb(0 0 0 / 15%);
    border-radius: 1.5rem;
    padding: 1rem;  
    .login-img {
      border-radius: 1.3rem;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      height: 100%;
      background: #ccc;
      img {
        height: 100%;
      }
    }
      .loging-head {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding-top: 1rem;
        img {
          max-height: 4.5rem;
        }
      }
        h2 {
          margin: 0;
          margin-top: 0.5rem;
          display: inline-block;
          width: 100%;
          font-size: 2rem;
          color: $primary;
          text-align: center;
        }
        p {
          margin: 0 0 1.5rem;
          padding: 0 0.51rem;
          width: 100%;
          color: #67748e;
          line-height: inherit;
          text-align: center;
        }
        button {
          border-radius: .5rem;
        }
        .btn-link {
    }
  }
  .login-form {
    height: 100%;
    .form-login {
      display: block;
      margin: 1rem auto 0;
      .login_member {
        border-radius: 0.5rem;
        background: rgba($primary, 0.05);
        padding: 1.3rem 0.5rem 0;
      }
      .form-group {
        display: inline-block;
        position: relative;
        margin-bottom: 1.2rem;
        .form-control {
          display: inline-block;
          width: 100%;
          padding: 0.5rem 0.75rem;
          font-size: .875rem;
          font-weight: 400;
          color: #212121;
          height: 3rem;
          background-color: #fff;
          background-clip: padding-box;
          border: 1px solid #cccccc;
          -webkit-appearance: none;
          appearance: none;
          border-radius: 0.5rem;
          transition: box-shadow .15s ease,border-color .15s ease;
          outline: 0;
          &::placeholder {
            color: transparent;
          }
          &:focus, &:-webkit-autofill {
            border-color: $primary;
            -webkit-box-shadow: 0 0 0px 1000px #fafafa inset;
            ~ .form-label {
              position: absolute;
              top: calc(0% - 0.5rem) !important;
              display: block;
              transition: 0.2s;
              font-size: .9rem;
              padding: 0 0.6rem;
              background: #fff;
              color: $primary;
            }
          }
          &:placeholder-shown ~ .form-label {
            cursor: text;
            top: 0.8rem;
            background: #fff;
            margin: 0;
            left: 1rem;
            color: #808080;
            font-weight: 400;
            padding: 0 0.6rem;
          }
        }
        .form-label {
          position: absolute;
          top: 0.8rem;
          left: 1.6rem;
          display: block;
          transition: 0.2s;
          color: #383739;
        }
        .srv-validation-message {
          font-size: 0.75rem;
        }
      }
      .verify_code_input{
        .react-code-input{
          display: flex !important;
          justify-content: space-between;
          input[type=number]::-webkit-inner-spin-button, 
          input[type=number]::-webkit-outer-spin-button { 
              -webkit-appearance: none;
              -moz-appearance: none;
              appearance: none;
              margin: 0; 
          }
          input[type=number]{
            font-size: 1.5rem !important;
            caret-color: #00000066;
            outline: none;
            text-align: center;
            padding: 0 !important;
            &:focus {
              border-color: $primary !important;
            }
          }
        }
      }
      .form-label{
        &:required,&:invalid { box-shadow:none; }
      }
      .custom-control.custom-checkbox {
        margin: 0;
        display: flex;
        align-items: center;
        .custom-control-label {
          line-height: 1.7;
        }
      }
      .forgot_password {
        color: #344767;
        &:hover, &:focus {
          color: #673ab7;
          text-decoration: underline;
        }
      }
      .custom-control-input:focus ~ .custom-control-label::before {
        box-shadow: none !important;
      }
      .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
        border-color: #383739;
      }
      .btn-primary {
        padding: 0.5rem;
        font-size: 1rem;
        border-radius: 5px;
        outline: none;
        box-shadow: none;
        &:hover,&:focus {
          opacity: 0.8;
        }
        svg {          
          width: 1.2rem;
          height: 1.2rem;
          margin-right: 0.3rem;
        }
      }
      .backto_login {
        line-height: normal;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          width: 0.9rem;
        }
      }
    }
    .copy-right {
      padding-top: 0.4rem;      
      p {
        margin: 0;        
      }
    }
  }       
}


