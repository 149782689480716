/* ==============
  General
===================*/

body {
  background-repeat: repeat;  
  font-family: $font-family;
  color: #344767;
  font-size: $base-font;
  padding-right: 0 !important;
}

html {
  overflow-x: hidden;
  position: relative;
  min-height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 10px 0;
  font-family: $font-family;
  font-weight: 500;
  color: #344767;
}

p {
  line-height: 1.7;
}

svg {
  max-width: 100%;
}

a {
  color: $gray-600;

  &:hover {
    outline: 0;
    text-decoration: none;
  }
  &:active {
    outline: 0;
    text-decoration: none;
  }
  &:focus {
    outline: 0;
    text-decoration: none;
  }
}

b,
strong {
  font-weight: 600;
}

.container-alt {
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.main-body {
  height: $height;
  // overflow: hidden;
  display: block;
  width: $width;
  // overflow: auto;
  &.backend {
    background: #f4f5ff !important;
    font-family: 'Montserrat';
  }
  &.front_main{
    font-family: 'Montserrat';
  }
}

// Slimscroll
.slimScrollDiv {
  height: auto !important;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: $primary;
}

.task_detail_info {
  p {
    border-bottom: 1px solid lighten($primary, 62%);
    padding-bottom: 0.5rem;
    margin-bottom: 0.5rem;
    b {
      width: 100%;
      display: inline-block;
    }
    .show-more-link {
      color: #00a7b5;
      text-decoration: underline;
      &:hover,
      &:focus {
        color: #9bd732;
      }
    }
  }
}
.task_details {
  background: #eff2f6;
  box-shadow: 0rem 0rem 1rem rgb(0 0 0 / 10%);
  .form-group {
    .form-label {
      background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(239, 242, 246, 1) 100%
      ) !important;
    }
  }
}
.add_tags_field {
  .ReactTags__tagInput {
    .ReactTags__tagInputField {
      background: url(../assets/images/down-arrow.svg) no-repeat center right 0.5rem /
        0.8rem !important;
    }
  }
  .ReactTags__suggestions {
    position: absolute;
    width: 100%;
    left: 0;
    top: 100%;
    z-index: 999;
  }
  .ReactTags__suggestions ul {
    list-style-type: none;
    box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
    background: $white;
    width: 100%;
    padding: 0;
  }
  .ReactTags__suggestions li {
    border-bottom: 1px solid #d2d6da;
    padding: 5px 10px;
    margin: 0;
  }
  .ReactTags__suggestions li mark {
    background: lighten($primary, 50%);
    font-weight: 600;
  }
  .ReactTags__suggestions ul li.ReactTags__activeSuggestion {
    background: rgb(59 130 246 / 0.5);
    color: $white;
    cursor: pointer;
  }
}
.no-floating-label {
  .form-group {
    .form-label {
      position: initial;
      margin: 0 0 0.3rem;
      color: #002E70;
      font-weight: 500;
    }
  }
}

.recent_activity.card {
  background: #ecf0f4;
  .card-header {
    background: #002E70;
    color: #fff;
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: 0.6px;
    padding: 0.6rem 1rem;
    button {
      padding: 0.25rem 0.5rem;
    }
  }
  .card-body {
    padding: 1rem;
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      li {
        display: flex;
        flex-direction: column;
        margin-bottom: 0.5rem;
        line-height: normal;
        label {
          margin: 0;
          color: #002E70;
          width: 100%;
          display: inline-block;
        }
        span {
          color: #6c757d;
        }
      }
    }
  }
}
.card {
  .card-header.task_cardhead {
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
  }
}
.task-head {
  .badge-code {
    background: $bg-body;
    color: $primary;
    padding: 0.1rem 0.4rem;
    line-height: normal;
    font-size: 0.8rem;
    border-radius: 3px;
    border: 1px dashed lighten($primary, 40%);
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
  }
  .badge-sm {
    padding: 0.1em 0.3em;
    font-size: 0.75rem;
    line-height: normal;
    line-height: normal;
    background: lighten($primary, 62%);
    color: $primary;
    border: 1px solid $primary;
  }
  p {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .task-status {
      margin-top: 0.4rem;
      background: $info;
      color: $white;
      padding: 0.1rem 0.4rem;
      line-height: normal;
      font-weight: 500;
      font-size: 0.8rem;
      border-radius: 5px;
      // border: 1px solid $info;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      -ms-border-radius: 5px;
      -o-border-radius: 5px;
    }
    span {
      width: 100%;
      display: flex;
      align-items: center;
      line-height: normal;
      font-size: 1.1rem;
      margin-top: 0.4rem;
      color: #002E70;
    }
  }
}
.nav-tabs {
  background: #fff;
}
.task_card {
  .tab-pane {
    background: #fff;
    // border: 0.8px solid #002E70;
    padding: 1rem;
    border-radius: 12px;
    min-height: 100%;
    > .card {
      // &:first-child {
      //   border-top-right-radius: 0;
      //   border-top-left-radius: 0;
      // }
    }
  }
}
.reporting_tab,
.tree_details_tab,
.work_flow_tab {
  > .card {
    border: none;
    box-shadow: none;
    .card-body {
      padding: 0;
    }
  }
}
.work_flow_tab {
  > .card {
    .card-body {
      padding: 0 1rem;
    }
  }
}
.document_tab {
  .card {
    box-shadow: 0rem 0rem 1rem rgb(0 0 0 / 10%);
    border: none;
    .card-header {
      font-size: 1.5rem;
      font-weight: 500;
      color: #002E70;
      border: none;
      padding-bottom: 0;
    }
  }
  .table-responsive {
    height: auto !important;
  }
}

/*/==========================Tree-View==========================/*/
.tree_details {
  position: relative;
  width: 100%;
  display: inline-block;
  .main-parent {
    font-size: 1rem;
    font-weight: 600;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    background: #002E70;
    color: #fff;
    margin-bottom: 1rem;
    padding: 0.6rem 1rem;
    border-radius: 8px;
    width: 100%;
    z-index: 1;
    h6 {
      color: #fff;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      margin: 0;
      small {
        width: 100%;
        display: inline-block;
      }
    }
    .task-action {
      display: flex;
      align-items: center;
      p {
        margin: 0 -0.3rem 0 0;
        border-radius: 100px;
        height: 2rem;
        width: 2rem;
        font-size: 0.7rem;
        letter-spacing: 0.05rem;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid;
        border-color: #00a7b5;
        background: #00a7b5;
        box-shadow: 0.2rem 0.2rem 0.2rem rgb(0 0 0 / 30%);
        z-index: 0;
        &.submitter {
          margin: 0 1rem 0 0;
          background: #9bd732;
          color: #fff;
          border-color: #9bd732;
        }
        &.approvertwo {
          margin-right: 1rem;
          background: #c24200;
          border-color: #c24200;
          z-index: -1;
        }
      }
      label {
        margin: 0;
        font-size: 0.8rem;
        margin-right: 0.5rem;
        font-weight: normal;
      }
    }
  }
  .tree-parent {
    padding-left: 1rem;
    position: relative;
    &.has-child {
      > .tree-item {
        > .inner-item {
          background: #f6f3fb;
          border: 1px solid #f6f3fb;
          padding: 1rem 2rem 1rem 1rem;
          svg {
            display: inline-block;
            position: absolute;
            width: 1.6rem;
            height: 1.6rem;
            color: #002E70;
            right: 0.5rem;
            top: 50%;
            transform: translateY(-50%) rotate(0deg);
            -webkit-transform: translateY(-50%) rotate(0deg);
            -moz-transform: translateY(-50%) rotate(0deg);
            -ms-transform: translateY(-50%) rotate(0deg);
            -o-transform: translateY(-50%) rotate(0deg);
          }
          b {
            a {
              transition: all 0.3s ease;
              -webkit-transition: all 0.3s ease;
              -moz-transition: all 0.3s ease;
              -ms-transition: all 0.3s ease;
              -o-transition: all 0.3s ease;
              &:hover,
              &:focus {
                cursor: pointer;
                color: #9bd732;
                transition: all 0.3s ease;
                -webkit-transition: all 0.3s ease;
                -moz-transition: all 0.3s ease;
                -ms-transition: all 0.3s ease;
                -o-transition: all 0.3s ease;
              }
            }
          }
        }
      }
    }
    .tree-item {
      position: relative;
      &::before {
        content: '';
        width: 1px;
        height: calc(100% + 1rem);
        background: #ccc;
        position: absolute;
        left: -0.9rem;
        top: -1rem;
        z-index: 0;
      }
      .inner-item {
        padding: 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        margin-bottom: 1rem;
        background: #f5faff;
        border: 1px solid #eee;
        border-radius: 10px;
        &::before {
          content: '';
          height: 1px;
          width: 0.9rem;
          background: #ccc;
          position: absolute;
          left: -0.8rem;
          top: 50%;
          transform: translateY(-50%);
        }
        svg {
          display: none;
        }
        b {
          width: 100%;
          font-size: 1.1rem;
          display: flex;
          flex-direction: column;
          label {
            font-size: 0.8rem;
            margin: 0px 0 2px 0;
            letter-spacing: 0.3px;
            color: #777;
            line-height: normal;
          }
          span {
            font-size: 0.9rem;
            font-weight: normal;
            color: #777;
            line-height: normal;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .task-action {
          display: inline-flex;
          align-items: center;
          p {
            margin: 0 0.2rem;
            flex: none;
            border-radius: 100px;
            height: 2rem;
            width: 2rem;
            font-size: 0.7rem;
            letter-spacing: 0.05rem;
            font-weight: 600;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid;
            border-color: #9bd732;
            background: #9bd732;
            box-shadow: 0.2rem 0.2rem 0.2rem rgb(0 0 0 / 20%);
            z-index: 0;
            &.submitter {
              background: #00a7b5;
              color: #fff;
              border-color: #00a7b5;
            }
          }
          .badge {
            line-height: normal;
            color: #fff;
            margin-left: 0.3rem;
          }
          label {
            margin: 0;
            font-size: 0.8rem;
            margin: 0 0.5rem;
            font-weight: normal;
            white-space: pre;
          }
          .task_icon {
            font-size: 1rem;
            &:hover,
            &:focus {
              i {
                filter: grayscale(1);
                -webkit-filter: grayscale(1);
                transition: all 0.2s ease;
                -webkit-transition: all 0.2s ease;
                -moz-transition: all 0.2s ease;
                -ms-transition: all 0.2s ease;
                -o-transition: all 0.2s ease;
              }
            }
            i {
              transition: all 0.2s ease;
              -webkit-transition: all 0.2s ease;
              -moz-transition: all 0.2s ease;
              -ms-transition: all 0.2s ease;
              -o-transition: all 0.2s ease;
            }
            svg {
              position: relative;
              right: inherit;
              top: inherit;
              width: 1.8rem;
              height: 1.8rem;
              transform: none;
              -webkit-transform: none;
              -moz-transform: none;
              -ms-transform: none;
              -o-transform: none;
            }
          }
        }
      }
    }
  }
}
/*/==========================End-Tree-View==========================/*/
.view_schedule_date {
  .react-datepicker-wrapper {
    .react-datepicker__input-container {
      width: auto;
      &::after {
        right: 0.4rem;
        width: 1.3rem;
        height: 1.3rem;
      }
    }
  }
}
/* Social */
.social-links {
  li {
    a {
      border-radius: 50%;
      display: inline-block;
      height: 30px;
      line-height: 30px;
      width: 30px;
      text-align: center;
    }
  }
}

.float-end {
  float: right;
}
.accordion-main {
  .accordion-action {
    position: absolute;
    right: 3.5rem;
    top: 0.6rem;
  }
  // overflow: hidden;
  .accordion_header {
    &.collapse {
      svg {
        color: $primary;
        transform: rotate(0deg);
        transition: all 0.3s ease;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
      }
    }
    svg {
      width: 1rem;
      height: 1rem;
      color: $info;
      transform: rotate(180deg);
      transition: all 0.3s ease;
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -ms-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
    }
  }
}

.side_slider ~ body {
  overflow: hidden;
}
.modal {
  &.show {
    &.side_slider .modal-dialog {
      transform: translateX(0%) !important;
      transition: all 0.3s ease;
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -ms-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
    }
  }
  &.side_slider {
    padding: 0 !important;
    .modal-dialog {
      margin: 0;
      float: right;
      transform: translateX(100%) !important;
      height: 100vh;
      max-width: 600px;
      max-height: none;
      transition: all 0.3s ease;
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -ms-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0;
        width: 40rem;
        .modal-header {
          border-radius: 0;
          -webkit-border-radius: 0;
          -moz-border-radius: 0;
          -ms-border-radius: 0;
          -o-border-radius: 0;
        }
        .modal-body {
          padding: 1rem;
          overflow-y: auto;
          overflow-x: hidden;
          .activity-modal-box {
            width: 100%;
            margin: 0;
            display: inline-block;
            // float: left;
            margin-top: 0.6rem;
            position: relative;
            &:first-child {
              margin-top: 0;
            }
            > h6 {
              color: $primary;
              font-size: 0.9rem;
              position: sticky;
              margin: 0;
              left: 0;
              top: -1rem;
              padding: 0.2rem 0;
              background-color: $white;
              z-index: 1;
              span {
                background: $white;
                padding: 0 0.5rem 0 0;
                position: relative;
                z-index: 1;
              }
              &::after {
                content: '';
                display: block;
                width: 100%;
                height: auto;
                border: 1px dashed $primary;
                position: absolute;
                left: 10%;
                top: 50%;
                transform: translateY(-50%);
              }
            }
            ul {
              padding: 0;
              margin: 0.6rem 0 0;
              li {
                list-style: none;
                font-size: 1.5rem;
                color: #000;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                gap: 1.2rem;
                margin-bottom: 1rem;
                .activity-inner-box {
                  background-color: #f4f5f7;
                  box-shadow: 0 0 0.3rem #0003;
                  // border: 1px solid #cccccc;
                  border-radius: 10px;
                  padding: 0.6rem;
                  display: flex;
                  justify-content: left;
                  align-items: flex-start;
                  gap: 0.8rem;
                  flex: auto;
                  border: 1px solid #ababab;
                  .activity-user-icon {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex: none;
                    width: 32px;
                    height: 32px;
                    border-radius: 30px;
                    background-color: $info;
                    color: $white;
                    svg {
                      width: 1.3rem;
                    }
                  }
                  > div {
                    line-height: 0;
                    display: inline-flex;
                    flex-direction: column;
                    align-items: flex-start;
                    position: relative;
                    .date-box {
                      position: absolute;
                      top: 0;
                      right: 0;
                      color: $muted;
                      font-size: 0.8rem;
                      width: auto;
                      display: inline-block;
                    }
                    b {
                      font-size: 1rem;
                      margin: 0;
                      color: $primary;
                      font-weight: 600;
                      line-height: normal;
                      display: -webkit-box;
                      -webkit-line-clamp: 3;
                      -webkit-box-orient: vertical;
                      overflow: hidden;
                    }
                    > span {
                      display: inline-block;
                      color: #868686;
                      margin: 0rem;
                      line-height: normal;
                      font-size: 0.8rem;
                      width: 100%;
                      display: -webkit-box;
                      -webkit-line-clamp: 3;
                      -webkit-box-orient: vertical;
                      overflow: hidden;
                      &.badge {
                        width: auto;
                        color: #fff;
                        padding: 0.1rem 0.4rem;
                        &.bg-pending {
                          background-color: $primary;
                        }
                        &.bg-approve {
                          background-color: $success;
                        }
                      }
                    }
                    .keyword-list {
                      display: flex;
                      gap: 0.5rem;
                      margin-top: 0.4rem;
                      justify-content: flex-start;
                      vertical-align: middle;
                      .new_keyword {
                        width: auto;
                        margin: 0;
                        .badge-key {
                          border-radius: 0.9rem;
                          font-size: 0.88rem;
                          width: auto;
                          color: #000000a1;
                          background-color: #00a7b54a;
                        }
                      }
                      .delete_keyword {
                        width: auto;
                        margin: 0;
                        .badge-key {
                          border-radius: 0.9rem;
                          font-size: 0.88rem;
                          width: auto;
                          color: #000000a1;
                          background-color: #de002e30;
                          position: relative;
                          &::before {
                            content: '';
                            width: 80%;
                            height: 0.1rem;
                            background-color: #000000a1;
                            display: block;
                            position: absolute;
                            top: 50%;
                            left: 0;
                            margin: 0 auto;
                            transform: translateY(-50%);
                            right: 0;
                          }
                        }
                      }
                    }

                    p {
                      line-height: normal;
                      margin: 0.1rem 0 0rem;
                      font-size: 0.9rem;
                      display: -webkit-box;
                      -webkit-line-clamp: 3;
                      -webkit-box-orient: vertical;
                      overflow: hidden;
                      color: #666;
                    }
                  }
                  &.rejected_card {
                    .activity-user-icon {
                      background-color: #de002e;
                    }
                    > div {
                      .rejected_title {
                        color: #de002e;
                        padding: 0.1rem 0;
                        font-size: 1rem;
                      }
                      .comments {
                        background: #002E7012;
                        padding: 0.7rem;
                        display: flex;
                        border-radius: 6px;
                        margin-top: 0.4rem;
                        span {
                          width: 25%;
                          display: block;
                          color: $info;
                        }
                        p {
                          -webkit-line-clamp: inherit;
                          color: #1b1b1b;
                        }
                      }
                    }
                  }
                  &.udate_card {
                    .activity-user-icon {
                      background-color: $success;
                    }
                    > div {
                      width: 100%;
                      .rejected_title {
                        padding: 0.1rem 0;
                        font-size: 1rem;
                        color: $success;
                      }
                      .diffrent {
                        width: 100%;
                        .old_change,
                        .new_change {
                          display: flex;
                          align-items: center;
                          padding: 0.2rem 0.3rem;
                          margin-top: 0.5rem;
                          border-radius: 3px;
                          span {
                            width: 1rem;
                            display: inline-block;
                            svg {
                              margin-right: 0.4rem;
                              color: #545454;
                            }
                          }
                          p {
                            margin: 0;
                            span {
                              width: auto;
                            }
                          }
                        }
                        .old_change {
                          background: #de002e14;
                          p {
                            span {
                              background-color: rgba(#de002e, 0.2);
                            }
                          }
                        }
                        .new_change {
                          background: #8bc34a3b;
                          p {
                            margin: 0;
                            span {
                              width: auto;
                              background-color: rgba($success, 0.5);
                            }
                          }
                        }
                      }
                      .low_update {
                        background: #002E7012;
                        padding: 0.7rem;
                        border-radius: 6px;
                        margin-top: 0.4rem;
                        h4 {
                          margin: 0;
                          font-size: 1rem;
                          font-weight: 100;
                          color: $primary;
                        }
                        p {
                          -webkit-line-clamp: inherit;
                          color: #1b1b1b;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.rebate-detail-modal {
  .modal-dialog {
    .modal-body {  
      max-height: calc(100vh - 8rem);
      overflow: auto;
      .container {
        max-width: none !important;
        margin: 0 !important;
      }
    }
  }
}

.auditlog_modal {
  height: 100vh;
  .modal-dialog {
    max-width: 800px !important;
    .modal-content {
      width: 40rem !important;
      .dataTables_wrapper {
        .table {
          thead {
            display: none;
          }
          tbody {
            padding: 0.6rem;
            display: block;
            tr {
              display: inline-block;
              width: 100%;
              margin-bottom: 1rem;
              padding: 0.5rem;
              background: #ede8f31f;
              border-radius: 10px;
              border: 1px solid #0000003d;
              transition: all 0.3s ease;
              &:hover {
                box-shadow: 0.1rem 0.1rem 0.5rem #0000002b;
                background: #e4dfeb36;
              }
              td {
                width: 100%;
                float: left;
                border: none;
                &:first-child {
                  width: 50%;
                  float: left;
                  display: flex;
                  align-items: center;
                  padding: 0;
                  span {
                    background: #002E70;
                    color: #fff;
                    width: 2rem;
                    padding: 0;
                    height: 2rem;
                    border-radius: 50%;
                    display: flex;
                    margin-right: 0.4rem;
                    align-items: center;
                    justify-content: center;
                    svg {
                      width: 65%;
                      height: 65%;
                    }
                  }
                  .audit_user {
                    h5 {
                      margin: 0;
                      font-size: 1rem;
                      font-weight: 400;
                      color: #000;
                    }
                    .badge {
                      background: #00a7b538;
                      color: #00a7b5;
                      padding: 0 0.3rem;
                      line-height: normal;
                      border-radius: 4px;
                      font-size: 0.8rem;
                    }
                  }
                }
                &:nth-child(2) {
                  width: 50%;
                  float: left;
                  text-align: end;
                  color: #0000006e;
                  padding: 0;
                  line-height: normal;
                  .action_title {
                    color: $primary;
                  }
                }
                &:nth-child(3) {
                  padding: 0;
                  div {
                    padding: 0.3rem 0.4rem;
                    background-color: #de002e1a;
                    margin: 0.3rem;
                    width: calc(100% - 0.6rem);
                    border-radius: 5px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                  }
                }
                &:nth-child(4) {
                  padding: 0;
                  div {
                    padding: 0.3rem 0.4rem;
                    background-color: #9bd73240;
                    margin: 0.3rem;
                    width: calc(100% - 0.6rem);
                    border-radius: 5px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                  }
                }
              }
            }
          }
        }
        .dataTables_paginate {
          margin-top: 1rem;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          .pagination {
            margin: 0 !important;
            display: inline-flex;
            li{
              &.disabled{
                a{
                  background-color: #e9ecef;
                }
              } 
              &:first-of-type{
                position: relative;                   
                span{
                  font-size: 0;                                
                }   
                &::before,&::after{
                  border-right: 2px solid #2a3142;
                  content: '';
                  display: block;
                  height: 8px;
                  margin-top: -6px;
                  position: absolute;
                  transform: rotate(45deg);
                  // transform:rotate(180deg);
                  right: 10px;
                  top: 50%;
                  width: 0;
                  z-index: 1;
                }       
                &::after{
                  margin-top: -1px;
                  transform: rotate(135deg);
                }      
              }
              &:last-of-type{
                position: relative;                  
                span{
                  font-size: 0;                 
                }
                &::after,&::before{
                  border-right: 2px solid #2a3142;
                  content: '';
                  display: block;
                  height: 8px;
                  margin-top: -6px;
                  position: absolute;
                  transform: rotate(135deg);
                  right: 10px;
                  top: 50%;
                  width: 0;
                  z-index: 1;
                }
                &::after{
                  margin-top: -1px;
                  transform: rotate(45deg);
                }  
              }
            }
          }
        }
      }
    }
  }
  .btn-close {
    filter: brightness(0) invert(1) !important;
  }
}

/*/==========================Chat-View==========================/*/
.chat-btn {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-70%, 0);
  // font-size: 1.4rem;
  margin: 0;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  &:hover,
  &:focus {
    color: $primary !important;
  }
  svg {
    width: 3.5rem;
    height: 3.5rem;
  }
  .chat_notification {
    position: absolute;
    top: -26%;
    left: 69%;
    transform: translate(-50%, 0);
    background: #d61711;
    color: #fff;
    border-radius: 30px;
    padding: 0.3rem;
    width: 2.4rem;
    height: 1.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #edecf5;
  }
}
.task-chat {
  display: flex;
  // grid-gap: 1rem;
  // gap: 1rem;
  padding: 1rem 0 0;
  height: calc(100vh - 33vh);
  background: #002E700d;
  padding: 1rem;
  border-radius: 10px;
  box-shadow: 0rem 0rem 0.8rem rgb(0 0 0 / 12%);
  .chat-sidebar {
    background: transparent;
    width: 20rem;
    height: 100%;
    border-radius: 8px;
    // overflow: hidden;
    .chat_back_btn {
      cursor: pointer;
      svg {
        width: 1.2rem;
        height: 1.2rem;
      }
    }
    h5 {
      margin: 0;
      font-weight: 500;
      font-size: 1.5rem;
      padding: 0.2rem 1rem;
      color: #002E70;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      svg {
        width: 3.5rem;
        height: 3.5rem;
        margin-right: 0.7rem;
      }
    }
    .chat_search_box {
      display: flex;
      padding: 0 1.1rem;
      .search_box {
        float: left;
        width: 100%;
        input {
          width: 100%;
          border-radius: 20px;
        }
      }
      .dropdown {
        display: inline-block;
        margin-left: 0.7rem;
        .add_chat {
          color: #002E70;
          background: #fff;
          padding: 0.3rem;
          border-radius: 50%;
          width: 2rem;
          height: 2rem;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: all 0.3s ease;
          border: 1px solid #ccc;
          &:hover,
          &:focus {
            cursor: pointer;
            color: #fff;
            background: #002E70;
          }
        }
        .dropdown-menu {
          .dropdown-item {
            padding: 0.5rem 0.8rem;
            display: flex;
            align-items: center;
            gap: 0.5rem;
            &:hover,
            &:focus {
              background: lighten($primary, 58%);
            }
          }
        }
      }
    }

    .chat_search {
      position: relative;
      margin: 0.8rem 0.8rem 0.5rem;
      &::before {
        content: '\f002';
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
        position: absolute;
        left: 0.6rem;
        top: 50%;
        font-size: 1.2rem;
        transform: translateY(-50%);
        color: $primary;
      }
      .form-control {
        padding: 0.7rem 0.7rem 0.7rem 1.75rem;
        height: auto;
        line-height: normal;
        margin: 0 !important;
        width: 100%;
      }
    }
    ul {
      list-style: none;
      margin: 0;
      height: calc(100% - 2.8rem);
      width: calc(100% - 1rem);
      display: inline-block;
      overflow: auto;
      padding: 0 0.5rem;
      li {
        width: 100%;
        display: inline-block;
        border-bottom: 1px solid #ffffffde;
        &.active {
          > a {
            background: #002E70;
            color: #fff;
            box-shadow: 0 2px 9px #00000066;
            .chat-details {
              span {
                color: #fff;
              }
              p {
                span {
                  color: rgba(255, 255, 255, 0.774);
                }
              }
            }
            &:hover,
            &:focus {
              background: #002E70;
              .chat-details {
                span {
                  color: #fff;
                }
              }
            }
          }
        }
        > a {
          padding: 0.4rem 0.6rem;
          // border-bottom: 1px solid lighten($primary, 61%);
          width: 100%;
          display: inline-flex;
          align-items: center;
          gap: 0.5rem;
          transition: all 0.3s ease;
          -webkit-transition: all 0.3s ease;
          -moz-transition: all 0.3s ease;
          -ms-transition: all 0.3s ease;
          -o-transition: all 0.3s ease;
          border-radius: 8px;
          &:hover,
          &:focus {
            cursor: pointer;
            background: #002E7029;
            border-color: rgba(0, 0, 0, 0.15);
            color: #fff;
            transition: all 0.3s ease;
            -webkit-transition: all 0.3s ease;
            -moz-transition: all 0.3s ease;
            -ms-transition: all 0.3s ease;
            -o-transition: all 0.3s ease;
            .chat-details {
              > span {
                color: #002E70;
              }
            }
          }

          .chat-details {
            width: calc(100% - 3rem);
            float: left;
            > span {
              width: 100%;
              display: inline-flex;
              align-items: center;
              justify-content: space-between;
              font-size: 0.9rem;
              font-weight: 500;
              color: #002E70;
              small {
                font-weight: 400;
                color: #00a7b5;
              }
            }
            p {
              margin: 0;
              width: 100%;
              color: #9e9e9e;
              display: flex;
              align-items: center;
              justify-content: space-between;
              span {
                white-space: pre;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
              }
              small {
                border-radius: 100px;
                -webkit-border-radius: 100px;
                -moz-border-radius: 100px;
                -ms-border-radius: 100px;
                -o-border-radius: 100px;
                background: $success;
                color: #fff;
                line-height: normal;
                height: 1.2rem;
                width: 1.2rem;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                flex: none;
                margin-left: 0.5rem;
              }
              a {
                color: #a3a3a3;
                pointer-events: none;
                &:hover,
                &:focus {
                  color: #a3a3a3;
                }
              }
            }
          }
        }
      }
    }
  }
  .chat-profile-img {
    width: 2.2rem;
    height: 2.2rem;
    float: left;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: none;
    background: $orange;
    font-size: 1.2rem;
    color: #fff;
  }
}
.chat-main {
  width: calc(100% - 20rem);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 10px;
  background: $white;
  overflow: hidden;
  position: relative;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  // box-shadow: 0.1rem 0.1rem 0.6rem #00000029;
  .chat-header {
    background: #002E700d;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.151);
    margin: 0.5rem;
    border-radius: 8px;
    // padding: 0.7rem 1rem;
    position: sticky;
    top: 0;
    .chat-profile {
      display: flex;
      align-items: center;
      padding: 0;
      h6 {
        font-weight: 500;
        margin: 0.5rem 0.6rem;
        color: #002E70;
        display: flex;
        align-items: center;
        &:hover,
        &:focus {
          color: $success;
          cursor: pointer;
        }
      }
      button.btn {
        padding: 0;
        margin: 0;
        display: flex;
        align-items: center;
        &:hover,
        &:focus {
          text-decoration: none;
          color: $info;
        }
        i {
          font-size: 1.3rem;
          display: flex;
          align-items: center;
        }
      }
    }
    .chat-header-action {
      display: flex;
      gap: 1rem;
      align-items: center;
      .search_chat {
        padding: 0.5rem;
      }
      .info_btn {
        color: #9bd732;
        padding: 0.7rem 1rem;
        margin: 0 0.2rem -0.2rem 0;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.2s ease;
        -webkit-transition: all 0.2s ease;
        -moz-transition: all 0.2s ease;
        -ms-transition: all 0.2s ease;
        -o-transition: all 0.2s ease;
        &:hover,
        &:focus {
          color: $info;
          fill: $info;
          transition: all 0.2s ease;
          -webkit-transition: all 0.2s ease;
          -moz-transition: all 0.2s ease;
          -ms-transition: all 0.2s ease;
          -o-transition: all 0.2s ease;
        }
        svg {
          width: 1.5rem;
          height: 1.5rem;
        }
      }
    }
  }
  .chat-area {
    float: left;
    width: 100%;
    display: inline-block;
    overflow-y: auto;
    height: calc(100% - 6.7rem);
    padding: 1rem;
    > ul {
      list-style: none;
      width: 100%;
      display: inline-block;
      overflow: hidden;
      margin: 0;
      padding: 0;
      > li {
        max-width: 65%;
        min-width: 65%;
        margin-bottom: 0.5rem;
        position: relative;
        &:hover {
          &.receive,
          &.send {
            .chat_msg {
              .dropdown {
                transition: all 0.3s ease;
                opacity: 1;
                -webkit-transition: all 0.3s ease;
                -moz-transition: all 0.3s ease;
                -ms-transition: all 0.3s ease;
                -o-transition: all 0.3s ease;
                .chat_action {
                  padding: 0.1rem 0.5rem;
                  transition: all 0.3s ease;
                  -webkit-transition: all 0.3s ease;
                  -moz-transition: all 0.3s ease;
                  -ms-transition: all 0.3s ease;
                  -o-transition: all 0.3s ease;
                }
              }
            }
          }
        }
        &.receive {
          display: flex;
          float: left;
          align-items: flex-start;
          .msg_img_inner {
            width: 2.3rem;
            height: 2.3rem;
            flex: none;
            margin-right: 0.3rem;
            border-radius: 100px;
            -webkit-border-radius: 100px;
            -moz-border-radius: 100px;
            -ms-border-radius: 100px;
            -o-border-radius: 100px;
            border: 2px solid #c24200;
            background: #c24200;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            font-weight: 500;
            font-size: 1rem;
            line-height: normal;
          }
          .chat_msg {
            display: inline-block;
            flex: inherit;
            position: relative;
            > p {
              background: #eeeeee;
              border-radius: 0 1.5rem 1.5rem 1.5rem;
              -webkit-border-radius: 0 1.5rem 1.5rem 1.5rem;
              -moz-border-radius: 0 1.5rem 1.5rem 1.5rem;
              -ms-border-radius: 0 1.5rem 1.5rem 1.5rem;
              -o-border-radius: 0 1.5rem 1.5rem 1.5rem;
              > p {
                padding: 0;
                color: #002E70;
              }
              a {
                color: $info;
                &:hover,
                &:focus {
                  color: $success;
                }
              }
              .reply-text {
                padding: 0.4rem 0.4rem 0.4rem 0.8rem;
                margin: 0 0 0.4rem;
                background: #fff;
                border-radius: 8px;
                position: relative;
                overflow: hidden;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                grid-gap: 0.5rem;
                gap: 0;
                width: 100%;
                &::before {
                  content: '';
                  width: 0.3rem;
                  height: 100%;
                  background: #9bd732;
                  position: absolute;
                  left: 0;
                  top: 0;
                }
                b {
                  width: 100%;
                  color: #9bd732;
                }
                span {
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                  > p {
                    margin: 0;
                    background: transparent;
                    padding: 0;
                    a {
                      pointer-events: none;
                      color: $info;
                    }
                  }
                }
              }
            }
            .dropdown {
              position: absolute;
              margin-top: 0.5rem;
              right: -1.5rem;
              top: 50%;
              transform: translateY(-50%);
              opacity: 0;
              transition: all 0.3s ease;
              -webkit-transition: all 0.3s ease;
              -moz-transition: all 0.3s ease;
              -ms-transition: all 0.3s ease;
              -o-transition: all 0.3s ease;
              &.show {
                opacity: 1;
                .chat_action {
                  padding: 0.1rem 0.5rem;
                }
              }
              .chat_action {
                padding: 0;
                transition: all 0.3s ease;
                -webkit-transition: all 0.3s ease;
                -moz-transition: all 0.3s ease;
                -ms-transition: all 0.3s ease;
                -o-transition: all 0.3s ease;
                &:hover,
                &:focus {
                  color: $info;
                  cursor: pointer;
                }
              }
              > .dropdown-menu {
                background: lighten($dark, 72%);
                padding: 0;
                .dropdown-item {
                  padding: 0.5rem 0.6rem;
                  display: flex;
                  align-items: center;
                  gap: 0.5rem;
                  background: transparent;
                  svg {
                    width: 1.2rem;
                    height: 1.2rem;
                  }
                  &:hover,
                  &:focus {
                    background: lighten($primary, 60%);
                    color: $primary;
                  }
                }
              }
            }
            .attachments {
              .reply-text {
                padding: 0.4rem 0.4rem 0.4rem 0.8rem;
                margin: 0 0 1rem;
                background: lighten($primary, 66%);
                border-radius: 8px;
                position: relative;
                overflow: hidden;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                grid-gap: 0.5rem;
                gap: 0;
                width: 100%;
                text-align: left;
                &::before {
                  content: '';
                  width: 0.3rem;
                  height: 100%;
                  background: $info;
                  position: absolute;
                  left: 0;
                  top: 0;
                }
                b {
                  width: 100%;
                  color: $info;
                }
                span {
                  overflow: hidden;
                  text-overflow: ellipsis;
                  font-size: inherit;
                  font-weight: normal;
                  background: transparent;
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                  > p {
                    margin: 0;
                    background: transparent;
                    padding: 0;
                  }
                }
              }
              .file {
                display: inline-flex;
                flex-direction: column;
                padding: 0.5rem;
                border-radius: 0 8px 8px 8px;
                -webkit-border-radius: 0 8px 8px 8px;
                -moz-border-radius: 0 8px 8px 8px;
                -ms-border-radius: 0 8px 8px 8px;
                -o-border-radius: 0 8px 8px 8px;
                width: 100%;
                overflow: hidden;
                background: #ededed;
                .file_inner {
                  display: inline-flex;
                  border-radius: 5px;
                  width: 100%;
                  overflow: hidden;
                  > p {
                    background: #002E70b5;
                  }
                  > span {
                    background: rgba($primary, 20%);
                    color: #5f418b;
                  }
                }
              }
            }
          }
        }
        &.send {
          float: right;
          text-align: right;
          .chat_msg {
            display: inline-block;
            position: relative;
            .chat_user {
              padding: 0 0rem 0.1rem 1rem;
            }
            p {
              text-align: left;
              background: #002E70;
              border-radius: 1.5rem 1.5rem 0 1.5rem;
              -webkit-border-radius: 1.5rem 1.5rem 0 1.5rem;
              -moz-border-radius: 1.5rem 1.5rem 0 1.5rem;
              -ms-border-radius: 1.5rem 1.5rem 0 1.5rem;
              -o-border-radius: 1.5rem 1.5rem 0 1.5rem;
              > p {
                padding: 0;
                color: #fff;
              }
              .reply-text {
                padding: 0.4rem 0.4rem 0.4rem 0.8rem;
                margin: 0 0 0.4rem;
                background: #fff;
                border-radius: 8px;
                position: relative;
                overflow: hidden;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                grid-gap: 0.5rem;
                gap: 0;
                width: 100%;
                &::before {
                  content: '';
                  width: 0.3rem;
                  height: 100%;
                  background: #9bd732;
                  position: absolute;
                  left: 0;
                  top: 0;
                }
                b {
                  width: 100%;
                  color: #9bd732;
                }
                span {
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                  > p {
                    margin: 0;
                    background: transparent;
                    padding: 0;
                    a {
                      color: #6c757d;
                      pointer-events: none;
                    }
                  }
                }
              }
              a {
                color: rgba(255, 255, 255, 0.733);
                &:hover,
                &:focus {
                  color: $info;
                }
              }
            }
            .dropdown {
              position: absolute;
              margin-top: 0.5rem;
              left: -1.5rem;
              top: 50%;
              transform: translateY(-50%);
              opacity: 0;
              transition: all 0.3s ease;
              -webkit-transition: all 0.3s ease;
              -moz-transition: all 0.3s ease;
              -ms-transition: all 0.3s ease;
              -o-transition: all 0.3s ease;
              &.show {
                opacity: 1;
                .chat_action {
                  padding: 0.1rem 0.5rem;
                }
              }
              .chat_action {
                padding: 0;
                transition: all 0.3s ease;
                -webkit-transition: all 0.3s ease;
                -moz-transition: all 0.3s ease;
                -ms-transition: all 0.3s ease;
                -o-transition: all 0.3s ease;
                &:hover,
                &:focus {
                  color: $info;
                  cursor: pointer;
                }
              }
              > .dropdown-menu {
                background: lighten($dark, 72%);
                padding: 0;
                .dropdown-item {
                  padding: 0.5rem 0.6rem;
                  display: flex;
                  align-items: center;
                  gap: 0.5rem;
                  background: transparent;
                  svg {
                    width: 1.2rem;
                    height: 1.2rem;
                  }
                  &:hover,
                  &:focus {
                    background: lighten($primary, 60%);
                    color: $primary;
                  }
                }
              }
            }
            .attachments {
              .reply-text {
                padding: 0.4rem 0.4rem 0.4rem 0.8rem;
                margin: 0 0 1rem;
                background: #ffffff;
                border-radius: 8px;
                position: relative;
                overflow: hidden;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                grid-gap: 0.5rem;
                gap: 0;
                width: 100%;
                text-align: left;
                &::before {
                  content: '';
                  width: 0.3rem;
                  height: 100%;
                  background: #9bd732;
                  position: absolute;
                  left: 0;
                  top: 0;
                }
                b {
                  width: 100%;
                  color: #9bd732;
                }
                span {
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                  > p {
                    margin: 0;
                    background: transparent;
                    padding: 0;
                  }
                }
              }
              .file {
                display: inline-flex;
                flex-direction: column;
                padding: 0.5rem;
                border-radius: 8px 8px 0 8px;
                width: 100%;
                overflow: hidden;
                background: #002E70;
                .file_inner {
                  display: inline-flex;
                  border-radius: 5px;
                  width: 100%;
                  overflow: hidden;
                  > p {
                    background: #fff;
                    a {
                      color: #000;
                    }
                  }
                  > span {
                    background: #ffffff6e;
                    color: #fff;
                  }
                }
              }
            }
          }
        }
        &.notification {
          display: inline-flex;
          width: 100%;
          justify-content: center;
          align-items: center;
          max-width: none;
          margin: 0;
          &.sticky {
            position: sticky;
            top: -0.5rem;
            z-index: 1;
          }
          span {
            margin: 0.3rem 0;
            background: #9bd732;
            color: #fff;
            padding: 0.3rem 0.8rem;
            line-height: normal;
            border-radius: 5px;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            -ms-border-radius: 5px;
            -o-border-radius: 5px;
          }
        }
        .chat_msg {
          max-width: 100%;
          .chat_user {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 1rem 0.1rem 0;
            .user_name {
              font-weight: 500;
              color: #6c757d;
              font-size: 0.85rem;
              white-space: pre;
              margin-right: 1rem;
              line-height: normal;
            }
            .msg_time {
              line-height: normal;
              white-space: pre;
              font-size: 0.8rem;
              color: #6c757d;
            }
          }
          p {
            padding: 0.5rem 0.9rem;
            line-height: 1.4;
            margin: 0;
          }
          .attachments {
            // margin-top: 0.5rem;
            width: 100%;
            .file {
              display: inline-flex;
              border: 1px solid #d9d9d9;
              border-radius: 5px;
              width: 100%;
              overflow: hidden;
              .file_inner {
                margin: -0.3rem;
                > span {
                  min-width: 2.5rem;
                  min-height: 2.5rem;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-size: 1.5rem;
                  background: #ccd2da;
                  color: #344767;
                }
                > p {
                  color: #fff;
                  width: calc(100% - 2.5rem);
                  display: flex;
                  align-items: center;
                  font-size: 0.9rem;
                  font-weight: 600;
                  border-radius: 0px;
                  margin: 0;
                  padding: 0.3rem 0.9rem;
                  -webkit-border-radius: 0px;
                  -moz-border-radius: 0px;
                  -ms-border-radius: 0px;
                  -o-border-radius: 0px;
                  a {
                    color: #fdfdfdad;
                    margin-top: 0;
                    font-weight: normal;
                    text-align: left;
                    word-break: break-all;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .chat-footer {
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    padding: 0.5rem 0.6rem 0.5rem 0.5rem;
    box-shadow: 0rem 0rem 0.4rem -0.1rem hsl(0deg 0% 0% / 15%);
    position: relative;
    .reply-text {
      padding: 0.4rem 0.3rem 0.4rem;
      margin: 0 0 0.5rem;
      background: lighten($muted, 30%);
      border-radius: 8px;
      position: relative;
      overflow: hidden;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      width: 100%;
      > svg {
        width: 2rem;
        height: 2rem;
        margin-left: 0.3rem;
        color: $info;
        fill: $info;
      }
      .close_rpl {
        padding: 0;
        width: 1.5rem;
        height: 1.5rem;
        flex: none;
        svg {
          transform: rotate(0deg);
          -webkit-transform: rotate(0deg);
          -moz-transform: rotate(0deg);
          -ms-transform: rotate(0deg);
          -o-transform: rotate(0deg);
          transition: all 0.3s ease;
          -webkit-transition: all 0.3s ease;
          -moz-transition: all 0.3s ease;
          -ms-transition: all 0.3s ease;
          -o-transition: all 0.3s ease;
        }
        &::before {
          content: none;
        }
        &:hover,
        &:focus {
          color: $info;
          fill: $info;
          svg {
            transform: rotate(90deg);
            -webkit-transform: rotate(90deg);
            -moz-transform: rotate(90deg);
            -ms-transform: rotate(90deg);
            -o-transform: rotate(90deg);
            transition: all 0.3s ease;
            -webkit-transition: all 0.3s ease;
            -moz-transition: all 0.3s ease;
            -ms-transition: all 0.3s ease;
            -o-transition: all 0.3s ease;
          }
        }
      }
      a {
        display: flex;
        flex-wrap: wrap;
        line-height: normal;
        position: relative;
        padding: 0 0 0 0.8rem;
        flex: 1;
        &::before {
          content: '';
          width: 0.2rem;
          height: 100%;
          background: $info;
          position: absolute;
          left: 0;
          top: 0;
        }
        &:hover {
          color: inherit;
        }
        b {
          width: 100%;
          color: $info;
        }
        span {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          > p {
            margin: 0;
          }
        }
      }
    }
    .tag-user-list {
      position: absolute;
      z-index: 1;
      background: #e6eaf0;
      width: auto;
      bottom: 0%;
      left: 1rem;
      display: inline-block;
      max-height: 12rem;
      box-shadow: -0.1rem -0.1rem 0.4rem 0.2rem hsl(0deg 0% 0% / 8%);
      overflow-y: auto;
      border-radius: 8px 8px 0 0;
      -webkit-border-radius: 8px 8px 0 0;
      -moz-border-radius: 8px 8px 0 0;
      -ms-border-radius: 8px 8px 0 0;
      -o-border-radius: 8px 8px 0 0;
      opacity: 0;
      transform: translateY(100%);
      -webkit-transform: translateY(100%);
      -moz-transform: translateY(100%);
      -ms-transform: translateY(100%);
      -o-transform: translateY(100%);
      transition: all 0.3s ease;
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -ms-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      &.show {
        bottom: 100%;
        opacity: 1;
        transform: translateY(0%);
        -webkit-transform: translateY(00%);
        -moz-transform: translateY(00%);
        -ms-transform: translateY(00%);
        -o-transform: translateY(00%);
        transition: all 0.3s ease;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
      }
      .chat_member {
        width: auto;
        display: flex;
        flex-direction: column;
        > li {
          padding: 0.4rem 1rem 0.4rem 0.4rem;
          border-color: #ccd2da;
          &:hover,
          &:focus {
            cursor: pointer;
            background: rgba($primary, 20%);
          }
          > div {
            > span {
              width: 2rem;
              height: 2rem;
              margin-right: 0rem;
              font-size: 0.9rem;
            }
            p {
              font-size: 0.9rem;
              small {
                margin-top: 0.1rem;
              }
            }
          }
        }
      }
    }
    .chat-input {
      display: flex;
      align-items: center;
      flex: 1;
      position: relative;
      height: auto;
      background: #dad8dd80;
      border-radius: 30px;
      max-height: 8rem;
      > div {
        width: 100%;
        background: transparent;
        border: none;
        margin-right: 7.5rem;
        border-radius: 30px;
        font-family: $font-family;
        color: $dark;
        max-height: 8rem;
        overflow-y: auto;
        [class*='EditorToolbar'] {
          position: absolute;
          bottom: 100%;
          padding: 0.5rem 0.5rem 0;
          margin: 0;
          border: 0;
          left: 0.5rem;
          background: #fff;
          height: 0;
          border-radius: 7px 7px 0 0;
          box-shadow: 0rem -0.2rem 0.7rem 0rem hsl(0deg 0% 0% / 8%);
          transform: translateY(100%) scaleY(0.9);
          -webkit-transform: translateY(100%) scaleY(0.9);
          -moz-transform: translateY(100%) scaleY(0.9);
          -ms-transform: translateY(100%) scaleY(0.9);
          -o-transform: translateY(100%) scaleY(0.9);
          opacity: 0;
          transition: all 0.3s ease;
          -webkit-transition: all 0.3s ease;
          -moz-transition: all 0.3s ease;
          -ms-transition: all 0.3s ease;
          -o-transition: all 0.3s ease;
          z-index: -1;
          .InputPopover__root___3Hpj9 {
            top: calc(100% + -169px);
            &::after,
            &::before {
              bottom: -10%;
              top: unset;
              transform: rotate(180deg);
            }
          }
          &.show {
            z-index: 1;
            height: auto;
            opacity: 1;
            transform: translateY(0%) scaleY(1);
            -webkit-transform: translateY(0%) scaleY(1);
            -moz-transform: translateY(0%) scaleY(1);
            -ms-transform: translateY(0%) scaleY(1);
            -o-transform: translateY(0%) scaleY(1);
            transition: all 0.3s ease;
            -webkit-transition: all 0.3s ease;
            -moz-transition: all 0.3s ease;
            -ms-transition: all 0.3s ease;
            -o-transition: all 0.3s ease;
          }
          [class*='ButtonWrap'] {
            button {
              &:hover,
              &:focus {
                background: $primary;
                color: $white;
                span {
                  filter: brightness(1) invert(1);
                  -webkit-filter: brightness(1) invert(1);
                }
              }
              &:disabled {
                background: #ccc;
                span {
                  filter: none;
                  -webkit-filter: none;
                }
              }
            }
          }
          //*========Tag=Users========*//
          .tag_badge {
            background: $success;
            color: $white;
            padding: 0.1rem 0.3rem 0.1rem 0rem;
            line-height: normal;
            border-radius: 3px;
            -webkit-border-radius: 3px;
            -moz-border-radius: 3px;
            -ms-border-radius: 3px;
            -o-border-radius: 3px;
          }
        }
      }
      .public-DraftEditorPlaceholder-root {
        padding-left: 1rem;
      }
      .public-DraftEditor-content {
        padding-left: 1rem;
      }
      input {
        height: auto;
        background: #f8f9fa;
      }
      .attachment-btn {
        font-size: 1.2rem;
        margin-right: 0.3rem;
        color: $primary;
        position: absolute;
        right: 3rem;
        width: 2.2rem;
        height: 2.2rem;
        border-radius: 100px;
        transition: all 0.3s ease;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        display: flex;
        justify-content: center;
        align-items: center;
        &.text-fortamet {
          right: 5rem;
        }
        svg {
          width: 1.2rem;
          height: 1.2rem;
        }
        &:hover,
        &:focus {
          color: $white;
          background: $success;
        }
      }
      .send-btn {
        position: absolute;
        right: 0.5rem;
        top: 50%;
        padding: 0 0 0 0.2rem;
        border-radius: 100px;
        margin-left: 0.3rem;
        font-size: 1.1rem;
        width: 2.2rem;
        height: 2.2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $success;
        color: $white;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        -webkit-border-radius: 100px;
        -moz-border-radius: 100px;
        -ms-border-radius: 100px;
        -o-border-radius: 100px;
        transition: all 0.3s ease;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        text-decoration: none;
        z-index: 1;
        svg {
          width: 1.4rem;
        }
        &:hover,
        &:focus {
          color: $white;
          background: $primary;
          text-decoration: none;
        }
      }
    }
  }
  .no_chat {
    height: calc(100% - 1.5rem);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    img {
      height: 60%;
    }
    h3 {
      margin: 1rem 0 0;
    }
  }
}
.modal-body {
  .add_member {
    position: fixed;
    right: 5rem;
    bottom: 1rem;
    width: auto;
    height: 2.4rem;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.1rem;
    padding: 0.1rem 0.9rem 0;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    i {
      margin: 0;
    }
    &:hover,
    &:focus {
      background: $primary;
      border-color: $primary;
      color: $white;
    }
  }
  .chat_membars {
    display: flex;
    align-items: flex-start;
    width: calc(100% + 1rem);
    overflow: hidden;
    margin: 0 -0.5rem;
    > .chat_member {
      transform: translateX(-100%);
      -webkit-transform: translateX(-100%);
      -moz-transform: translateX(-100%);
      -ms-transform: translateX(-100%);
      -o-transform: translateX(-100%);
      transition: all 0.3s ease;
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -ms-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      flex: none;
      &.show {
        transform: translateX(0%);
        -webkit-transform: translateX(0%);
        -moz-transform: translateX(0%);
        -ms-transform: translateX(0%);
        -o-transform: translateX(0%);
        transition: all 0.3s ease;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
      }
    }
  }
}
.chat_member {
  margin: 0;
  padding: 0;
  list-style: none;
  width: 100%;
  display: inline-block;
  li {
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    padding: 0.5rem;
    border-bottom: 1px solid lighten($primary, 60%);
    &:last-child {
      border-bottom: none;
    }
    &:hover,
    &:focus {
      background: rgba($primary, 10%);
    }
    div {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      span {
        width: 2.3rem;
        height: 2.3rem;
        margin-right: 0.3rem;
        border-radius: 100px;
        -webkit-border-radius: 100px;
        -moz-border-radius: 100px;
        -ms-border-radius: 100px;
        -o-border-radius: 100px;
        border: 1px solid #c24200;
        background: #c24200;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-weight: 500;
        font-size: 1rem;
        line-height: normal;
      }
      p {
        margin: 0;
        display: flex;
        flex-direction: column;
        line-height: normal;
        font-weight: 500;
        small {
          color: #6c757d;
          margin-top: 0.2rem;
        }
      }
    }
    a {
      padding: 0.5rem;
      transition: all 0.3s ease;
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -ms-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      &:hover,
      &:focus {
        cursor: pointer;
        color: #000 !important;
        transition: all 0.3s ease;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
      }
    }
  }
}
.availble_member {
  transform: translateX(100%);
  -webkit-transform: translateX(100%);
  -moz-transform: translateX(100%);
  -ms-transform: translateX(100%);
  -o-transform: translateX(100%);
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  flex: none;
  width: 100%;
  &.show {
    transform: translateX(0%);
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
  }
  .availble_list {
    h6 {
      display: flex;
      align-items: center;
      line-height: normal;
      gap: 0.5rem;
      font-weight: 500;
      padding: 0 0.5rem;
      a {
        font-size: 1.5rem;
        line-height: normal;
        display: inline-flex;
        align-items: center;
        color: #344767;
        &:hover,
        &:focus {
          color: $info;
          cursor: pointer;
        }
      }
    }
  }
}
/*/==========================End-Chat-View==========================/*/

/*/==========================Dashboard==========================/*/
.nav-tabs.dashboard_tabs {
  display: inline-flex;
  width: auto;
  justify-content: flex-end;
  align-items: center;
  float: right;
  line-height: normal;
  border-radius: 5px;
  padding: 0.2rem;
  position: absolute;
  right: 25%;
  top: 0.5rem;
  z-index: 1;
  &.details_tab_open {
    right: 0%;
  }
  .nav-item {
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      grid-gap: 0.5rem;
      gap: 0.5rem;
      padding: 0.3rem 0.5rem;
      white-space: pre;
      border-radius: 5px;
      &:hover,
      &:focus {
        color: $primary;
      }
      &::after {
        content: none;
      }
      svg {
        width: 1.2rem;
        height: 1.2rem;
      }
    }
  }
}
.dashboard_content {
  position: relative;
  width: 100%;
}
.dashboard_count_main {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  .dashboard_count {
    background: $white;
    border-radius: 10px;
    display: flex;
    align-items: center;
    cursor: default !important;
    justify-content: space-between;
    padding: 0.8rem;
    text-decoration: none;
    transition: all 0.3s ease;
    &:is(:hover, :focus) {
      background: lighten($primary, 58%);
      transition: all 0.3s ease;
    }
    h4 {
      margin: 0;
      font-family: sans-serif;
      font-weight: 800;
      color: $dark;
      small {
        display: inline-block;
        width: 100%;
        font-size: 1.2rem;
        font-weight: 500;
        color: $gray-600;
      }
    }
    span {
      border-radius: 8px;
      padding: 0.5rem;
      color: $white;
      aspect-ratio: 1;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 5rem;
      svg {
        width: 80%;
        height: 80%;
      }
    }
  }
}
.dashboard_section {
  width: 100%;
  display: inline-block;
  margin: 0.5rem 0 1rem 0;
  .form-control.selectbox {
    > div {
      > div {
        > div {
          align-items: center;
        }
      }
    }
  }
  .chart-card,
  .top_area {
    box-shadow: 0rem 0rem 10px #00000017;
  }
  .quick_link {
    margin: 0;
    padding: 0 1rem 1rem;
    list-style: none;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 0.5rem;
    li {
      a {
        background: $white;
        color: $primary;
        padding: 0.5rem;
        min-height: 6rem;
        display: inline-flex;
        flex-direction: column;
        align-content: space-between;
        justify-content: flex-start;
        width: 100%;
        border: 1px solid #002E705c;
        box-shadow: 0.1rem 0.1rem 0.3rem #00000017;
        position: relative;
        z-index: 9;
        overflow: hidden;
        border-radius: 8px;
        -webkit-border-radius: 8px;
        -moz-border-radius: 8px;
        -ms-border-radius: 8px;
        -o-border-radius: 8px;
        transition: all 0.3s ease;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        &:hover,
        &:focus {
          background: rgba(79, 45, 127, 0.151);
          box-shadow: 0rem 0rem 0.5rem #00000033;
          transition: all 0.3s ease;
          -webkit-transition: all 0.3s ease;
          -moz-transition: all 0.3s ease;
          -ms-transition: all 0.3s ease;
          -o-transition: all 0.3s ease;
        }
        &::before {
          content: '';
          width: 6.5rem;
          height: 6rem;
          position: absolute;
          top: 50%;
          right: -4.6rem;
          transform: translate(0, -50%);
          border-radius: 100px;
          background: #002E70;
          z-index: -1;
          transition: all 0.5s ease-in-out;
        }
        .quick_right_arrow {
          position: absolute;
          right: 1%;
          top: 50%;
          transform: translate(0, -50%);
          width: 1rem;
          height: 1rem;
          color: #fff;
        }
        span {
          svg {
            width: 2.3rem;
            height: 2.3rem;
            margin-bottom: 0.3rem;
          }
        }
        label {
          margin: 0;
          line-height: normal;
          font-size: 0.9rem;
          height: 2.3rem;
          padding-right: 1rem;
        }
      }
    }
  }
  .dashboard_head {
    height: 100%;
    // overflow: auto;
    h5 {
      position: relative;
      width: 100%;
      display: inline-block;
      margin: 0 0 0.5rem;
      padding: 0.4rem 1rem;
      font-size: 1.1rem;
      color: #002E70;
      background: rgb(255, 255, 255);
      background: linear-gradient(
        270deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(79, 45, 127, 0.19) 82%,
        rgba(79, 45, 127, 0.22) 100%
      );
      &::before {
        content: '';
        background: $primary;
        position: absolute;
        left: 0;
        top: 0;
        width: 0.3rem;
        height: 100%;
      }
    }
    ul {
      padding: 0;
      height: calc(100% - 8rem);
      overflow-y: auto;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      &::-webkit-scrollbar {
        width: 6px;
        background: rgba(19, 47, 78, 0.3);
      }

      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px #5e72e41a;
        -webkit-border-radius: 10px;
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb {
        -webkit-border-radius: 10px;
        border-radius: 10px;
        background: #3b215f;
      }

      &::-webkit-scrollbar-thumb:window-inactive {
        background: darken($primary, 20%);
      }
      li {
        width: 100%;
        display: inline-block;
        padding-right: 0.7rem;
        .country_list_info {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin: 0.2rem 0 0.4rem;
          p {
            margin: 0;
            img {
              width: 1.75rem;
              margin-right: 0.5rem;
            }
          }
          span {
            font-weight: 500;
          }
        }
        .progress {
          background: rgba($primary, 0.2);
          border-radius: 100px;
          -webkit-border-radius: 100px;
          -moz-border-radius: 100px;
          -ms-border-radius: 100px;
          -o-border-radius: 100px;
          .progress-bar {
            border-radius: 100px;
            -webkit-border-radius: 100px;
            -moz-border-radius: 100px;
            -ms-border-radius: 100px;
            -o-border-radius: 100px;
          }
        }
      }
    }
    .card-header {
      border: 0;
      padding: 1rem;
    }
  }
  .geo_chart {
    position: relative;
    text-align: center;
    > div {
      display: inline-block;
    }
    .geo_chart_dropdown {
      width: 100%;
      display: inline-flex;
      flex-direction: row-reverse;
      justify-content: space-between;
    }
    .form-group {
      display: inline-block;
      margin: 0 5rem 0.4rem 0;
      float: right;
      min-width: 14rem;
      z-index: 1;
      position: relative;
      .form-control {
        line-height: normal;
        height: auto;
        font-size: 0.85rem;
        border-radius: 5px;
        text-align: start;
        border-color: rgba($primary, 20%);
        > div {
          div {
            img {
              width: 2rem !important;
              margin-right: 0.5rem;
            }
          }
        }
      }
    }
  }
  &.country_list_height {
    .dashboard_head {
      ul {
        height: 26rem !important;
      }
    }
  }
}
.dashboardcard_head {
  box-shadow: 0rem 0rem 10px rgb(0 0 0 / 9%) !important;
  .card-header {
    padding: 1rem !important;
    h5 {
      position: relative;
      width: 100%;
      display: inline-block;
      margin: 0 0 0.5rem;
      padding: 0.4rem 1rem;
      font-size: 1.1rem;
      color: #002E70;
      background: rgb(255, 255, 255);
      background: linear-gradient(
        270deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(79, 45, 127, 0.19) 82%,
        rgba(79, 45, 127, 0.22) 100%
      );
      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 0.3rem;
        height: 100%;
        background: $primary;
      }
    }
  }
}
.col-lg-3 {
  &:first-child {
    .short_list.dashboardcard_head {
      .card-header {
        h5 {
          color: #002E70;
          background: rgb(255, 255, 255);
          background: linear-gradient(
            270deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(79, 45, 127, 0.19) 82%,
            rgba(79, 45, 127, 0.22) 100%
          );
          &::before {
            background: $primary;
          }
        }
      }
      .card-body {
        li {
          a {
            &:hover,
            &:focus {
              background: rgba(79, 45, 127, 0.12);
              color: rgb(79, 45, 127);
              span {
                background: #002E70;
                color: #fff;
              }
            }
            span {
              color: #002E70;
            }
          }
        }
      }
    }
  }
  &:nth-child(2) {
    .short_list.dashboardcard_head {
      .card-header {
        h5 {
          color: #00a7b5;
          background: rgb(255, 255, 255);
          background: linear-gradient(
            270deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(0, 167, 181, 0.19) 82%,
            rgba(0, 167, 181, 0.22) 100%
          );
          &::before {
            background: #00a7b5;
          }
        }
      }
      .card-body {
        li {
          a {
            &:hover,
            &:focus {
              background: rgba(0, 167, 181, 0.15);
              color: rgb(0, 167, 181);
              span {
                background: #00a7b5;
                color: #fff;
              }
            }
            span {
              color: #00a7b5;
            }
          }
        }
      }
    }
  }
  &:nth-child(3) {
    .short_list.dashboardcard_head {
      .card-header {
        h5 {
          color: #9bd732;
          background: rgb(255, 255, 255);
          background: linear-gradient(
            270deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(155, 215, 50, 0.19) 82%,
            rgba(155, 215, 50, 0.22) 100%
          );
          &::before {
            background: #9bd732;
          }
        }
      }
      .card-body {
        li {
          a {
            &:hover,
            &:focus {
              background: rgba(155, 215, 50, 0.19);
              color: rgb(155, 215, 50);
              span {
                background: #9bd732;
                color: #fff;
              }
            }
            span {
              color: #9bd732;
            }
          }
        }
      }
    }
  }
  &:nth-child(4) {
    .short_list.dashboardcard_head {
      .card-header {
        h5 {
          color: #c24200;
          background: rgb(255, 255, 255);
          background: linear-gradient(
            270deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(194, 66, 0, 0.19) 82%,
            rgba(194, 66, 0, 0.22) 100%
          );
          &::before {
            background: #c24200;
          }
        }
      }
      .card-body {
        li {
          a {
            &:hover,
            &:focus {
              background: rgba(194, 66, 0, 0.11);
              color: #c24200;
              span {
                background: #c24200;
                color: #fff;
              }
            }
            span {
              color: #c24200;
            }
          }
        }
      }
    }
  }
}

.chart-card {
  height: 100%;
  width: 100%;
  display: inline-flex;
  box-shadow: 0rem 0rem 10px rgb(0 0 0 / 9%) !important;
  &.super_dashboard {
    height: calc(100% - 30px);
  }
  h3 {
    margin: 0 0 0.3rem;
    font-size: 1.2rem;
    font-weight: 500;
    letter-spacing: 0px;
  }
  .card-header {
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    h5 {
      margin: 0;
      color: $primary;
    }
  }
  .card-body {
    padding-bottom: 0;
    &.details_view {
      position: relative;
      canvas {
        width: 100% !important;
        margin: 0 0 -2rem;
        padding: 0rem 1rem 1rem;
        float: left;
        height: 100% !important;
        display: inline-block !important;
        max-height: 48vh;
      }
    }
    &.week_activity {
      canvas {
        width: 100% !important;
        margin: 0;
        height: 100% !important;
      }
    }
    canvas {
      width: calc(100% + 1.5rem) !important;
      float: left;
      clear: both;
      margin-left: -1rem;
    }
    .number_laws {
      tbody {
        tr {
          transition: all 0.2s ease;
          -webkit-transition: all 0.2s ease;
          -moz-transition: all 0.2s ease;
          -ms-transition: all 0.2s ease;
          -o-transition: all 0.2s ease;
          &:hover,
          &:focus {
            cursor: pointer;
            background: lighten($primary, 60%);
            transition: all 0.2s ease;
            -webkit-transition: all 0.2s ease;
            -moz-transition: all 0.2s ease;
            -ms-transition: all 0.2s ease;
            -o-transition: all 0.2s ease;
          }
        }
      }
    }
    .bar-companyData {
      height: 100%;
      display: inline-flex;
      flex-direction: column;
      justify-content: space-between;
    }
    thead {
      color: $primary;
      tr {
        th {
          border-bottom: 1px solid rgba($primary, 90%);
          border-top: 0;
          padding: 0.5rem 0.75rem;
          font-weight: 500;
          text-align: center;
          white-space: pre;
          &.category_name {
            width: 55%;
            text-align: left;
          }
        }
      }
    }
    tbody {
      tr {
        &:nth-child(odd) {
          background: lighten($primary, 64%);
        }
        td {
          text-align: center;
          padding: 0.6rem 0.75rem;
          &:first-child {
            text-align: left;
          }
          .badge {
            font-size: 0.85rem;
            &.badge-info {
              background: rgba($info, 0.2);
              color: $info;
            }
            &.badge-success {
              background: rgba($success, 0.2);
              color: $success;
            }
          }
        }
      }
    }
  }
  &.details_dashboard {
    height: calc(100% - 1.5rem);
    .card-body {
      canvas {
        margin: 0 0 -0.5rem;
        width: 100% !important;
        padding: 0 1rem 0 0.5rem;
      }
    }
  }
}
.bar-companyData {
  display: inline-block;
  width: 100%;
}
.dashboard-filter {
  .row {
    gap: 1rem;
    .col {
      padding: 0;
      margin: 0;
    }
  }
  .entity_filter_row {
    display: flex;
    .entity_filter {
      grid-gap: 1rem;
      gap: 1rem;
      display: flex;
      width: 50%;
      .form-group {
        width: 100%;
        margin-bottom: 0;
      }
      &:last-child {
        display: flex;
        justify-content: flex-end;
        .form-group {
          width: 25%;
        }
      }
    }
  }
}
.overall_status {
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
  li {
    width: 100%;
    display: inline-block;
    border-bottom: 1px solid #e8ecf2;
    &:last-child {
      border-bottom: none;
    }
    &:nth-child(odd) {
      a {
        background: lighten($primary, 64%);
      }
    }
    &:hover,
    &:focus {
      a {
        background: lighten($primary, 60%);
        span {
          color: $dark;
        }
      }
    }
    a {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.8rem 1.5rem;
      h6 {
        margin: 0;
        font-weight: 500;
        font-size: 0.95rem;
      }
      span {
        color: $dark;
        font-size: 0.9rem;
      }
    }
  }
}
.nav-tabs-custom.activity_tabs {
  background: lighten($primary, 56%);
  border-radius: 6px;
  padding: 0.3rem;
  box-shadow: none;
  border: none;
  .nav-item {
    .nav-link {
      padding: 0.4rem 1rem;
      transition: all 0.3s ease;
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -ms-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      line-height: normal;
      &.active {
        background: $white;
        color: $primary !important;
        box-shadow: 0rem 0rem 0.2rem 0.05rem hsl(0deg 0% 0% / 10%);
        border-radius: 5px;
        transition: all 0.3s ease;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
      }
      &:hover,
      &:focus {
        color: $info;
      }
      &::after {
        content: none;
      }
    }
  }
}
.activity_content {
  display: inline-block;
  width: 100%;
  height: 100%;
  float: left;
  .tab-pane {
    width: 100%;
    height: 100%;
    position: relative;
    &::after {
      content: '';
      width: 0.1rem;
      height: 100%;
      background: rgba($success, 30%);
      position: absolute;
      left: 1.2rem;
      top: 0;
      z-index: 0;
    }
    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      height: 100%;
      max-height: 24rem;
      min-height: 24rem;
      overflow-y: auto;
      width: 100%;
      position: relative;
      li {
        padding: 0.2rem 0.5rem 0.2rem 2.5rem;
        width: 100%;
        position: relative;
        &::before {
          content: '';
          background: $success;
          width: 0.8rem;
          height: 0.8rem;
          border-radius: 100px;
          -webkit-border-radius: 100px;
          -moz-border-radius: 100px;
          -ms-border-radius: 100px;
          -o-border-radius: 100px;
          position: absolute;
          left: 0.9rem;
          top: 50%;
          z-index: 1;
          transform: translate(0, -50%);
        }
        > div {
          border: 1px solid rgb(79 45 127 / 2%);
          border-radius: 6px;
          box-shadow: 0rem 0.1rem 0.2rem rgb(0 0 0 / 15%);
          display: flex;
          align-items: center;
          padding: 0.5rem;
          background: rgba(79, 45, 127, 0.0392156863);
          .activity_icon {
            color: #fff;
            width: 2.1rem;
            height: 2rem;
            padding: 0.4rem;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            &.approver_icon {
              background: #002E70;
            }
            &.completed_icon {
              background: #9bd732;
            }
            &.submitted_icon {
              background: #00a7b5;
            }
            &.audited_icon {
              background: #c24200;
            }
          }
          .activity_code {
            background: #002E700a;
            color: #002E70;
            padding: 0rem 0.3rem;
            font-size: 0.8rem;
            border-radius: 5px;
            border: 1px dashed #002E7036;
            white-space: nowrap;
          }
          .activity-date-time {
            color: #00000052;
          }
        }
      }
    }
  }
}
.calendar_card_bg {
  background: #fff;
}
.card-body {
  .dashboard_calendar {
    .expand_calendar {
      position: absolute;
      left: 8.9rem;
      color: $primary;
      top: 1.5rem;
      transform: translateY(0rem);
      transition: all 0.3s ease;
      -webkit-transform: translateY(0rem);
      -moz-transform: translateY(0rem);
      -ms-transform: translateY(0rem);
      -o-transform: translateY(0rem);
      &:hover,
      &:focus {
        color: $info;
        transform: translateY(-0.15rem);
        transition: all 0.3s ease;
      }
      &:focus {
        transform: translateY(0rem);
      }
      svg {
        width: 1rem;
        height: 1rem;
      }
    }
    .fc-bootstrap {
      padding: 1rem;
      .fc-header-toolbar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0.8rem;
        .fc-left {
          display: flex;
          align-items: center;
          .btn-group {
            margin: 0;
            .btn-primary {
              background: #002E7012;
              color: #002E70;
              border: none;
              font-size: 1.3rem;
              margin-right: 0.3rem;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 50%;
              height: 1.5rem;
              width: 1.5rem;
              border: 1px solid #002E7030;
              &:hover,
              &:focus {
                color: $info;
              }
            }
          }
          .fc-today-button {
            background: transparent;
            border-color: $primary;
            color: $primary;
            opacity: 1;
            font-weight: 500;
            text-transform: capitalize;
            padding: 0.3rem 0.5rem;
            height: auto;
            margin: 0;
            margin-left: 0.2rem;
            margin-right: 0.4rem;
            line-height: normal;
            transition: all 0.3s ease;
            -webkit-transition: all 0.3s ease;
            -moz-transition: all 0.3s ease;
            -ms-transition: all 0.3s ease;
            -o-transition: all 0.3s ease;
            order: 2;
            &:hover,
            &:focus {
              background: $primary;
              color: $white;
              transition: all 0.3s ease;
              -webkit-transition: all 0.3s ease;
              -moz-transition: all 0.3s ease;
              -ms-transition: all 0.3s ease;
              -o-transition: all 0.3s ease;
            }
          }
        }
        .fc-center {
          h2 {
            margin: 0;
            font-size: 1rem;
            font-weight: 500;
            color: $dark;
            text-transform: capitalize;
          }
        }
        .fc-right {
          .btn-group {
            background: lighten($primary, 56%);
            border-radius: 6px;
            padding: 0.3rem;
            box-shadow: none;
            border: none;
            .btn-primary {
              background: transparent;
              border: none;
              color: $dark;
              height: auto;
              padding: 0.3rem 0.5rem;
              font-weight: 500;
              text-transform: capitalize;
              transition: all 0.3s ease;
              -webkit-transition: all 0.3s ease;
              -moz-transition: all 0.3s ease;
              -ms-transition: all 0.3s ease;
              -o-transition: all 0.3s ease;
              &.active {
                background: $white;
                color: $primary !important;
                border: 0;
                box-shadow: 0rem 0rem 0.2rem 0.05rem hsl(0deg 0% 0% / 10%) !important;
                border-radius: 5px;
                transform: none;
                -webkit-transform: none;
                -moz-transform: none;
                -ms-transform: none;
                -o-transform: none;
              }
              &:hover,
              &:focus {
                color: $info;
                transition: all 0.3s ease;
                -webkit-transition: all 0.3s ease;
                -moz-transition: all 0.3s ease;
                -ms-transition: all 0.3s ease;
                -o-transition: all 0.3s ease;
                transform: none;
                -webkit-transform: none;
                -moz-transform: none;
                -ms-transform: none;
                -o-transform: none;
              }
            }
          }
        }
      }
      .fc-view-container {
        // background: #fff;
        // padding: 0.5rem;
        // border-radius: 10px;
        // box-shadow: 0 0 0.7rem #0000000a;
        > .fc-view {
          > table {
            border: none;
            .table-bordered {
              border: none;
            }
            .fc-head {
              color: $white;
              background: $primary;
              overflow: hidden;
              border-radius: 8px;
              -webkit-border-radius: 8px;
              -moz-border-radius: 8px;
              -ms-border-radius: 8px;
              -o-border-radius: 8px;
              tr {
                overflow: hidden;
                border-radius: 8px;
                -webkit-border-radius: 8px;
                -moz-border-radius: 8px;
                -ms-border-radius: 8px;
                -o-border-radius: 8px;
                .fc-head-container {
                  border: none;
                  overflow: hidden;
                  border-radius: 8px;
                  -webkit-border-radius: 8px;
                  -moz-border-radius: 8px;
                  -ms-border-radius: 8px;
                  -o-border-radius: 8px;
                  > .table-bordered {
                    border: none;
                    overflow: hidden;
                    border-radius: 8px;
                    -webkit-border-radius: 8px;
                    -moz-border-radius: 8px;
                    -ms-border-radius: 8px;
                    -o-border-radius: 8px;
                    table {
                      overflow: hidden;
                      border-radius: 8px;
                      -webkit-border-radius: 8px;
                      -moz-border-radius: 8px;
                      -ms-border-radius: 8px;
                      -o-border-radius: 8px;
                      border: 0;
                    }
                    .fc-day-header {
                      color: $white;
                      border: 0;
                      overflow: hidden;
                      border-radius: 8px;
                      -webkit-border-radius: 8px;
                      -moz-border-radius: 8px;
                      -ms-border-radius: 8px;
                      -o-border-radius: 8px;
                      padding: 0.3rem 0.75rem;
                    }
                  }
                }
              }
            }
            .fc-body {
              background: transparent;
              border: 0;
              > tr,
              tr {
                background: transparent;
                border: 0;
                td {
                  background: transparent;
                  border: 0;
                  padding: 0;
                }
              }
              .fc-day-grid {
                text-align: center;

                .fc-content-skeleton {
                  height: 100%;
                  table {
                    height: 100%;
                    thead {
                      tr {
                        border-top: 1px solid #0000000f;
                      }
                    }
                    thead,
                    tr {
                      height: 100%;
                      td {
                        vertical-align: middle;
                        text-align: center;
                        border-right: 1px solid #0000000f;
                        &:last-child {
                          border: none;
                        }
                        &.fc-today {
                          .fc-day-number {
                            width: 2rem;
                            height: 2rem;
                            border: 1px solid #ffffff94;
                            margin: 0 auto;
                            background: $success;
                            border-radius: 100px;
                            -webkit-border-radius: 100px;
                            -moz-border-radius: 100px;
                            -ms-border-radius: 100px;
                            -o-border-radius: 100px;
                            box-shadow: 0.1rem 0.1rem 0.3rem 0.1rem
                              hsl(0deg 0% 0% / 20%);
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            color: $white;
                            line-height: normal;
                            font-size: 1rem;
                          }
                        }
                      }
                    }
                  }
                }
                thead {
                  background: transparent;
                }
              }
            }
          }
        }
      }
    }
    thead {
      color: $dark;
    }
    &.col-lg-12 {
      .fc-view-container {
        .fc-scroller {
          height: 100% !important;
        }
        > .fc-view {
          > table {
            .fc-head {
              .fc-head-container {
                table {
                  .fc-day-header {
                    padding: 0.5rem 0.75rem;
                    font-size: 1rem;
                  }
                }
              }
            }
            .fc-body {
              > tr,
              tr {
                td {
                  .fc-day-grid {
                    .fc-row {
                      height: 8rem !important;
                    }
                  }
                }
              }
              .fc-day-grid {
                .fc-content-skeleton {
                  table {
                    thead {
                      tr {
                        border-top: 1px solid #410f8829;
                        .fc-today {
                          .fc-day-number {
                            width: 3.5rem;
                            height: 3.5rem;
                            font-size: 1.6rem;
                          }
                        }
                        .fc-past,
                        .fc-future {
                          .fc-day-number {
                            font-size: 1.2rem;
                          }
                        }
                      }
                    }
                    thead,
                    tr {
                      td {
                        border-right: 1px solid #410f8829;
                        &.fc-today {
                          .fc-day-number {
                            font-size: 1.4rem;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .calendar_recent {
    height: 100%;
    position: relative;
    padding: 1rem;
    padding-left: 0;
    // &::before {
    //   content: "";
    //   background: rgba($dark, 30%);
    //   height: calc(100% - 2rem);
    //   width: 1px;
    //   position: absolute;
    //   left: 0;
    //   top: 1rem;
    // }
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      height: 100%;
      max-height: 27.5rem;
      min-height: 27.5rem;
      overflow-y: auto;
      width: 100%;
      position: relative;
      li {
        width: calc(100% - 1rem);
        padding: 0.4rem 0.4rem;
        margin: 0.5rem 0.5rem;
        border-radius: 10px;
        box-shadow: 0.1rem 0.1rem 0.5rem #0000002b;
        background: #fff;
        transition: all 0.3s ease;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        &:last-child {
          border-bottom: none;
        }
        &:hover,
        &:focus {
          transition: all 0.3s ease;
          -webkit-transition: all 0.3s ease;
          -moz-transition: all 0.3s ease;
          -ms-transition: all 0.3s ease;
          -o-transition: all 0.3s ease;
          background: #002E703b;
          transform: translateX(-5px);
          a {
            color: $primary;
            .date {
              background: #fff;
            }
          }
        }
        a {
          display: flex;
          color: $dark;
          align-items: center;
          width: 100%;
          gap: 0.7rem;
          transition: all 0.3s ease;
          -webkit-transition: all 0.3s ease;
          -moz-transition: all 0.3s ease;
          -ms-transition: all 0.3s ease;
          -o-transition: all 0.3s ease;
          .date {
            background: #002E703b;
            border-radius: 8px;
            -webkit-border-radius: 8px;
            -moz-border-radius: 8px;
            -ms-border-radius: 8px;
            -o-border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            padding: 0.4rem;
            color: $primary;
            gap: 0.1rem;
            width: 3.5rem;
            text-align: center;
            box-shadow: 0 0 0.1rem rgb(0 0 0 / 66%);
            border: 1px solid #ffffff4f;
            b {
              text-transform: uppercase;
              font-weight: 500;
              color: $primary;
            }
            small {
              width: 100%;
              white-space: pre;
            }
          }
          .recent_task {
            width: calc(100% - 4.1rem);
            display: inline-block;
            p {
              width: 100%;
              margin: 0 0 0.4rem;
              white-space: pre;
              text-overflow: ellipsis;
              overflow: hidden;
              line-height: normal;
            }
            .badge {
              border-radius: 100px;
              -webkit-border-radius: 100px;
              -moz-border-radius: 100px;
              -ms-border-radius: 100px;
              -o-border-radius: 100px;
              padding: 0.2rem 0.4rem;
              line-height: normal;
            }
          }
        }
      }
    }
  }
}
/*/==========================End-Dashboard==========================/*/
