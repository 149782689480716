/* ==============
  Bootstrap-custom
===================*/

//Dropdown
.dropdown-menu {
  padding: 4px 0;
  font-size: $base-font;
  box-shadow: $shadow;
  background-color: $white;
  border-color: $gray-200;
  margin: 0;
}
.dropdown-item {
  background-color: $white;
  padding: .55rem 1.5rem;
  
  &:active,&:hover {
    background-color: lighten($primary, 58%);
    color: $dark;
  }
}

.dropdown-item.active, .dropdown-item:active {
  background-color: $gray-100;
  color: $dark;
}

.breadcrumb>li+li:before {
  padding: 0 5px;
  color: $muted;
  content: "\f105" !important;
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
}

// blockquote

.blockquote {
  padding: 10px 20px;
  margin-bottom: 20px;
  border-left: 4px solid $gray-200;
}

.blockquote-reverse {
  border-left: 0;
  border-right: 4px solid $gray-200;
  text-align: right;
}

//Background color
.bg-primary {
  background-color: $primary !important;
}

.bg-success {
  background-color: $success !important;
}

.bg-info {
  background-color: $info !important;
}

.bg-warning {
  background-color: $warning !important;
}

.bg-danger {
  background-color: $danger !important;
}

.bg-muted {
  background-color: $muted !important;
}

.bg-white {
  background-color: $white !important;
}

.bg-orange {
  background-color: $orange !important;
}


//Text Color
.text-white {
  color: $white !important;
}

.text-danger {
  color: $danger !important;
}

.text-muted {
  color: $muted !important;
}

.text-primary {
  color: $primary !important;
}

.text-warning {
  color: $warning !important;
}

.text-success {
  color: $success !important;
}

.text-info {
  color: $info !important;
}

.text-dark {
  color: $dark !important;
}


//Badge

.badge {
  font-weight: 500;
}


.badge-primary {
  background-color: $primary;
}

.badge-success {
  background-color: $success;
}

.badge-info {
  background-color: $info;
}

.badge-warning {
  background-color: $warning;
  color: $white;
}

.badge-danger {
  background-color: $danger;
}

.badge-dark {
  background-color: $dark;
}

// typography

dt {
  font-weight: 500;
}

// spinner
.spinner-border, .spinner-grow {
  margin-right: 10px;
  margin-top: 10px;
}

// checkbox
.preloader-main {
  width: 100vw;
  height: 100vh;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  background: hsl(0deg 0% 0% / 60%);
  z-index: 9999;
}
// .multi-spinner-container {
//   width: 125px;
//   height: 125px;
//   overflow: hidden;
//   -webkit-animation: scale-up-center 1s ease-in-out infinite alternate-reverse both;
// 	animation: scale-up-center 1s ease-in-out infinite alternate-reverse both;
//   .multi-spinner {
//     -webkit-animation: spin 2s cubic-bezier(0.17, 0.49, 0.96, 0.76) infinite;
//     animation: spin 2s cubic-bezier(0.17, 0.49, 0.96, 0.76) infinite;
//     img {
//       width: 100%;
//       height: 100%;
//     }
//   }
// }
// @-webkit-keyframes scale-up-center {
//   0% {
//     -webkit-transform: scale(0.6);
//     transform: scale(0.6);
//   }
//   100% {
//     -webkit-transform: scale(1);
//     transform: scale(1);
//   }
// }
// @keyframes scale-up-center {
//   0% {
//     -webkit-transform: scale(0.6);
//     transform: scale(0.6);
//   }
//   100% {
//     -webkit-transform: scale(1);
//     transform: scale(1);
//   }
// }
// @keyframes spin {
//   from {
//     -webkit-transform: rotate(0deg);
//     transform: rotate(0deg);
//   }
//   to {
//     -webkit-transform: rotate(360deg);
//     transform: rotate(360deg);
//   }
// }
// .custom-control-input{
//   &:checked~.custom-control-label{
//     &:before {
//       border-color: $primary;
//       background-color: $primary;
//     }
//   }
// }
.preloader-wrapper{
  transform: scale(1.5);
   .loader{
    height: 25px;
    width: 1px;
    position: absolute;
    animation: rotate 3.5s linear infinite;
    .dot{
      top: 30px;
      height: 7px;
      width: 7px;
      background: #6dc000;
      border-radius: 50%;
      position: relative;
    }
    &:nth-child(1){
      animation-delay: 0.15s;
    }
    &:nth-child(2){
      animation-delay: 0.3s;
    }
    &:nth-child(3){
      animation-delay: 0.45s;
    }
    &:nth-child(4){
      animation-delay: 0.6s;
    }
    &:nth-child(5){
      animation-delay: 0.75s;
    }
    &:nth-child(6){
      animation-delay: 0.9s;
    }
  }
}
@keyframes rotate {
  30%{
    transform: rotate(220deg);
  }
  40%{
  transform: rotate(450deg);
    opacity: 1;
 }
 75%{
  transform: rotate(720deg);
  opacity: 1;
 }
 76%{
  opacity: 0;
 }
 100%{
  opacity: 0;
  transform: rotate(0deg);
 }
}
