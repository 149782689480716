/*
 Template Name: Veltrix - Responsive Bootstrap 4 Admin Dashboard
 Author: Themesbrand
 Website: www.themesbrand.com
 File: Main Css File
*/

@import 'variables';
/* ======
01. General
   ====== */
@import 'general';
/* ======
02. Bootstrap-custom
   ====== */
@import 'bootstrap-custom';
/* ======
03. Helper
   ====== */
@import 'helper';
/* ======
04. Waves Effect
   ====== */
@import 'waves';
/* ======
05. Menu
   ====== */
@import 'menu';
/* ======
06. Buttons
   ====== */
@import 'buttons';
/* ======
07. Cards
   ====== */
@import 'cards';
/* ======
08. Email
   ====== */
@import 'email';
/* ======
09. Summernote
   ====== */
@import 'summernote';
/* ======
10. Alerts
   ====== */
@import 'alerts';
/* ======
11. Demo Only
   ====== */
@import 'demo-only';
/* ======
12. Pagination
   ====== */
@import 'pagination';
/* ======
13. Progress
   ====== */
@import 'progress';
/* ======
14. Popover - Tooltips
   ====== */
@import 'popover-tooltips';
/* ======
15. Sweet Alerts
   ====== */
@import 'sweet-alert';
/* ======
16. Tabs
   ====== */
@import 'tabs';
/* ======
17. Form-elements
   ====== */
@import 'form-elements';
/* ======
18. Form-validation
   ====== */
@import 'form-validation';
/* ======
19. Form-advanced
   ====== */
@import 'form-advanced';
/* ======
20. Form-editors
   ====== */
@import 'form-editor';
/* ======
21. Form-upload
   ====== */
@import 'form-upload';
/* ======
22. Form-Wizard
   ====== */
@import 'form-wizard';
/* ======
22. Calendar
   ====== */
@import 'calendar';
/* ======
23. Tables
   ====== */
@import 'tables';
/* ======
24. Maps
   ====== */
@import 'maps';
/* ======
25. Range-slider
   ====== */
@import 'range-slider';
/* ======
26. Session-timeout
   ====== */
@import 'session-timeout';
/* ======
27. Charts
   ====== */
@import 'charts';
/* ======
28. Widgets
   ====== */
@import 'widgets';
/* ======
29. TImeline
   ====== */
@import 'timeline';
/* ======
30. Directory
   ====== */
@import 'directory';
/* ======
31. Extra Pages
   ====== */
@import 'extra-pages';
/* ======
32. gallery
   ====== */
@import 'gallery';
/* ======
33. Invoice
   ====== */
@import 'invoice';
/* ======
34. Account Pages
   ====== */
@import 'account-pages';
/* ======
35. Responsive
   ====== */


/*-----------End-Dashboard-------------*/
.switch-sm {
  .react-switch-bg {
    height: 22px !important;
    width: 50px !important;
    background: #9BD732 !important;

    >div {
      height: 22px !important;
    }

    svg {
      display: none;
    }
  }

  &.false {
    .react-switch-bg {
      background: #eb5656 !important;
    }
  }

  .react-switch-handle {
    height: 20px !important;
    width: 20px !important;

    &:hover,
    &:focus {
      box-shadow: none !important;
      outline: none !important;
    }
  }

}

/*----------------------search-box------------------*/
.search_box {
  float: right;
  position: relative;
  flex: 0.7;
  button {
    color: #002E70;
    position: absolute;
    top: 50%;
    right: 0.3rem;
    transform: translate(0, -50%);
    width: 1.5rem;

    &:hover,
    &:focus {
      color: $orange;
    }
  }
  .close-btn {
    width: auto;
    right: 2rem;
    color: #868686;

    svg {
      width: 0.6rem;
      height: 0.6rem;
    }
  }

  input {
    border-radius: 30px;
    border: 1px solid #002E70a4;
    padding: 0.3rem 3rem 0.3rem 0.5rem;
    width: 100%;
    &:focus {
      border: 1px solid #c24200;
      outline: none;
    }
  }

  .search_input {
    border: 1px solid hsl(0, 0%, 80%);
    border-radius: 0.5rem;
    padding: 0.3rem 0.3rem 0.3rem 2rem;
    transition: all 0.3s ease-in-out;

    &:focus {
      border: 1px solid #002E70 !important;
      outline: none;
    }
  }
}

/*---------------Scroll-design--------------*/
::-webkit-scrollbar {
  width: 10px;
  background: rgba(19, 47, 78, 0.3);
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #5e72e41a;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #002E70;
}

::-webkit-scrollbar-thumb:window-inactive {
  background: darken($primary, 20%);
}

.small-scroll,
.report_highpoint {
  &::-webkit-scrollbar {
    width: 6px;
    background: rgba(19, 47, 78, 0.3);
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px #5e72e41a;
    -webkit-border-radius: 10px;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #002E70;
  }

  &::-webkit-scrollbar-thumb:window-inactive {
    background: darken($primary, 20%);
  }
}

.template-box {
  // height: calc(100vh - 10.1rem);
  position: relative;

  .card {
    position: sticky;
    top: 5.7rem;
  }
}

.edit_template ul,
.Default_notifications ul {
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background: rgba(19, 47, 78, 0.3);
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px #5e72e41a;
    -webkit-border-radius: 10px;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #3b215f;
  }

  &::-webkit-scrollbar-thumb:window-inactive {
    background: darken($primary, 20%);
  }
}

/*---------------End-scroll-design--------------*/

/*---------------Start-Modal-design--------------*/
.modal-dialog {
  .modal-content {
    border-radius: 10px;

    .modal-header {
      padding: 0.5rem 1rem;
      background: $primary;
      border-radius: 8px 8px 0 0;

      .modal-title {
        font-size: 1.2rem;
        font-weight: 500;
        line-height: normal;
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin: 0;
        color: $white;

        .modal-action {
          display: flex;
          align-items: center;

          .full_btn {
            padding: 0.3rem 0.5rem;
            color: $white;

            &:hover,
            &:focus {
              color: #7494ea;
            }

            i {
              display: flex;
              align-items: center;
              line-height: normal;
            }
          }

          .modal_close {
            background: transparent;
            border: 0;
            font-size: 1.7rem;
            padding: 0.2rem 0.5rem;
            transform: rotate(0deg);
            transition: all 0.3s ease;
            color: $white;

            &:hover,
            &:focus {
              cursor: pointer;
              color: #7494ea;
              text-decoration: none;
              transform: rotate(180deg);
              transition: all 0.3s ease;
            }
          }
        }
      }
    }
  }
}

/*---------------------------------allocation-form------------*/
.selectbox [class*='indicatorContainer'] {
  padding-left: 0;
  padding-right: 0;
}

.row {
  --bs-gutter-x: 1.1rem !important;

  .form-group {
    margin-bottom: 0.5rem;
    position: relative;
  }
}

/*------------------Multi-select-dropdown-----------------------*/
.modal-xxl {
  max-width: 1400px;
}

/*---------------  header  --------------*/
.container {
  max-width: calc(100% - 4rem) !important;
  margin: 0 2rem !important;
  padding: 0 !important;
}

.navbar {
  padding: 0 !important;
  position: fixed !important;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  &.dashboard-page {
    &::before {
      content: none;
    }
  }
  &::before {
    content: '';
    background: #f4f5ff;
    // background: transparent;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: calc(100% - 10px);
    z-index: -1;
  }
  .container {
    background: $primary;
    border-radius: 0 0 30px 30px;
    max-width: 100%;
    margin: 0 2rem;
    .navbar-brand {
      height: 85px;
      width: 280px;
      display: flex;
      align-items: center;
      padding: 0.5rem 1.7rem;
      padding-right: inherit;
      a {
        height: auto;
        width: 8rem;
        display: flex;
        align-items: center;
        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
    .navbar-nav {
      gap: 1.5rem;
      align-items: center;
      margin-right: 1rem;
      .nav-link {
        color: #fff;
        padding: 0rem;
        transition: all 0.3s ease-in;
        font-size: clamp(0.8rem, 0.95vw, 1rem);
        &.active {
          color: #9bd732;
        }

        &:hover {
          color: #9bd732;
        }
      }

      .dropdown {
        &.active {
          .nav-link {
            color: #9bd732;
          }
        }
      }

      .right_header {
        display: flex;
        align-items: center;
        gap: 1.5rem;

        .notification_btn {
          .btn {
            padding: 0;
            background: transparent;
            border: none;
            transform: none;

            &:hover,
            &:active,
            &:focus {
              background: transparent;
              transform: none;
            }

            &::after {
              display: none;
            }

            svg {
              width: 1.5rem;
              height: 1.5rem;
            }
          }
        }

        .profile_btn {
          .btn {
            padding: 0;
            background: transparent;
            border: none;
            display: flex;
            transform: none;
            align-items: center;

            &:hover,
            &:active,
            &:focus {
              background: transparent;
              border: none !important;
            }

            &::after {
              display: none;
            }

            .profile_name {
              text-align: end;
              h5 {
                margin: 0;
                color: #fff;
                font-size: clamp(0.9rem, 1vw, 1rem);
                font-weight: bold;
                line-height: normal;
              }
              > div {
                display: flex;
                align-items: center;
                justify-content: end;
                gap: 0.9rem;

                p {
                  margin: 0;
                  // margin-top: 0.1rem;
                  white-space: nowrap;
                  overflow: hidden;
                  max-width: 5.7rem;
                  text-overflow: ellipsis;
                  line-height: normal;
                  font-size: 0.9rem;
                }

                .profile-country-box {
                  max-width: 5.7rem;
                  display: inline-block;
                  position: relative;
                  font-size: 0.9rem;
                  // white-space: nowrap;
                  // overflow: hidden;
                  // text-overflow: ellipsis;
                  line-height: normal;

                  // img {
                  //   width: 100%;
                  //   border-radius: 0.1rem;
                  // }
                  &::before {
                    content: '';
                    width: 0.26rem;
                    height: 0.26rem;
                    position: absolute;
                    top: 55%;
                    transform: translateY(-50%);
                    left: -0.6rem;
                    background-color: #ffffff;
                    border-radius: 50%;
                  }
                }
              }
            }

            .user-profile {
              background: #d08cba;
              padding: 0.5rem;
              width: 2.5rem;
              height: 2.5rem;
              margin-left: 0.6rem;
              font-size: 1.2rem;
              border-radius: 30px;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }

          .dropdown-menu {
            right: 0;
            left: auto;
            min-width: 16rem;
            .dropdown-item {
              padding: 0.4rem 0.6rem;
              &.vendor_dropdown {
                svg {
                  width: 1.5rem;
                  height: 1.5rem;
                  margin-right: 0.3rem;
                }
              }
              .sync-data {
                span {
                    display: inline-block;
                    svg {
                      width: 0.9rem;
                      height: 0.9rem;
                    }
                  }
              }              
              svg {
                margin-right: 0.2rem;
                flex: none;
                width: 1.3rem;
                height: 1.3rem;
              }
              > span {
                word-wrap: break-word;
                white-space: break-spaces;
                font-size: 0.9rem;
              }
            }
          }
        }
      }

      .dropdown-menu {
        box-shadow: 0 0 0.9rem #00000017;

        .dropdown-item {
          padding: 0.25rem 0.6rem;
          align-items: center;
          display: flex;

          &:hover,
          &:active {
            background-color: lighten($primary, 55%);
          }

          &.active {
            background-color: $primary;
          }

          >span {
            display: inline-flex;
            flex-direction: column;
            line-height: normal;

            small {
              font-size: 0.7rem;
            }
          }
        }
      }
    }
  }
}

.dashboard_header {
  .navbar {
    &::before {
      background: transparent;
    }
  }
}

.backend {
  .main-inner {
    padding-top: 5.3rem;
  }
}

.sub-footer {
  background: #e5edfa;
  box-shadow: 0rem 0.5rem 1rem 0.4rem rgb(0 0 0 / 20%);
  padding: 1rem 0.5rem;
  .row {
    > div  {
      &.sub-footer-email {
        display: flex;
        justify-content: end;
        flex-wrap: wrap;
        gap: 0.5rem;
      }
      a {
        text-decoration: none;
        display: flex;
        gap: 0.3rem;
        padding: 0;
        align-items: center;
        &:is(:hover, :focus) {
          color: $primary;
          box-shadow: none;
        }
        span {
          width: 1.5rem;
          height: 1.5rem;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid;
          border-radius: 100px;
          padding: 0.3rem;
        }
      }
    }
  }
}

.main-inner {
  padding-top: 7rem;
  padding-bottom: 1.5rem;
  min-height: calc(100vh - 2.1rem);
}

.section-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0;
  gap: 5vw;
  >div {
    display: flex;
    align-items: center;

    h3 {
      font-size: 1.7rem;
      color: #002E70;
      margin: 0;
      font-weight: 600;
    }
  }

  .view_title_head {
    display: flex;
    align-items: start;
    justify-content: space-between;

    p {
      width: 100%;
    }
  }

  .header-action-item {
    gap: 0.5rem;
    flex: 1;
    justify-content: flex-end;
    a {
      text-decoration: none;
    }
  }
}

.separate-head {
  margin: 0rem 0 0.9rem;
  color: #002E70;
}

.back_btn {
  background: transparent;
  border: 1px solid #002E70;
  color: #002E70;
  padding: 0;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.6rem;
  height: 1.6rem;
  margin-right: 0.5rem;
  transition: all 0.3s ease-in;

  svg {
    width: 0.9rem;
    height: 0.9rem;
  }

  &:hover,
  &:focus {
    background: #002E70;
    color: #fff;
  }
}

.form-switch {
  display: flex;
  align-items: center;

  input {
    width: auto;
    height: auto;
    padding: auto;
  }

  .form-check-input {
    width: 3em;
    height: 1.5em;

    &:focus {
      box-shadow: none;
    }

    &:checked {
      background-color: #9bd732 !important;
      border-color: #9bd732 !important;
    }
  }

  .form-check-label {
    margin-left: 0.5rem;
    font-size: 1rem;
  }
}

.table {
  thead {
    tr {
      background: #002E70;
      color: #fff;
      border: none !important;

      th {
        border-top: none !important;
        border-left: none !important;
        border-bottom: none !important;
        border-right-style: inset !important;

        &:last-child {
          border-right-style: none !important;
        }
      }
    }
  }

  tbody {
    background: #fff;

    tr {
      border: 1px solid #00000009;

      td {
        border: 1px solid #00000009;
      }
    }

    .first_name_td {
      align-items: center;
      gap: 0.5rem;
      display: flex;
      justify-content: flex-end;
      white-space: pre;

      .short-name {
        background: #9bd732;
        color: #fff;
        padding: 0;
        width: 1.8rem;
        border-radius: 30px;
        font-size: 0.8rem;
        height: 1.8rem;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 0.4rem;
        line-height: normal;
      }

      p {
        margin: 0;
        font-weight: 500;
      }
    }

   
  }
}
.rebate_history {
  &.rebate_history-tables {
    margin-bottom: 0;
    .react-bootstrap-table {
      > table {
        > thead {
          > tr {
            > th {
              font-size: 0.9rem;
            }
          }
        }
        > tbody {
          > tr {
            > td {
              font-weight: bold;
              color: $black;
              &.rebates_year {
                width: 5rem;
              }
              &.volume_type {
                width: 10rem;
              }
              &.total_volume {
                width: 11rem;
              }
              &.q1_rebate, &.q2_rebate, &.q3_rebate, &.q4_rebate {
                width: 6rem;
              }
              &.total_amount {
                width: 9.2rem;
              }
            }
            &:hover {
              background-color: transparent !important;
            }
            &:nth-child(even) {
              background-color: transparent !important;
            }
            &.even-row {
             ~ .expanding-row {                
                  table {
                    tr {                      
                      &:last-child {
                        td {
                          &:first-child, &:last-child {
                            border-radius: 0;
                          }
                        }
                      }
                      &:nth-child(odd) {
                        background-color: #f4f8ff !important;
                        &:hover {
                          background-color: #f4f8ff !important;
                        }
                      }
                    }
                  }
             } 
            }
            &.odd-row {
              background: #e2edff;
              &:hover {
                background: #e2edff !important;
              }
              + .expanding-row {
                background: #e2edff;
                table {
                  tr {
                    background-color: #e3eeff66;
                    &:hover {
                      background-color: #e3eeff66 !important;
                    }
                    &:last-child {
                      td {
                        &:first-child, &:last-child {
                          border-radius: 0;
                        }
                      }
                    }
                    &:nth-child(even) {
                      background-color: #ebf3ff !important;
                      &:hover {
                        background-color: #ebf3ff !important;
                      }
                    }
                  }
                }
              } 
            }
            &.expanding-row {
              > td {
                padding: 0 !important;
                .react-bootstrap-table {
                  table {
                    thead {
                      tr {
                        th {
                          padding: 0 !important;
                          font-size: 0;
                          &:first-child {
                            border-radius: 0;
                          }
                          span {
                            &::after {
                              content: none;
                            }
                          }
                        }
                      }
                    }
                    tbody {
                      tr {
                        td {
                          font-weight: 500;
                          color: #667085;
                          &.rebates_name {
                            padding-left: 1.5rem !important;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .table {
    thead {
      top: -1rem;
    }
    tbody {
      // tr.rebate-detail-total {
      //   display: none;
      // }
      position: relative;      
      tr.rebate-detail-total {
        border-radius: 0 0 0.5rem 0.5rem;
        overflow: hidden;
        // display: table-row;
        // position: absolute;
        // bottom: 0rem;
        // width: 100.3%;
        // left: -0.1rem;
        // right: 0;
        // z-index: 1;
        // top: 100%;
        // display: contents;
        bottom: 0rem;
        z-index: 1;
        position: absolute;
        display: inline-table;
        top: 99.7%;
        td {
          background: lighten($primary, 60%) !important;
          padding: 0.4rem 0.8rem;
          color: $black !important;
          font-weight: bold;
          text-transform: capitalize;
          &:nth-child(1) {
            width: 5rem;
          }
          &:nth-child(2){
            width: 9.4rem;
          }
          &:nth-child(3){
            width: 10rem;
          }
          &:nth-child(4) {
            width: 11.5rem;
          }
          &:nth-child(5), &:nth-child(6), &:nth-child(7), &:nth-child(8) {
            width: 6rem;
          }
          &:nth-last-of-type(1) {                  
            width: 9.2rem;
          }    
    
          &:first-letter {
            // font-size: 0;
          }
        }
      }
    }
    tfoot{
      tr {
        &.rebate-detail-total {   
            
              background-color: #a3c9ff !important;           
              border: 1px solid rgba(0, 0, 0, 0.0352941176);
              border-radius: 0 0 0.3rem 0.3rem;
                      td {
                        background: #a3c9ff !important;
                        color: #000 !important;
                        font-weight: 700;                        
                        text-transform: capitalize;
                        &:first-child {
                          border-radius: 0 0 0 0.3rem;
                        }
                        &:last-child {
                          border-radius: 0 0 0.3rem 0;
                        }
                      }
          }
      }
    }
    
  }
}
.front_main {
  .rebate_history-tables {
    margin-bottom: 3rem;
    &.rebate_history {
      .table {
        tbody {
          position: relative;
          tr {
            &.rebate-detail-total {   
                // display: table-row !important;
                position: absolute;
                bottom: -2rem;
                width: 100%;
                left: 0;
                right: 0;
                z-index: 1;
                display: flex !important;                  
                  justify-content: space-between;
                  background-color: #a3c9ff !important;              


                // td {
                //   width: 14.4rem;
                //   &:nth-last-of-type(1) {
                //     width: 9.2rem;
                //   }
                //   &:nth-last-of-type(2), &:nth-last-of-type(3), &:nth-last-of-type(4), &:nth-last-of-type(5) {
                //     width: 6rem;
                //   }
                // }   
                td {
                  &:nth-child(1) {
                    width: 5rem;
                  }
                  &:nth-child(2){
                    width: 49.5rem;
                  }
                  &:nth-child(3){
                    width: 10rem;
                  }
                  &:nth-child(4) {
                    width: 11.5rem;
                  }
                  &:nth-child(5), &:nth-child(6), &:nth-child(7), &:nth-child(8) {
                    width: 6rem;
                  }
                  &:nth-last-of-type(1) {                  
                    width: 9.2rem;
                  }                                   
                }         
              }
          }
        }  
      }
    }  
     tfoot{          
      display: none;
          &:first-of-type{
            display: table-footer-group !important;
          }
         }
  }
}

.section-head {
  .creat_btn {
    background: #002E70;
    border: none;
    border-radius: 30px;
    font-size: $base-font;
    display: flex;
    align-items: center;
    white-space: pre;
    padding: 0.3rem 0.8rem;
    flex: none;

    &:hover,
    &:active,
    &:focus {
      transform: none !important;
      border: none !important;
      background-color: darken($primary, 5%);
    }

    svg {
      width: 1rem;
      height: 1rem;
      flex: none;
      margin-right: 0.5rem;
    }

    &.edit_hra_btn {
      color: #fff;
      padding: 0.2rem 0.6rem;
      font-size: 0.87rem;
      text-decoration: none;

      &:hover,
      &:focus {
        background-color: #2c1947;
      }

      svg {
        width: 0.8rem;
        height: 0.8rem;
      }
    }
  }
}
.creat_btn{
  border: none;
}
.creat_btn {
  background: #002E70;
  border: none !important;
  border-radius: 30px;
  display: flex;
  align-items: center;
  white-space: pre;
  padding: 0.4rem 0.8rem;
  flex: none;
  font-size: 0.9rem;

  &:hover,
  &:active,
  &:focus {
    transform: none !important;
    border: none !important;
  }

  svg {
    width: 1rem;
    height: 1rem;
    flex: none;
    margin-right: 0.5rem;
  }

  &.edit_hra_btn {
    color: #fff;
    padding: 0.2rem 0.6rem;
    font-size: 0.87rem;

    &:hover,
    &:focus {
      background-color: #2c1947;
    }

    svg {
      width: 0.8rem;
      height: 0.8rem;
    }
  }
}

.header-action-item {
  .filter_btn {
    .btn {
      background: none !important;
      border: none !important;
      color: #002E70;
      padding: 0;
      transform: none !important;

      svg {
        height: 1.2rem;
        width: 1.2rem;
      }

      &::after {
        display: none;
      }

      &:hover,
      &:active,
      &:focus {
        color: #372058 !important;
        background: none !important;
        border: none !important;
      }
    }
  }
}


/*-------------------Dashboard------------------*/
.dashboard {
  background: #002E70;
  padding: 4rem 2rem 2rem;
  margin: -2rem 0 0;
  border-radius: 0 0 30px 30px;

  img {
    max-width: 100%;
  }
}

.dashboard-charts {
  .card {
    h5 {
      position: relative;
      padding: 0.2rem 0 0.2rem 0.7rem;
      margin: 0.8rem 0 0 0.8rem;
      margin-bottom: 0.7rem;
      font-size: 1.1rem;
      color: #002E70;
      background: linear-gradient(90deg,
          rgba(0, 46, 112, 0.3) 0%,
          rgba(255, 255, 255, 0) 100%);
      line-height: 1.4;
      max-width: 30rem;

      &::before {
        content: '';
        background: #002E70;
        width: 0.3rem;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }
}

// category page
.category-main {
  .category-head {
    .header-action-item {
      a {
        color: $primary;
        text-decoration: none;
      }
    }
  }

  .form-switch {
    display: flex;
    align-items: center;

    input {
      width: 2.1rem !important;
      height: 1rem !important;
      border-radius: 20px !important;
    }
  }
}
@property --p{
  syntax: '<number>';
  inherits: true;
  initial-value: 0;
}
.category-modal {
  &.admin-support-ticket {
    .support-request-modal-list {
      margin: 0 0 1rem;
      display: flex;
      flex-wrap: wrap;
      background-color: #eeeeee;
      padding: 0.3rem 0.5rem;
      .support-request-modal-data {
        width: 50%;
        border-radius: 0.2rem;
        display: flex;
        gap: 0.6rem;
        align-items: center;
        margin-bottom: 0;
        padding: 0.3rem;
        &.address {
          flex: 1;
          width: 100%;
        }
        .support-icon-box {
          width: 1.8rem;
          height: 1.8rem;
          background: rgba($primary, 0.10);
          svg {
            width: 0.9rem;
            height: 0.9rem;
          }
        }
      }
    }
    .file-attchment {
      input[type="file"] {
        display: none;
      }      
      label {
        border: 1px solid rgba($primary, 0.5);
        display: inline-block;
        padding: 0.4rem 0.7rem;
        cursor: pointer;
        display: inline-flex;
        justify-content: flex-start;
        gap: 0.3rem;
        align-items: center;
        color: $primary;
        font-size: 0.9vw;
        font-weight: normal;
        border-radius: 0.3rem;
        background: rgba($primary, 0.15);
        transition: all 0.3s ease;
        &:is(:hover, :focus) {
          background: $primary;
          color: $white;
          transition: all 0.3s ease;
        }
        svg {
          width: 1rem;
          height: 1rem;
        }
        span {
          small {
            color: #656565;
            margin-left: 0.3rem;
            font-size: 0.7rem;
          }
        }
      }
      .pie {
        --p:20;
        --b:22px;
        --c:darkred;
        --w:150px;
        
        width:var(--w);
        aspect-ratio:1;
        position:relative;
        display:inline-grid;
        margin:5px;
        place-content:center;
        font-size:25px;
        font-weight:bold;
        font-family:sans-serif;
      }
      .pie:before,
      .pie:after {
        content:"";
        position:absolute;
        border-radius:50%;
      }
      .pie:before {
        inset:0;
        background:
          radial-gradient(farthest-side,var(--c) 98%,#0000) top/var(--b) var(--b) no-repeat,
          conic-gradient(var(--c) calc(var(--p)*1%),#0000 0);
        -webkit-mask:radial-gradient(farthest-side,#0000 calc(99% - var(--b)),#000 calc(100% - var(--b)));
                mask:radial-gradient(farthest-side,#0000 calc(99% - var(--b)),#000 calc(100% - var(--b)));
      }
      .pie:after {
        inset:calc(50% - var(--b)/2);
        background:var(--c);
        transform:rotate(calc(var(--p)*3.6deg)) translateY(calc(50% - var(--w)/2));
      }
      @keyframes p {
        from{--p:0}
      }
    }
    .uploaded-attachment {
      display: flex;
      gap: 0.5rem;
      li {
        position: relative;
        width: 20%;
        display: flex;
        place-content: center;
        background: rgba($primary, 0.10);
        button {
          position: absolute;
          padding: 0.2rem;
          right: 0.3rem;
          top: 0.3rem;
          border-radius: 100px;
          display: flex;
          place-items: center;
          aspect-ratio: 1 / 1;
          background: rgba($danger, 0.3);
          &:is(:hover, :focus) {
            background: $primary;
            color: $white !important;
          }
          svg {
            width: 0.8rem;
            height: 0.8rem;
          }
        }
        img {
          object-fit: cover;
          max-width: 100%;
        }
      }
    }
  }
  .btn-close {
    filter: brightness(0) invert(1) !important;

    &:focus {
      box-shadow: none !important;
    }
  }
  .modal-body {
    padding: 1rem;

    form.form-horizontal.form-login.row {
      gap: 1rem;

      .form-group {
        margin: 0;

        .form-control.selectbox {
          float: left;
          display: inline-block;
          height: auto;
        }
      }
    }
    .email-configration {
      display: inline-grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 0.5px;
      border: 0;
      background: $primary;
      border-radius: 100px;
      padding: 0.24rem;
      .nav-item {
        margin: 0;
        width: auto;
        .nav-link {
          border: 0;
          color: $white;
          font-size: 0.8rem;
          font-weight: 400;
          letter-spacing: 0.3px;
          border-radius: 100px;
          padding: 0.5rem;
          white-space: pre;
          text-align: center;
          width: 100%;
          transition: all 0.3s ease;
          &:is(:hover, :focus) {
            cursor: pointer;
            color: $success;
            background: rgba($success, 0.1);
          }
          &.active {
            background: $white;
            color: $primary;
          }
        }
      }
    }
    .column-configuration {
      .checkbox-column {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(9.2rem, 1fr));
        grid-gap: 0.8rem;
        width: 100%;
        margin-bottom: 1rem;
        .form-group {
          margin: 0;
          position: relative;
          input {
            width: 1rem;
            height: 1rem;
            flex: none;
            top: 0.4rem;
            margin: 0;
            position: absolute;
            left: 0.3rem;
            box-shadow: none;
            &:checked ~ label {
              background: rgba($primary, 0.15);
              color: $primary;
            }
          }
          label {
            width: 100%;
            background: #f4f4f4;
            color: $dark;
            cursor: pointer;
            font-weight: normal;
            font-size: 0.95rem;
            border-radius: 0.3rem;
            padding: 0.1rem 0.2rem 0.1rem 1.5rem;
            &:is(:hover, :focus) {
              color: $primary;
              background: #dddddd;
            }
          }
        }
      }
    }

    .category-head {
      .form-group {
        margin-bottom: 0;
      }

      input {
        width: 100%;
      }

      a {
        text-decoration: none;
        transition: all 0.3s ease;
        color: $danger;
        display: inline-block;

        svg {
          height: 1.3rem;
        }

        &:hover,
        &:focus {
          color: $primary;
          transform: none !important;
        }
      }

      .invalid-feedback {
        display: block;
      }
    }

    .form-label {
      left: 1rem;
    }

    ul {
      padding: 0 0.6rem;
      margin: 0;
    }

    li {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 0.3rem;
      background-color: unset !important;
      padding: 0 !important;
      list-style: none;
      margin-bottom: 0.5rem;

      &:last-of-type {
        margin-bottom: 0;
      }

      &:first-child {
        &.hidden {
          gap: 0;
        }
      }

      &.hidden {
        gap: 0.3rem;

        .sub-task-btn {
          position: relative;
          right: -3rem;
          opacity: 0;
          z-index: 0;
          width: 0;
        }
      }

      &.active {
        .sub-task-btn {
          position: relative;
          right: auto;
          display: flex;
          z-index: 0;
          opacity: 1;
        }
      }

      .form-label {
        left: 0.7rem;
      }

      &.show {
        >div {
          position: relative;

          .form-control {
            padding-left: 3rem;
          }

          >div {
            padding: 0.3rem 0.5rem;
            border-radius: 5px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 2%;
          }

          .content_icon {
            background: rgba(0, 167, 181, 0.2392156863);
            color: #00a7b5;
          }

          .report_icon {
            background: rgba(194, 66, 0, 0.1607843137);
            color: #c24200;
          }
        }
      }
    }

    .btn-box {
      display: block;
      text-align: center;

      &.assign_btn {
        button {
          gap: 0.3rem;

          &.btn-primary {
            width: 100% !important;
          }
        }
      }

      button {
        width: auto !important;
        display: inline-flex;
        align-items: center;
        line-height: normal;

        svg {
          width: 0.9rem;
          height: 0.9rem;
        }
      }
    }
  }
  .form-switch {
    display: flex;
    align-items: center;

    input {
      width: 1.7rem !important;
      height: 1rem;
      border-radius: 20px !important;
    }
  }
}

.swal-footer {
  text-align: center;
}


.error-msg-box {
  padding: 0.2rem 0.9rem;
}

.form-control {
  &.disable {
    background-color: #dedede;
    pointer-events: none;
    box-shadow: none;
    border-color: #dedede;
  }
}

.update_date {
  align-items: center;
  display: inline-flex;
  gap: 0.5rem;
  margin-right: 0.3rem;

  svg {
    flex: none;
    width: 1rem;
  }

  span {
    color: #000000ab;
  }
}

.approver_names {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 3.4rem;
}

.table-pagination {
  .pagination {
    gap: 0.7rem;

    .page-item {
      &.active {
        .page-link {
          background-color: $primary;
          color: $white;

          &:is(:hover, :focus) {
            background: $primary;
            color: $white;
          }
        }
      }

      &.disabled {
        .page-link {
          background-color: $gray-200;
          pointer-events: none;
          cursor: not-allowed;
        }
      }

      .page-link {
        border-radius: 30px;
        background-color: $white;
        color: $dark;

        &:is(:hover, :focus) {
          background: $primary;
          color: $white;
        }
      }
    }
  }
}

.filter_section.store-filter {
  background: #F4F5F6;
  .hra-create {
      .form-control {
        background: $white;
        -webkit-box-shadow: 0 0 0px 1000px $white inset !important;
        border-color: #ccc;
        &:focus ~ .form-label {
          background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%,rgba(239, 242, 246, 1) 100%);
        }
        &.selectbox {
          ~ .form-label {
            background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%,rgba(239, 242, 246, 1) 100%);
          }
        }
      }
  }
}
.hra-create {
  .separate-head {
    margin: 0rem 0 1rem;
  }

  .card-body {
    padding: 1.5rem 1.3rem;
  }

  .form-control:placeholder-shown~.form-label {
    left: 0.7rem;
  }
}

// hra-view-detail
.hra-view-detail {
  position: relative;
  .section-head {
    .user-box {
      .first_name_td {
        .short-name {
          &:last-of-type {
            background: $info;
          }
        }
      }
    }
  }

  .hra-view-detail-inner-box {
    .hra-form-box {
      background: #d4d4d452;
      border-radius: 5px;
      padding: 0.5rem 0.7rem;
      margin: 0px -0.2rem 0.6rem;
      min-height: calc(100% - 0.6rem);
      &.mailing-zip-box {
        span {
          span {
            &:last-child {
              display: none;
            }
          }
        }
      }
      .form-control {
        &::placeholder {
          opacity: 0.20;
          color: $primary !important;
        }        
      }

      label {
        display: block;
        color: $primary;
        font-size: 0.9rem;
        margin-bottom: 0;
        line-height: normal;
        font-weight: 600;
        text-transform: capitalize;
        hyphens: auto;
      }

      span {
        width: 100%;
        margin: 0;
        white-space: pre;
      }

      ul {
        display: flex;
        justify-content: start;
        align-items: center;
        flex-wrap: wrap;
        list-style: none;
        gap: 0.5rem;
        margin-top: 0.2rem;

        li {
          background-color: #d9d9d9;
          border-radius: 30px;
          padding: 0.2rem 0.5rem;
          color: #000;
          font-size: 0.7rem;
        }

        &.assigned_badge {
          padding: 0;
          margin-bottom: 0.3rem;

          li {
            background-color: transparent;
            padding: 0;
            font-size: 0.9rem;
          }
        }
      }

      &.d-flex {
        gap: 11rem;
      }
    }

    .assigned_promotion {
      .hra-form-box {
        span {
          &::after {
            content: ",";
            display: inline-block;
            padding: 0 0.2rem;
          }

          &:last-of-type {
            &::after {
              display: none;
            }
          }
        }

        &.mailing-zip-box {
          span {
            &::after {
              display: none;
            }
          }
        }
      }
    }

    .keyword-view-box {
      min-height: 3.6rem;
    }

    h5 {
      margin: 0;
      margin-bottom: 0.7rem;
      font-size: 1.3rem;
      color: $primary;
      line-height: 1.4;
    }

    p {
      margin-bottom: 0.9rem;
      line-height: normal;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    .separate-head {
      margin: 0 0 0.5rem;
      font-size: 1.2rem;
      font-weight: 500;
    }

  }

  .not_assign_img {
    img {
      opacity: 0.4;
      width: 70%;
    }
  }

  ul.comment-box {
    padding: 0;
    margin: 0;

    li {
      list-style: none;
      display: flex;
      justify-content: space-between;
      align-items: start;
      gap: 0.5rem;
      padding: 0.6rem 0.5rem;
      background-color: #fff;
      border-radius: 5px;

      &:first-of-type {
        padding-top: 0;
      }

      // &:last-of-type{
      //    border-bottom: 0;
      //    padding-bottom: 0;
      // }
      &:nth-child(even) {
        background-color: #f7f1ff;
      }

      .con-box {
        width: 100%;

        >div {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        h6 {
          margin: 0;
          margin-bottom: 0.3rem;
          color: $dark;
        }

        span {
          font-size: 0.8rem;
          color: #929292;
          display: block;
          white-space: nowrap;
        }

        p {
          margin-bottom: 0;
        }
      }
    }
  }

  .btn-box {
    display: flex;
    justify-content: space-between;
    align-items: center;

    >div {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      button {
        display: flex;
        gap: 0.3rem;
        justify-content: center;
        align-items: center;
        padding: 0.2rem 0.5rem;
        border-radius: 30px;
      }

      .btn-success,
      .btn-danger {
        svg {
          width: 1.2rem;
          height: 1.2rem;
          flex: none;
        }
      }
    }
  }
  &.vendor_view_details{
    .hra-form-box{
      &.logo_box{
        padding: 0;
        background: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
          width: 10vw;
          height: 8vw;
          max-width: 100%;
          object-fit: contain;
        }
      }
    }
    .hra-view-detail-inner-box{
      .vendor_details_view_row{
        > img{
          padding: 0;
        }
        .jodit-container{
          .jodit-wysiwyg{
            color: #fff;
          }
        }
        .text_editer_view{
          background: #000;
          padding: 2rem;
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          > div{
            width: 60%;
            p{
              // background: transparent !important;
                  color: #fff;
              a{
                // color: #7bb1ff !important;
                transition: all 0.3s ease-in-out;
                &:hover{
                  color: #4782d4 !important;
                }
              }
              span{
                // background: transparent !important;
                // color: #fff !important;
              }
            }
          }
        }

      }
    }
  }
}

.form-switch {
  .form-check-input {
    &:hover {
      cursor: pointer;
    }
  }
}

.disabled-btn {
  opacity: 0.5;
  cursor: no-drop;
  background-color: #cfcfcf !important;
  color: #6e6e6e !important;

  &:hover,
  &:focus {
    background-color: #cfcfcf !important;
    color: #6e6e6e !important;
  }

  /* add other styles to visually disable the button */
}


/*--------Datepicker----------*/
.react-datepicker-popper {
  z-index: 99 !important;
}

.react-datepicker__day--today {
  border-radius: 0.3rem;
  background-color: #bad9f1;
  color: rgb(0, 0, 0);

  &:hover {
    background-color: #1d5d90;
  }
}

.react-datepicker-wrapper {
  width: 100%;

  .react-datepicker__input-container {
    &::after {
      content: '';
      background: url(../assets/images/calendar.svg) no-repeat center / 100% 100%;
      position: absolute;
      right: 0.5rem;
      top: 50%;
      width: 1.5rem;
      height: 1.5rem;
      transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
    }

    .form-control {
      &::placeholder {
        display: block;
        color: rgba(92, 92, 92, 0.645) !important;
      }
    }
  }
}

.react-datepicker-popper[data-placement^=bottom] {
  .react-datepicker__triangle {
    &::before {
      left: -20px !important;
    }

    &::after {
      left: -20px !important;
    }
  }
}

/*--------End-Datepicker----------*/

input[type='number'] {

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
}

/*============ popup modal=======*/

.swal-button {
  border-radius: 30px;
  outline: none;

  &:hover,
  &:focus {
    outline: none;
    box-shadow: none;
  }

  &.swal-button--confirm {
    background: $primary;

    &:hover,
    &:focus {
      background-color: #2c1947;
    }
  }
}

.no-data-found {
  height: calc(100vh - 261px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    color: rgba(0 0 0 / 50%);
    margin-bottom: 0.7rem;
  }

  p {
    color: rgb(0 0 0 / 40%);
    font-size: 1.4rem;
    margin: 0;
  }

  &.report-data {
    height: calc(100vh - 309px);
  }

  &.modal-data {
    svg {
      width: 4.5rem;
      height: 4.5rem;
    }

    h4 {
      color: rgba(0 0 0 / 50%);
    }
  }
}

.no-record-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border-radius: 0 0 10px 10px;
  border: 1px solid rgb(79 45 127 / 7%);

  h4 {
    color: rgba(0 0 0 / 50%);
  }
}

/*========================================= page not found 404 =====================*/

.error_wrapper {
  background: $primary;
  padding-top: 6rem;
  margin-top: -6rem;

  .error_main {
    padding: 20px 0;

    .errorcontent {
      padding: 0 25px;
      z-index: 2;

      h1 {
        color: #fff;
        margin: 36px 0;
        text-shadow: 0px 1px 6px #00000033;
        text-transform: uppercase;
        font-size: 4rem;
        letter-spacing: 2px;
      }

      p {
        color: #a2a2fe;
        text-transform: uppercase;
      }

      .btn {
        background: #ea7c1e;
        border: 2px solid transparent;
        border-radius: 40px;
        color: #fff;
        margin: 30px 0;
        padding: 18px 48px 16px;
        text-transform: uppercase;

        &:hover,
        &:focus,
        &:active {
          background: #0079ad;
          border: 2px solid #fff;
          color: #fff;
        }
      }
    }

    .laptop {
      margin-top: 40px;
      z-index: 1;
      position: relative;

      img {
        position: absolute;
        right: 0;
      }

      .glitch {
        color: #fff;
        font-size: 120px;
        font-weight: 600;
        // left: 300px;
        right: 27%;
        position: absolute;
        top: 110px;
        z-index: 3;

        &::after {
          animation: noise-anim 2s infinite linear alternate-reverse;
          content: '404';
          left: 2px;
          overflow: hidden;
          position: absolute;
          text-shadow: -2px 0 #474747;
        }

        &::before {
          animation: noise-anim-2 3s infinite linear alternate-reverse;
          content: '404';
          left: -1px;
          overflow: hidden;
          position: absolute;
          text-shadow: 2px 0 #636363;
        }
      }
    }
  }
}

.react-tooltip {
  z-index: 100;

  .react-tooltip {
    z-index: 100;
  }
}

.cursor-pe {
  cursor: pointer !important;
}

.jodit-react-container {
  .jodit-container {
    .jodit-add-new-line.jodit-add-new-line_after {
      display: none !important;
    }
  }
}


// dashboard chart

@keyframes noise-anim {
  0% {
    clip: rect(53px, 999px, 113px, 0);
  }

  5% {
    clip: rect(28px, 999px, 114px, 0);
  }

  10% {
    clip: rect(55px, 999px, 37px, 0);
  }

  15% {
    clip: rect(77px, 999px, 80px, 0);
  }

  20% {
    clip: rect(79px, 999px, 120px, 0);
  }

  25% {
    clip: rect(44px, 999px, 24px, 0);
  }

  30% {
    clip: rect(43px, 999px, 18px, 0);
  }

  35% {
    clip: rect(48px, 999px, 32px, 0);
  }

  40% {
    clip: rect(22px, 999px, 82px, 0);
  }

  45% {
    clip: rect(100px, 999px, 120px, 0);
  }

  50% {
    clip: rect(75px, 999px, 115px, 0);
  }

  55% {
    clip: rect(24px, 999px, 50px, 0);
  }

  60% {
    clip: rect(75px, 999px, 55px, 0);
  }

  65% {
    clip: rect(50px, 999px, 105px, 0);
  }

  70% {
    clip: rect(7px, 999px, 9px, 0);
  }

  75% {
    clip: rect(63px, 999px, 64px, 0);
  }

  80% {
    clip: rect(50px, 999px, 106px, 0);
  }

  85% {
    clip: rect(60px, 999px, 60px, 0);
  }

  90% {
    clip: rect(94px, 999px, 40px, 0);
  }

  95% {
    clip: rect(82px, 999px, 120px, 0);
  }

  100% {
    clip: rect(30px, 999px, 119px, 0);
  }
}

@keyframes noise-anim-2 {
  0% {
    clip: rect(24px, 0, 107px, 999px);
  }

  5% {
    clip: rect(39px, 0, 120px, 999px);
  }

  10% {
    clip: rect(19px, 0, 43px, 999px);
  }

  15% {
    clip: rect(43px, 0, 100px, 999px);
  }

  20% {
    clip: rect(75px, 0, 61px, 999px);
  }

  25% {
    clip: rect(86px, 0, 56px, 999px);
  }

  30% {
    clip: rect(80px, 0, 108px, 999px);
  }

  35% {
    clip: rect(5px, 0, 80px, 999px);
  }

  40% {
    clip: rect(31px, 0, 43px, 999px);
  }

  45% {
    clip: rect(23px, 0, 54px, 999px);
  }

  50% {
    clip: rect(89px, 0, 113px, 999px);
  }

  55% {
    clip: rect(48px, 0, 63px, 999px);
  }

  60% {
    clip: rect(89px, 0, 120px, 999px);
  }

  65% {
    clip: rect(51px, 0, 62px, 999px);
  }

  70% {
    clip: rect(73px, 0, 120px, 999px);
  }

  75% {
    clip: rect(37px, 0, 100px, 999px);
  }

  80% {
    clip: rect(34px, 0, 110px, 999px);
  }

  85% {
    clip: rect(41px, 0, 102px, 999px);
  }

  90% {
    clip: rect(68px, 0, 115px, 999px);
  }

  95% {
    clip: rect(41px, 0, 41px, 999px);
  }

  100% {
    clip: rect(37px, 0, 101px, 999px);
  }
}

/* --- FOOTER styles --- */
.footer_row {
  margin: 170px 0 70px;
  position: relative;
  z-index: 1;
}

/* search styles */
.form-group {
  margin-bottom: 20px;
}

/* --- ADAPTIVE styles ---*/
@media screen and (max-width:991px) {
  .rebate-wrapper {
    .rebate_details {
      .promotion_tab {
        .nav-item {
          .nav-link {
            min-width: auto;

            p {
              font-size: 0;
              margin: 0;
              display: inline-block;
              line-height: normal;
            }

            p::first-letter,
            p:first-letter {
              font-size: 1rem;
            }
          }
        }
      }
    }
  }

  .rebate_list {
    >div {
      margin: 0;
      width: calc(33.33% - 0.5rem);
    }
  }
}

@media (min-width: 0px) and (max-width: 767px) {
  .error_main {
    text-align: center;
  }

  .rebate-wrapper {
    .section-head {
      flex-direction: column;
      align-items: baseline;
      gap: 1rem;

      >div {
        width: 100%;

        .form-group {
          width: 100%;
        }
      }
    }
  }

  .laptop img {
    background: -moz-linear-gradient(#0079ad 77%, #ffffff 23%);
    background: -webkit-linear-gradient(#0079ad 77%, #ffffff 23%);
    background: -webkit-gradient(linear,
        0 0,
        0 100%,
        color-stop(0.77, #0079ad),
        color-stop(0.23, #ffffff));
    background: -o-linear-gradient(#0079ad 77%, #ffffff 23%);
    background: linear-gradient(#0079ad 77%, #ffffff 23%);
    max-width: 120%;
    padding-right: 4%;
  }

  .sub-footer {
    .row {
      > div.col-sm-12, > div.sub-footer-email  {
        display: flex;
        justify-content: center;
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .wrapper {
    height: 870px;
  }

  .error_main {
    text-align: center;
  }

  .glitch {
    left: 270px;
    top: 110px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .laptop {
    margin-top: 110px;
  }

  .laptop img {
    max-width: 125%;
  }

  .glitch {
    left: 220px;
    top: 90px;
  }
}


.react-datepicker__input-container {
  .react-datepicker__close-icon {
    right: 2rem !important;
  }
}

/*========================================================== HRA ======================================================*/


.card {
  .card-body {
    .pdf-upload {
      border: 1px solid #ddd !important;

    }
  }
}


.promotion_img_upload {
  margin-bottom: 0;

  .card-body {
    overflow: auto;
    max-height: calc(100vh - 13rem);

    .uploads_btn {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 0.6rem;
      margin: 0.5rem 0;

      span {
        color: #c8c0c0;
        font-size: 0.8rem;
      }

      .btn-banner {
        &.disabled {
          background: #9b9b9b !important;
          pointer-events: none;
        }

        padding: 0.1rem 0.5rem !important;
        background: $primary !important;
        border: none;

        &:hover {
          border: none !important;
          transform: none;
          background: darken($primary, 60%) !important;
        }
      }

      .btn-link {
        padding: 0;
      }
    }
  }
}

.add-customfiled {
  >button {
    padding: 0;
    margin: 0.2rem 0 0;
    color: $primary;
    gap: 0.3rem;
    display: flex;
    align-items: center;
    text-decoration: none;

    svg {
      width: 0.6rem;
    }

    &:hover,
    &:focus {
      color: darken($primary, 50%);
      box-shadow: none;
      outline: none;
    }
  }
}

.add_custom_field {
  background: #dfe5ee;
  padding: 0.6rem;
  border-radius: 8px;
  transition: all 0.3s ease-in-out;

  button.btn {
    &:is(:hover, :focus) {
      color: $dark !important;
    }
  }

  .form-group {
    .form-control {
      &::placeholder {
        color: #4646469a !important;
      }
    }
  }
}

.Promotion_section {
  display: grid;
  // grid-template-columns: repeat(auto-fit, minmax(250px, calc(20% - 1.05rem)));
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 1.1rem;
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    a {
      text-decoration: none;
      display: inline-block;
      height: 100%;
    }
  }
}

.promotion_card {
  border-radius: 10px !important;
  height: 100% !important;
  display: block;
  margin: 0;
  box-shadow: none;
  position: relative;

  &:hover {
    // transform: scale(1.02);
    box-shadow: 0.1rem 0.1rem 0.5rem #00000013;

    .card-body {
      img {
        transform: rotate(3deg) scale(1.1);
      }
    }
  }

  &.disabled {
    background-color: lighten($danger, 50%);

    .card-body {
      .card_content {
        &:hover {
          background-color: lighten($danger, 50%) !important;
        }
      }
    }
  }

  &.selected {
    background: $primary;
    transition: none;

    .card-body {
      .card_content {
        .card_content_text {
          h4 {
            color: $white !important;
          }

          p {
            color: rgba($white, 0.7) !important;
          }
        }

        .card_date {
          background: #ffffff21;
          color: $white !important;
        }
      }

      .creat_btn {
        background-color: #00a7b5;
        color: $white !important;
        font-weight: 500;
      }
    }
  }

  .card-body {
    padding: 0;
    display: inline-flex;
    flex-direction: column;

    p {
      margin-bottom: 0;
    }

    .card_img {
      overflow: hidden;
      border-radius: 10px 10px 0 0;
      position: relative;
      flex: none;

      &:is(:hover, :focus) {
        .assigned {
          background: $white;
          color: #ff2c2c;
          transition: all 0.3s ease;
        }
        

        .view_more {
          transform: translateX(0);
          transition: all 0.3s ease;
        }
      }

      .dropdown {
        z-index: 9;

        .dropdown-menu {
          .dropdown-item {

            &:hover,
            &:focus {
              background: #002e7038;
            }
          }
        }
      }

      .promotion_img {
        position: relative;
        width: 100%;
        padding-bottom: 56.25%;

        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }

      .promotion_svg {
        padding: 11.8%;
        background-color: #f2f2f2;
        text-align: center;

        svg {
          width: 5rem;
          height: 5rem;
        }
      }

      .assigned {
        position: absolute !important;
        z-index: 1;
        right: 0.5rem;
        bottom: 0.5rem;
        background: #ff2c2c;
        color: #fff;
        border: 1px solid #fff;
        border-radius: 40px;
        padding: 0.1rem 0.4rem;
        display: flex;
        align-items: center;
        font-size: 0.9rem;
        gap: 0.2rem;
        transition: all 0.3s ease;

        svg {
          width: 1.1rem;
          height: 1.1rem;
        }
      }

      .view_more {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba($primary, 0.8);
        display: flex;
        align-items: center;
        justify-content: center;
        transform: translateX(-100%);
        transition: all 0.3s ease;
        z-index: 1;

        svg {
          color: $success;
          width: 1.5rem;
          height: 1.5rem;
        }
      }
    }

    .tooltip {
      .tooltip-inner {
        .county_tooltip {
          margin: 0;
          padding: 0 !important;
          list-style: none !important;
        }
      }
    }

    img {
      max-width: 100%;
      object-fit: fill;
      transition: all 0.3s ease-in-out;
    }

    .opt_in_date {
      position: absolute;
      bottom: 0;
      width: 100%;
      background: #00000040;
      backdrop-filter: blur(10px);
      z-index: 9;

      p {
        color: #ffffff;
        text-align: center;
        font-weight: 500;
        font-size: 0.9rem;

        span {
          font-weight: 300;
        }
      }
    }

    .card_content {
      padding: 0;
      position: relative;
      display: inline-flex;
      flex-direction: column;
      justify-content: space-between;
      cursor: pointer;
      border-radius: 0 0 10px 10px;
      // height: calc(100% - 10rem);
      height: 100%;

      &:hover,
      &:focus {
        background: rgba($primary, 0.15);

        .opt_in_count {
          background-color: #9BD732 !important;
        }
      }

      .card_content_text {
        transition: all 0.2s ease-in;
        padding: 0.8rem 0.8rem 0 0.8rem;
        display: flex;
        flex-direction: column;

        .action_dot_btn {
          position: absolute;
          right: -0.6rem;
          top: -0.1rem;
          width: 1.6rem;
          height: 1.6rem;
          background: rgb(255 255 255 / 45%);
          border: none;
          padding: 0;
          border-radius: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $info;
          z-index: 1;

          &:hover,
          &:focus {
            border: none !important;
            transform: none !important;
            color: #002e70 !important;
            background: #fff !important;
          }

          &::after {
            display: none;
          }

          svg {
            width: 1.1rem;
            height: 1.1rem;
            display: block;
          }
        }

        h4 {
          margin-bottom: 0 !important;
          overflow: hidden;
          width: calc(100% - 2.8rem);
          float: left;
          text-overflow: ellipsis;
          white-space: initial;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          color: #002E70;
          margin-bottom: 0.3rem !important;
          font-size: 1rem;
          font-weight: 600;
          line-height: 1.1;
          padding-right: 0.4rem;
          max-width: 15.5rem;
          transition: all 0.3s ease-in;
        }

        >p {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: initial;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          margin-bottom: 0.5rem;
          line-height: normal;
          font-size: 0.9rem;
          max-width: 16rem;
          color: $dark;
        }
      }

      div {
        .card_date {
          background: #7e7d7d21;
          padding: 0.1rem 0.3rem;
          border-radius: 5px;
          font-size: 0.9rem;
          display: flex;
          align-items: center;
          gap: 0.3rem;
          color: rgb(54 54 54 / 90%);

          p {
            font-size: 0.75rem;
          }

          svg {
            width: 1.2rem;
            height: 1rem;
          }
        }

        .opt_in_assign_btn {
          .btn {
            padding: 0;
            color: #8bb01f;
            transition: all 0.3s ease;

            svg {
              width: 2rem;
              height: 2rem;
            }

            &:active {
              border: none;
            }

            &:hover {
              color: $primary;
              transform: scale(1.03);
            }
          }

          .opt_in_count {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 1.5rem;
            height: 1.5rem;
            font-size: 0.8rem;
            font-weight: 600;
            color: #ffffff;
            border-radius: 50%;
            background-color: #00a7b5;
            box-shadow: 0 0 0.5rem #00000057;
            position: relative;
            transition: all 0.2s ease-in;

            &:hover,
            &:focus {
              transform: scale(1.05);
              background-color: #82b628 !important;
            }
          }
        }
      }

      // .btn-link{
      //   display: block;
      //   margin-left: auto;
      //   color: #75ca06;
      //   padding: 0;
      //   transition: all 0.3s ease;
      //   &:hover{
      //     color: #487210;
      //   }
      // }
    }

    .creat_btn {
      font-size: 0.8rem;
      padding: 0.4rem 0.5rem;
      position: absolute;
      right: 0.5rem;
      bottom: 0.5rem;
      line-height: normal;

      &:is(:hover, :focus) {
        background-color: #82b628;
      }

      &.assigned {
        background-color: #e34f4f;

        &:disabled {
          background-color: #ff8282;
        }

        &:is(:hover, :focus) {
          background-color: $success;
        }
      }
    }
  }
}

.newsletter_section {
  .card-body {
    .card_img {
      .promotion_svg {
        height: 20vh;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .promotion_img {
        height: 20vh;
        padding: 0;
      }
    }
  }
}


.no_data_img {
  height: calc(100vh - 19rem);
}

.pdf-view-box {
  a {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    background: #00bcd429;
    padding: 0.2rem 0.4rem;
    border-radius: 5px;
    color: #289dac;
    transition: all 0.3s ease;

    &:hover,
    &:focus {
      background: #00bbd4;
      color: #ffffff;
    }

    span {
      font-size: 1rem;
      max-width: 23rem !important;
      width: auto;
      margin: 0;
      text-overflow: ellipsis;
      white-space: pre;
      display: block;
      overflow: hidden;
    }

    svg {
      width: 1.3rem;
      height: 1.5rem;
    }
  }
}

.product-image-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(245px, 1fr));
  grid-gap: 0.8rem;
  padding: 0;
  margin: 0;

  li {
    list-style: none;

    img {
      width: 100%;
      border-radius: 0.3rem;
      // border: 4px solid transparent;
    }

    &.product-main {
      position: relative;

      img {
        border: 4px solid #59b500;
      }

      &::before {
        content: "\2713";
        width: 1.5rem;
        height: 1.5rem;
        position: absolute;
        right: 4%;
        top: 7%;
        z-index: 9;
        color: #fff;
        background-color: #59b500;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

.modal {
  .modal-body {
    .form-group {
      .form-select {
        -webkit-box-shadow: none !important;
      }
    }
    .assign_modal_title {
      font-size: 1.2rem;
      color: $primary;
    }

    .assign_modal_img {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      grid-gap: 0.2rem;
      max-height: 20rem;
      overflow: auto;

      li {
        display: flex;
        align-items: center;
        background-color: #e8e8e8 !important;
        padding: 0.5rem !important;
        border-radius: 5px;
        justify-content: start !important;

        .assign_modal_content {
          width: calc(100% - 7rem);
        }

        img {
          max-width: 100%;
          width: 7rem;
        }

        .docfile {
          svg {
            width: 5.5rem;
          }
        }

        span {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          word-wrap: break-word;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          line-height: 1;

          &.fw-bold {
            -webkit-line-clamp: 1;
            line-height: normal;
          }
        }
      }
    }
  }
}

.create-date-badge {
  background: none;
  border: none;
  color: #8f8c8c !important;
  font-size: 0.9rem !important;
  font-weight: 400 !important;
  margin-right: 0.5rem;
}

.product-image-box {
  .card-body {
    padding-right: 0.5rem;
  }

  .product-image-list {
    // max-height: calc(100vh - 28.7rem);
    overflow: auto;
    padding-right: 0.5rem;
  }
}

.view_member_head {
  display: flex;
  gap: 1rem;
  margin-bottom: 0.5rem;
  align-items: center;

  .view_member_satus {
    color: #fff;
    padding: 0rem 0.7rem;
    border-radius: 50px;

    &.active {
      background: #7ad720;
    }

    &.inactive {
      background: #e34e4e;
    }
  }

  .view_member_hra {
    background: #e5f3d5;
    padding: 0rem 0.5rem;
    border-radius: 5px;
    border: 1px dashed;
    font-weight: 500;
  }
}

.view_member_card {
  .separate-head {
    position: relative;
    background: linear-gradient(90deg, #003b702e 0%, rgba(255, 255, 255, 0) 100%);
    padding: 0.4rem 0 0.4rem 0.7rem;
    width: 100%;
    color: $primary;

    &::before {
      content: "";
      background: $primary;
      width: 5px;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }
  }
  &.hra-view-detail {
    .ach-modal_content {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
      grid-gap: 8px;
      .ach_details_body {
        padding: 1rem;
        h6 {
          position: relative;
          padding: 0.4rem 0 0.4rem 0.7rem;
          width: 100%;
          background: linear-gradient(90deg, #003b702e 0%, rgba(255, 255, 255, 0) 100%);
          color: $primary;         
          &::after {
            content: "";
            background: $primary;
            width: 5px;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
          }
          &.previous_record {
            background: linear-gradient(90deg, rgb(255 0 0 / 15%) 0%, rgba(255, 255, 255, 0) 100%);
            color: $black;
            &::after {
              background: #ff0000;
            }
          }
        }
        .ach_check_img {
          h5 {
            font-size: 1.2rem;
          }
        }
        .checkImg {
          max-width: 100%;
          max-height: 20rem;
          img {
            max-width: 100%;
            max-height: 100%;
          }
        }
        ul {
          display: grid;          
          grid-template-columns: repeat(auto-fit, minmax(19rem, 1fr));
          // grid-template-columns: minmax(150px, max-content) repeat(auto-fill, 180px) 15%;
          list-style: none;
          padding: 0;
          margin: 0 0 1.5rem;
          grid-gap: 0.5rem;
          li {
            background-color: rgba(212, 212, 212, 0.3215686275);
            padding: 0.5rem;
            border-radius: 5px;
            display: flex;
            flex-flow: column;
            &:last-of-type {
              grid-column: 1 / -1;
            }
            &.ach_check_img {
              grid-column: 2 / -1;
            }
            label {
              display: block;
              color: $primary;
              font-size: 0.9rem;
              margin-bottom: 0;
              line-height: normal;
              font-weight: 600;
              text-transform: capitalize;
              height: auto;
            }
          }
        }
        &:nth-child(2) {
          h6{
            background: linear-gradient(90deg, rgb(0 128 1 / 15%) 0%, rgba(255, 255, 255, 0) 100%);
            &::after {
              background: #008001;
            }
          }
        }
      }
    
    }
    .ach_action {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
      &.view_ach {
        position: absolute;
        right: 0;
        top: -3rem;
      }
      button {
        min-width: 7rem;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
      }
    }
    .hra-form-box.signImg {
      img {
        border: 1px solid #dee2e6;
        border-radius: 10px;
        padding: 0;
        max-width: 100%;
      }
    }
  }
}

.swal2-popup {
  &.swal2-toast {
    .swal2-title {
      font-size: 1rem !important;
    }
  }
}

.sticky_lable {
  position: absolute;
  top: calc(0% - 0.4rem) !important;
  display: block;
  transition: 0.2s;
  color: #002E70 !important;
  line-height: 1;
}

.view_btn_icon {
  color: $success;
  &:is(:hover, :focus) {
    cursor: pointer;
    color: $primary;
  }
}
.agree_icon_btn{
  min-width: 1.6rem !important;
  cursor: pointer;
  &:is(:hover, :focus) {
    color: #04747d;
  }
}

.delete_btn_icon {
  color: $danger;

  &:is(:hover, :focus) {
    cursor: pointer;
    color: $primary;
  }
}

.hra_id {
  background: #002e7014;
  color: #002e70;
  padding: 0rem 0.3rem;
  border-radius: 3px;
  border: 1px dashed #002e70;
}

.promotion_tab {
  background: transparent;
  gap: 0.5rem;

  .nav-item {
    .nav-link {
      padding: 0.3rem 0.7rem;
      background: #0000000f;
      border: none;
      cursor: pointer;
      min-width: 6.5rem;
      text-align: center;
      box-shadow: inset 0px 0px 0px #00000000, inset 0px 0px 0px #e2222200,
        inset 0px -3px 4px #00000014, inset 0px 0px 0px #9a1bea00;

      svg {
        width: 1.8rem;
        height: 1.8rem;
        margin-right: 0.3rem;
      }

      &.active {
        background: #00a7b5;
        color: #fff;
        box-shadow: none;

        &:hover,
        &:focus {
          background: #00a7b5;
          color: #fff;
        }
      }

      &:hover,
      &:focus {
        background: #00a7b521;
      }
    }

    &:last-child {
      svg {
        padding: 0.3rem 0;
      }
    }
  }
}

.promotion_tab-content {
  padding: 1rem;
  border: 1px solid rgba($info, 0.2);
  border-radius: 0 10px 10px 10px;
  background: #f0f0f0;
  border: 1px solid rgba($success, 0.2);
}

.chart_btns {
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;

  .rebate-filter {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    width: 50%;
    .form-group {
      flex: 1;
      .form-control {
        height: 2.5rem !important;

        [class*=control] {
          min-height: 2.5rem;
          max-height: 2.5rem;
        }
      }

      .form-label {
        left: 0.5rem;
        background: linear-gradient(180deg, hsla(0, 0%, 94%, 1) 50%, hsla(0, 0%, 100%, 1) 60%) !important;
      }
    }
  }

  .form-group {
    flex: 0.2;
  }
}

.rebate_chart {
  .rebate_data {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(4, 1fr);

    .rebate_list {
      >div {
        width: 100%;
      }

      #chart {
        >div {
          text-align: center;
          display: flex;
          justify-content: center;
        }
      }
    }

    >div {
      margin-bottom: 0 !important;
    }
  }
}

.rebate_search {
  width: 100%;
  min-width: 30rem;
  height: calc(100% - 0.55rem);

  input {
    width: 100%;
    height: 100%;
    border-radius: 0.375rem;
    border: 1px solid rgb(204 204 204);
  }
}

.rebate_bg {
  >div {
    background: #ffffff69;
    padding: 1rem;
    border-radius: 10px;

    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
}

.rebate_btn {
  background: #fff;
  padding: 0.3rem;
  margin-bottom: 0.3rem;
  height: fit-content;

  .btn {
    padding: 0.2rem 0.6rem;
    line-height: normal;
    border: 1px solid #00a7b5;
    background: transparent;
    color: #00a7b5;
    display: flex;
    align-items: center;
    gap: 0.2rem;

    &:is(:hover, :focus, .active) {
      transform: none;
      background: #00a7b5;
      border-color: #00a7b5;
      color: #fff;
    }

    &.active {
      background: #00a7b5;
      border-color: #00a7b5;
      color: #fff;
    }

    &:is(:hover) {
      background: #00a6b56c;
      border-color: #00a7b5;
    }

    svg {
      width: 1.3rem;
      height: auto;
    }
  }
}

.rebate_list {
  display: inline-flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 0;
  width: 100%;

  >div {
    margin: 0;
    width: calc(20% - 0.8rem);

    .card {
      margin: 0;

      .badge {
        line-height: normal;
      }
    }
  }
}

.status_switch {
  .react-switch-bg {
    background: #9BD732 !important;
    width: 87px !important;

    >div {
      width: auto !important;
      right: 6px !important;
      top: -1px !important;
    }
  }

  .react-switch-handle {
    transform: translateX(66px) !important;
  }

  &.false {
    .react-switch-bg {
      background: #f8b425 !important;

      >div {
        width: auto !important;
        right: 6px !important;
        top: -1px !important;
      }
    }

    .react-switch-handle {
      transform: translateX(1px) !important;
    }
  }

  .pending_switch {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 0.8rem;
    color: #fff;
  }

  .Done_switch {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 0.75rem;
    color: #fff;
  }
}

.rebate-wrapper {
  .rebate_details {
    .promotion_tab {
      .nav-item {
        .nav-link {
          p {
            margin: 0;
            display: inline-block;
            line-height: normal;
          }
        }
      }
    }
  }
}

.inquiry_view_modal {
  .modal-body {
    padding-top: 0;
  }
}

.inqury_view {
  .inqury_view_head {
    display: flex;
    justify-content: end;
    gap: 0.9rem;
    margin: 0.4rem 0;

    .created_date {
      font-size: 0.9rem;
      font-weight: 500;
      color: #888686;
    }
  }

  .inqury_view_field {
    background: #eeeeee;
    padding: 0.3rem 0.7rem;
    border-radius: 5px;
    margin-bottom: 0.7rem;

    p {
      margin-bottom: 0;
      line-height: normal;
      font-weight: 500;
      font-size: 0.85rem;
      color: #000;
    }
  }
}

.swal2-popup {
  .swal2-container {
    .swal2-title {
      font-weight: 500;
    }
  }

  .swal2-actions {
    .swal2-confirm {
      background-color: #163a70;
    }
  }
}

.reset_password {
  .loging-content {
    p {
      margin-bottom: 1rem;
    }
  }
}

.reset_email {
  margin: 0.5rem 0 1.2rem;
  padding: 0.5rem 0.8rem;
  background: #002e7024;
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #002e70;

  h6 {
    font-size: 1.1rem;
    margin: 0;
    max-width: 22rem;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}


.store_modal {
  .modal-body {
    padding: 0;

    .form-group {
      >div {
        display: inherit !important;

        >div {
          z-index: 9;
          padding: 0 !important;

          div {
            padding: 0.3rem 0.7rem;
          }
        }
      }

      .form-select {
        --bs-form-select-bg-img: none;
      }
    }
  }
}

.parents_store {
  background: #002e7024;
  padding: 0.7rem;

  h4 {
    color: #002e70;
    margin: 0 0 0.4rem;
    font-size: 1.2rem;
  }

  .store_find {
    ~div {
      padding: 0 !important;
    }
  }

  .form-group {
    margin-bottom: 0.8rem;
  }

  .form-control {
    height: 2.3rem !important;
  }

  .form-label {
    top: 0.5rem !important;
  }
}

.parents_store_details {
  display: flex;
  background: rgb(250 250 250 / 54%);
  padding: 0.3rem 0.6rem;
  margin-top: 0.5rem;
  border-radius: 5px;
  gap: 0.5rem;

  >div {
    display: flex;
    align-items: center;
    gap: 0.3rem;

    svg {
      width: 0.9rem;
      height: 0.9rem;
    }

    span {
      white-space: pre;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 0.8rem;
    }
  }

  .store_name {
    span {
      max-width: 20rem;
    }
  }

  .store_location {
    span {
      max-width: 15rem;
    }
  }
}

.sub_store {
  padding: 0.8rem !important;
  overflow: auto;
  max-height: 14rem;
  margin-bottom: 0.3rem;

  h4 {
    color: #002e70;
    margin: 0 0 0.4rem;
    font-size: 1.2rem;
  }

  >div {
    background: #f8f8f8;
    padding: 0.8rem;
    border-radius: 4px;
  }

  .sub_store_field {
    display: flex;
    width: 100%;
    gap: 0.5rem;
    align-items: flex-start;

    .form-group {
      // margin-bottom: 0.8rem;
      width: 100%;
    }

    // .form-control {
    //   height: 2.3rem !important;
    // }
    // .form-label {
    //   top: 0.5rem !important;
    // }  
    .btn {
      padding: 0;
      background: #ececec;
      width: 2.7rem;
      height: 2.4rem;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.3s ease;

      &:hover {
        background: #d6d6d6;
      }
    }
  }

  .parents_store_details {
    background: rgb(244 67 54 / 9%);
  }
}
.member_all_details_main {
  overflow-x: auto;
  width: auto;
}
.store_accordion {
  margin-top: 2rem;
  &.member_all_details {
    width: auto;
    min-width: 100%;
    max-width: none;
    display: inline-block;
    .vendor_member_row.member_row_head {
      display: inline-flex;
      flex-wrap: nowrap;   
      width: auto;   
      min-width: 100%;
      li {
        width: auto;
        min-width: 10rem;
        font-size: 0.9rem;
        &.member-hra_id {
          min-width: 6.5rem;
          max-width: 6.5rem;
        }
        &.member-name {
          min-width: 12rem;
          max-width: 12rem;
        }
        &.member-email {
          min-width: 15rem;
          max-width: 15rem;
        }
        &.member-phoneno {
          min-width: 12rem;
          max-width: 12rem;
        }
        &.member-representative {
          min-width: 14rem;
          max-width: 14rem;
        }
        &.member-relation {
          min-width: 7.3rem;
          max-width: 7.3rem;
          justify-content: space-between;
        }
        &.member-contact_name {
          min-width: 12rem;
          max-width: 12rem;
        }
        &.member-leads_requests {
          min-width: 12rem;
          max-width: 12rem;
        }
        &.member-mailing_zip {
          min-width: 11rem;
          max-width: 11rem;
        }
        &.member-store_name_hra {
          min-width: 15rem;
          max-width: 15rem;
        }
        &.member-date_enrolled {
          min-width: 11rem;
          max-width: 11rem;
        }
        &.member-store_name_regular {
          min-width: 18rem;
          max-width: 18rem;
        }
        &.member-state {
          width: auto;
          min-width: 8rem;
          max-width: 8rem;
        }
        &.member-rbna_distributor {
          min-width: 16rem;
          max-width: 16rem;
        }
        &.member-mailing_street_address {
          min-width: 20rem;
          max-width: 20rem;
        }
        &.member-id_number {
          min-width: 13rem;
          max-width: 13rem;
        }
        &.member-account_name {
          min-width: 15rem;
          max-width: 15rem;
        }
        &.member-city {
          min-width: 10.5rem;
          max-width: 10.5rem;
        }
        &.member-coke_bottler_region {
          min-width: 14rem;
          max-width: 14rem;
        }
        &.member-created_by {
          min-width: 15rem;
          max-width: 15rem;
        }
        &.member-ticket_owner {
          min-width: 12rem;
          max-width: 12rem;
        }
        &.member-business_phone {
          min-width: 12rem;
          max-width: 12rem;
        }
        &.member-ach_enrolled {
          min-width: 11rem;
          max-width: 11rem;
        }
        &.member-created_date {
          min-width: 11rem;
          max-width: 11rem;
        }
        &.member-secondary_email {
          min-width: 18rem;
          max-width: 18rem;
        }
        &.member-inactive_date {
          min-width: 11rem;
          max-width: 11rem;
        }
        &.member-mailing_state {
          min-width: 11rem;
          max-width: 11rem;
        }
        &.member-manager_territory {
          min-width: 12rem;
          max-width: 12rem;
        }
        &.member-master_dase {
          min-width: 10.5rem;
          max-width: 10.5rem;
        }
        &.member-store_created_date {
          min-width: 11rem;
          max-width: 11rem;
        }
        &.member-street_address {
          min-width: 15rem;
          max-width: 15rem;
        }
        span {
          white-space: pre;
        }
        .btn {
          border: none;
          min-width: 1.5rem;
          margin-left: 0.5rem;
        }
       
      }
    }
    .accordion-item {
      width: auto;
      .accordion-header {
        width: 100%;
        .accordion-button {
          padding: 0.1rem;
          ul {
            li {
              min-width: 10rem;
              font-size: 0.9rem;
              width: auto;
              &.store_hra_id {
                min-width: 6.5rem;
                max-width: 6.5rem;
              }
              &.member-name {
                min-width: 12.4rem;
                max-width: 12.4rem;
                svg {
                  width: 1rem;
                  height: 1rem;
                }
              }
              &.member-bbg_status {
                width: auto;
              }
              &.store-hra-mail {
                min-width: 15rem;
                max-width: 15rem;
                p {
                  width: 100%;
                  white-space: pre;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  svg {
                    width: 1rem;
                    height: 1rem;
                  }
                }
              }
              &.store-hra-contact {
                min-width: 12rem;
                max-width: 12rem;
                svg {
                  width: 1rem;
                  height: 1rem;
                }
              }
              &.representative_field {
                min-width: 12rem;
                max-width: 12rem;
                svg {
                  width: 1rem;
                  height: 1rem;
                }
              }
              &.relation_badge {
                min-width: 10rem;
                max-width: 10rem !important;
              }
              &.member-contact_name {
                min-width: 12rem;
                max-width: 12rem;
              }
              &.member-leads_requests {
                min-width: 12rem;
                max-width: 12rem;
              }
              &.member-email_login {
                min-width: 9.5rem;
                max-width: 9.5rem;
              }
              &.member-store_name_hra {
                min-width: 15.5rem;
                max-width: 15.5rem;
              }
              &.member-county {
                min-width: 10rem;
                max-width: 10rem;
                width: auto;
              }
              &.member-store_name_regular {
                min-width: 18rem;
                max-width: 18rem;
              }
              &.member-state {
                width: auto;
                min-width: 7.8rem;
                max-width: 7.8rem !important;
              }
              &.member-rbna_distributor {
                min-width: 16rem;
                max-width: 16rem;
              }
              &.member-phoneno {
                min-width: 12rem;
                max-width: 12rem;
              }
              &.member-mailing_street_address {
                min-width: 20rem;
                max-width: 20rem;
              }
              &.member-id_number {
                min-width: 13rem;
                max-width: 13rem;
              }
              &.member-email_login {
                min-width: 10rem;
                max-width: 10rem;
                width: auto;
              }
              &.member-mailing_zip {
                min-width: 10.8rem;
                max-width: 10.8rem;
              }
              &.member-account_name {
                min-width: 15rem;
                max-width: 15rem;
              }
              &.member-coke_bottler_region {
                min-width: 14rem;
                max-width: 14rem;
              }
              &.member-representative {
                min-width: 14rem;
                max-width: 14rem;
              }
              &.member-ticket_owner {
                min-width: 12rem;
                max-width: 12rem;
              }
              &.member-business_phone {
                min-width: 12rem;
                max-width: 12rem;
              }
              &.member-ach_enrolled {
                min-width: 11rem;
                max-width: 11rem;
              }
              &.member-date_enrolled {
                min-width: 11rem;
                max-width: 11rem;
                width: auto;
              }
              &.member-status {
                width: auto;
              }
              &.member-secondary_email {
                min-width: 18rem;
                max-width: 18rem;
              }
              &.member-created_date {
                min-width: 11rem;
                max-width: 11rem;
              }
              &.member-inactive_date {
                min-width: 11rem;
                max-width: 11rem;
              }
              &.member-mailing_state {
                min-width: 11rem;
                max-width: 11rem;
              }
              &.member-created_by {
                min-width: 15rem;
                max-width: 15rem;
              }
              &.member-manager_territory {
                min-width: 12rem;
                max-width: 12rem;
              }
              &.member-master_dase {
                width: auto;
                min-width: 10.5rem;
                max-width: 10.5rem;
              }
              &.member-postal_code {
                width: auto;
              }
              &.member-ein {
                width: auto;
              }
              &.member-inactive_reason {
                width: auto;
              }
              &.member-city {
                min-width: 10.5rem;
                max-width: 10.5rem;
              }
              &.member-relationship {
                width: auto;
                min-width: 10rem;
                max-width: 10rem;
              }
              &.member-territory {
                width: auto;
              }
              &.member-store_created_date {
                min-width: 11rem;
                max-width: 11rem;
              }
              &.member-street_address {
                min-width: 15rem;
                max-width: 15rem;
              }
              &.action_button {
                min-width: auto;
                justify-content: flex-end;
                flex: 1;
                .view_substore {
                  position: relative;
                  right: -0.3rem;
                  transform: translate(0, 0%);
                  margin-left: 0.2rem;
                }
              }
            }
          }
        }
      }
      .accordion-collapse {
        .accordion-body {
          padding: 0.4rem;
          ul {
            padding: 0.1rem;
            li {
              font-size: 0.9rem;
              border-right-color: rgba($primary, 0.3);
              width: auto;
              svg {
                width: 1rem;
                height: 1rem;
              }
              p {
                white-space: pre;
                overflow: hidden;
                text-overflow: ellipsis;
              }
              &.store_hra_id {
                min-width: 6rem;
                max-width: 6rem;
              }
              &.member-name {
                min-width: 12.4rem;
                max-width: 12.4rem;
                svg {
                  width: 1rem;
                  height: 1rem;
                }
              }
              &.member-bbg_status {
                width: auto;
                min-width: 10rem;
                max-width: 10rem;
              }
              &.store-hra-mail {
                min-width: 15rem;
                max-width: 15rem;
                p {
                  width: 100%;
                  white-space: pre;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  svg {
                    width: 1rem;
                    height: 1rem;
                  }
                }
              }
              &.store-hra-contact {
                min-width: 12rem;
                max-width: 12rem;
                svg {
                  width: 1rem;
                  height: 1rem;
                }
              }
              &.representative_field {
                min-width: 12rem;
                max-width: 12rem;
                svg {
                  width: 1rem;
                  height: 1rem;
                }
              }
              &.relation_badge {
                min-width: 10rem;
                max-width: 10rem;
              }
              &.member-contact_name {
                min-width: 12rem;
                max-width: 12rem;
              }
              &.member-leads_requests {
                min-width: 12rem;
                max-width: 12rem;
              }
              &.member-email_login {
                min-width: 9.5rem;
                max-width: 9.5rem;
              }
              &.member-store_name_hra {
                min-width: 15.5rem;
                max-width: 15.5rem;
              }
              &.member-county {
                min-width: 10rem;
                max-width: 10rem;
                width: auto;
              }
              &.member-store_name_regular {
                min-width: 18rem;
                max-width: 18rem;
              }
              &.member-updated_by {
                min-width: 10rem;
                max-width: 10rem;
              }
              &.member-postal_code {
                min-width: 10rem;
                max-width: 10rem;
              }
              &.member-phoneno {
                min-width: 12rem;
                max-width: 12rem;
              }
              &.member-state {
                width: auto;
                min-width: 7.8rem;
                max-width: 7.8rem;
              }
              &.member-rbna_distributor {
                min-width: 16rem;
                max-width: 16rem;
              }
              &.member-mailing_street_address {
                min-width: 20rem;
                max-width: 20rem;
              }
              &.member-id_number {
                min-width: 13rem;
                max-width: 13rem;
              }
              &.member-email_login {
                min-width: 10rem;
                max-width: 10rem;
                width: auto;
              }
              &.member-mailing_zip {
                min-width: 10.8rem;
                max-width: 10.8rem;
              }
              &.member-secondary_email {
                min-width: 18rem;
                max-width: 18rem;
              }
              &.member-account_name {
                min-width: 15rem;
                max-width: 15rem;
              }
              &.member-coke_bottler_region {
                min-width: 14rem;
                max-width: 14rem;
              }
              &.member-representative {
                min-width: 14rem;
                max-width: 14rem;
              }
              &.member-ticket_owner {
                min-width: 12rem;
                max-width: 12rem;
              }
              &.member-business_phone {
                min-width: 12rem;
                max-width: 12rem;
              }
              &.member-ach_enrolled {
                min-width: 11rem;
                max-width: 11rem;
              }
              &.member-date_enrolled {
                min-width: 11rem;
                max-width: 11rem;
                width: auto;
              }
              &.member-status {
                width: auto;
                min-width: 10rem;
                max-width: 10rem;
              }
              &.member-created_date {
                min-width: 11rem;
                max-width: 11rem;
              }
              &.member-inactive_date {
                min-width: 11rem;
                max-width: 11rem;
              }
              &.member-mailing_state {
                min-width: 11rem;
                max-width: 11rem;
              }
              &.member-created_by {
                min-width: 15rem;
                max-width: 15rem;
              }
              &.member-manager_territory {
                min-width: 12rem;
                max-width: 12rem;
              }
              &.member-master_dase {
                width: auto;
                min-width: 10.5rem;
                max-width: 10.5rem;
              }
              &.member-postal_code {
                width: auto;
              }
              &.member-ein {
                width: auto;
                min-width: 10rem;
                max-width: 10rem;
              }
              &.member-inactive_reason {
                width: auto;
                max-width: 10rem;
                min-width: 10rem;
              }
              &.member-city {
                min-width: 10.5rem;
                max-width: 10.5rem;
              }
              &.member-relationship {
                width: auto;
                min-width: 10rem;
                max-width: 10rem;
              }
              &.member-territory {
                width: auto;
                min-width: 10rem;
                max-width: 10rem;
              }
              &.member-user_name {
                min-width: 10rem;
                max-width: 10rem;
              }
              &.member-updated_date {
                min-width: 10rem;
                max-width: 10rem;
              }
              &.member-old_hra {
                min-width: 10rem;
                max-width: 10rem;
              }
              &.member-mailing_city {
                min-width: 10rem;
                max-width: 10rem;
              }
              &.member-store_created_date {
                min-width: 11rem;
                max-width: 11rem;
              }
              &.member-w9 {
                min-width: 10rem;
                max-width: 10rem;
              }
              &.member-street_address {
                min-width: 15rem;
                max-width: 15rem;
              }
              &.action_button {
                min-width: auto;
                justify-content: flex-end;
                flex: 1;
              }
            }
          }
        }
      }
    }
  }
  .accordion-item {
    margin-bottom: 0.8rem;
    border-radius: 8px;
    overflow: hidden;
    &.remove_accodian {
      .accordion-header {
        .accordion-button {
          &::after {
            content: none;
          }
        }
      }
    }
    .accordion-header {
      &.collapse-accordion {
        ul {
          .action_button {
            .view_substore {
              color: $white;
              transform: translateY(-50%);
              transition: all 0.3s ease;
              svg {
                transform: rotate(180deg);
                transition: all 0.3s ease;
              }
            }
          }
        }
      }
      .accordion-button {
        padding: 0.7rem 1.5rem 0.7rem 1rem;
        background: #002f70e8;
        color: #fff;
        &:is(:hover, :focus){
          a{
            color: #fff;
          }
        }
        &::after {
          content: none;
        }
        a{
          text-decoration: none;
          color: #fff !important;
          &.view_link_user{
            line-height: normal;
            font-size: 0.9rem;
            color: #ffffff;
            svg{
              width: 1.2rem;
              height: 1.2rem;
              margin-right: 0.3rem;
            }
          }
        }
        .store_hra_id {
          min-width: 5.4rem;
          span {
            background: rgba(255, 255, 255, 0.11);
            color: #ffffff;
            border: 1px dashed #ffffff;
            text-align: center;
          }
        }
        .representative_field{
          p{
            svg{
              width: 1.6rem;
              height: 1.6rem;
            }
          }
        }
        .action_button {
          >a {
            &:is(:hover, :focus) {
              color: $white;
            }
          }
        }

        &::after {
          filter: brightness(0) invert(1) !important;
          position: absolute;
          right: 0.5rem;
        }

        &.collapsed {
          background: #002e7024;
          color: #000000;
          a{
            text-decoration: none;
            color: #000000 !important;
            &.view_link_user{
              line-height: normal;
              font-size: 0.9rem;
              color: #000000;
              svg{
                width: 1.2rem;
                height: 1.2rem;
                margin-right: 0.3rem;
              }
            }
          }
          .store_hra_id {
            span {
              background: rgb(61 196 24 / 10%);
              color: #002e70;
              border: 1px dashed #002e70;
              font-size: 1rem;
              font-weight: 500;
              text-align: center;
            }
          }

          &::after {
            filter: brightness(0) invert(0) !important;
          }

          &:hover {
            background: #002f70e8;
            color: #fff;
            a{
              color: #fff !important;
              &.view_link_user{
                color: #fff;
              }
            }

            .store_hra_id {
              span {
                background: rgba(255, 255, 255, 0.11);
                color: #ffffff;
                border: 1px dashed #ffffff;
              }
            }

            .relation_badge {
              span {
                background: #ffffff10;
                // min-width: 5rem;
                text-align: center;
              }
            }

            &::after {
              filter: brightness(0) invert(1) !important;
            }

            span {
              svg {
                color: $white;
              }
            }
          }

          span {
            border-radius: 0.4rem;

            svg {
              color: $info;
            }
          }
        }

        span {
          svg {
            color: $white;
          }

          &.expire-agreement {
            animation: pulse-process 2s infinite;

            svg {
              color: $danger;
            }
          }
        }
      }
    }
    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      width: 100%;
      font-family: 'Montserrat';
      li {
        width: 33%;
        border-right: 1px solid #fff;
        &:first-child,
        &:last-child {
          border: none;
        }
        p {
          margin: 0;
          line-height: normal;
          font-size: 0.9rem;
          svg {
            width: 1.2rem;
            height: 1.2rem;
            margin-right: 0.3rem;
          }
        }
        &.relation_badge {
          width: 13%;
          display: flex;
          justify-content: center;
          span {
            background: #f4433621;
            padding: 0.2rem 0.4rem;
            line-height: normal;
            border-radius: 5px;
            font-size: 0.9rem;
            font-weight: 500;
          }
        }
        a{
          text-decoration: none;
          &.view_link_user{
            line-height: normal;
            font-size: 0.9rem;
            color: #646464;
            svg{
              width: 1.2rem;
              height: 1.2rem;
              margin-right: 0.3rem;
            }
          }
        }
        &.store_hra_id {
          width: 6rem;
          display: flex;
          justify-content: center;
          span {
            background: rgba(0, 46, 112, 0.0784313725);
            color: #002e70;
            padding: 0.2rem 0.3rem;
            line-height: normal;
            border-radius: 3px;
            border: 1px dashed #002e70;
            min-width: 5.4rem;
            text-align: center;
          }
        }
        &.store-hra-representative{
          p{
            svg{
              width: 1.6rem;
              height: 1.6rem;
            }
          }
        }
        &.representative_field{
          p{
            svg{
              width: 1.6rem;
              height: 1.6rem;
            }
          }
        }
        &.action_button {
          display: flex;
          justify-content: end;
          width: 21rem;
          padding-right: 0.8rem;
          .btn-info {
            font-size: 0.8rem;
            padding: 0.2rem 0.5rem;
            line-height: 1.3;
            color: #ffffff;
            margin-left: 0.7rem;
            white-space: pre;
            min-width: 6.6rem;

            &:is(:hover, :focus) {
              background-color: $primary;
              border-color: $primary;
              box-shadow: none;
            }

            svg {
              width: 0.7rem;
              height: 0.7rem;
              margin-right: 0.3rem;
            }
          }
          .view_substore {
            padding: 0;
            margin-left: 0.5rem;
            color: #212529;
            transform: translateY(-50%);
            transition: all 0.3s ease;
            position: absolute;
            right: 1rem;
            top: 50%;
            svg {
              width: 1.1rem;
              height: 1.1rem;
              transform: rotate(0);
              transition: all 0.3s ease;
            }
          }
          >span {
            min-width: 7rem;
            text-align: center;
          }

          span {
            border-radius: 0.4rem;

            svg {
              width: 1.4rem;
              height: 1.4rem;
            }

            &.expire-agreement {
              animation: pulse-process 2s infinite;

              svg {
                color: $danger;
              }
            }
          }
        }
      }
    }

    .accordion-body {
      padding: 0.8rem;

      > ul {
        padding: 0.4rem 0.4rem;
        border-radius: 7px;
        background: #00000008;
        margin-bottom: 0.5rem;
        color: #646464;
      }
      .interested_row{
        .store-hra-action{
          display: flex;
          align-items: center;
          justify-content: end;
          gap: 0.6rem;
          width: 10rem;
          .approve_btn{
            background: #02a7b5;
            border-color: #02a7b5;
            display: flex;
            gap: 0.3rem;
            align-items: center;
            padding: 0.1rem 0.4rem !important;
            font-size: 0.8rem;
            svg{
              width: 1rem;
              height: 1rem;
            }
            &:hover{
              background: #06828d;
             border-color: #06828d;
            }
            &.approved_show{
                  background: #d0e9a7;
                  border: none;
                  color: #80b425;
                  &:hover{
                    transform: none;
                  }
            }
          }
          .reject_btn{
            background: #cf3636;
            border-color: #cf3636;
            display: flex;
            gap: 0.3rem;
            align-items: center;
            padding: 0.1rem 0.4rem !important;
            font-size: 0.8rem;
            svg{
              width: 0.7rem;
              height: 0.7rem;
            }
            &.reject_show {
              background: #f56c6c;
              border: none;
              color: #cf3636;
              &:hover{
                transform: none;
              }
            }
            &:hover{
              background: #a82a2a;
             border-color: #a82a2a;
            }
          }
        }
        &.approverd_row{
          background: #e7f6ce;
        }
        &.rejected_row{
          background: #ffe3e3;
        }
      }
      .table-pagination{
        .pagination{
          margin: 0;
          padding: 0;
          list-style-type: none;
          gap: 0.5rem;
          display: flex;
          width: auto;
        }
      }
    }
  }
  &.vendor_accordion{
    margin-top: 0;
    .accordion-item{
      position: relative;
    }
    .accordion-header{
      .accordion-button{
        ul{
          .vendor_name{
            width: 20%;
            a {
              text-decoration: none;
              line-height: normal;
              font-size: 0.9rem;
              color: #000;
              img {
                max-height: 2rem;
                max-width: 2.5rem;
              }
              svg {
                margin-right: 0.2rem;
              }
            }
          }
          .vendor_number{
            width: 14%;
          }
          .vendor_address{
            width: 22%;
          }
          .vendor_zohoid{
            width: 10%;
          }
          .vendor_doctype{
            width: 10%;
            display: flex;
            align-items: center;
            justify-content: center;
            span{
              background: #8bc34a40;
              padding: 0.2rem 0.6rem;
              border-radius: 5px;
            }
          }
          li{
            &:first-child,
            &:last-child{
              border-right: 1px solid #fff;
            }
            p{
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
        &:hover{
          .vendor_name{
            a{
              color: #fff;
            }
          }
        }
      }
    }
    .accordion-collapse.show {
      ~ .vendor_action_button {
        .view_substore {
          color: $white;
          transform: translateY(-50%);
          transition: all 0.3s ease;
          svg {
            transform: rotate(180deg);
            transition: all 0.3s ease;
          }
        }
      }
    }
    .vendor_action_button {
      gap: 0.2rem;
      position: absolute;
      right: 3.5vw;
      top: 0.6vw;
      z-index: 9;
      display: flex;
      align-items: center;
      .vendor-assign-status {
        width: 5rem;
        display: flex;
        justify-content: center;
        .badge {
          border-radius: 100px;
          line-height: normal;
          height: auto;
          font-weight: 500;
          letter-spacing: 0.5px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0.3rem 0.5rem;
          transition: all 0.3s ease;
          &:is(:hover, :focus) {
            cursor: pointer;
            background-color: $success;
            transition: all 0.3s ease;
          }
          &.badge-danger {
            background-color: #e34f4f;
            &:is(:hover, :focus) {
              cursor: pointer;
              background-color: $success;
            }
          }          
        }
      }
      a{
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover{
          color: $primary !important;
        }
      }
      .like_btn{
        // background: #e34f4f;
        background: #86b731;
        color: #fff !important;
        padding: 0.2rem 0.5rem;
        display: flex;
        align-items: center;
        gap: 0.3rem;
        font-size: 0.9rem;
        min-width: initial;
        transition: all 0.3s ease-in-out;
        border-radius: 7px;
        text-decoration: none;
        svg{
          width: 1.2rem;
          height: 1.3rem;
          color: #fff;
        }
        .fill_svg{
          display: block;
        }
        .line_svg{
          display: none;
        }
        &.active{
          background: #e34f4f;
          .fill_svg{
            display: none;
          }
          .line_svg{
            display: block;
          }
        }
      }
      .view_substore {
        padding: 0;
        margin-left: 0.5rem;
        color: #212529;
        transform: translateY(-50%);
        transition: all 0.3s ease;
        position: absolute;
        right: -2.5vw;
        top: 50%;
        svg {
          width: 1.1rem;
          height: 1.1rem;
          transform: rotate(0);
          transition: all 0.3s ease;
        }
      }
    }
    .accordion-collapse{
      .accordion-body{
        .interested_head{
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 0.7rem;
          h3{
            font-size: 1.2rem;
            color: $primary;
            margin: 0;
          }
          > div{
            display: flex;
            gap: 0.8rem;
            .filter_btn{
              display: flex;
              align-items: center;
              .btn{
                background: none;
                color: $primary;
                border: none;
                padding: 0;
                &:hover{
                  transform: none;
                }
                &::after{
                  display: none;
                }
              }
              .dropdown-menu{
                .dropdown-item{
                  &.Active{
                    color: #fff;
                    background: $primary;
                  }
                }
              }
            }
          }
        }
      }
      &.show ~ .vendor_action_button{
        a{
          &:hover{
            color: #fff !important;
          }
        }
      }
    }
    &.vendor-assign {
      .vendor_member_row {
        .vendor_name {
          padding-left: 2rem;
        }
        .vendor_doctype {
          width: 8%;
        }
      }
      .accordion-item {
        .accordion-header {
          .accordion-button {
            padding: 0.7rem 1.5rem 0.7rem 2.5rem;
            .vendor_doctype {
              width: 8%;
            }
          }
          &.collapse-accordion {
            ~ .vendor_check {
              .form-check {
                .form-check-input {
                  border-color: $white;
                  background-color: rgba($white, 0.3);
                }
              }
            }
          }
        }
        .vendor_check {
          width: 1.6rem;
          position: absolute;
          left: 0.7rem;
          top: 0.85rem;
          z-index: 9;
          .form-check {
            cursor: pointer;
            .form-check-input {
              cursor: pointer;
              border-color: $primary;
              background-color: rgba($primary, 0.3);
              &:checked[type=checkbox] {
                background-color: $primary;
                &:is(:hover, :focus) {
                  border-color: $primary;
                  background-color: $primary;
                }
              }
              &:is(:hover, :focus) {
                border-color: $info;
                background-color: rgba($info, 0.3);
              }
            }
          }
        }
      }
    }
  }
}
.rebate_data {
 cursor: pointer;
  .rebate_amount {
    cursor: pointer;
    position: relative;
    &:is(:hover, :focus) {
      color: $info;
      &::after {
        content: "Rebate Details";
        position: absolute;
        right: 3.8rem;
        top: 0.25rem;
        display: inline-block;
        background: #1e1d1d;
        font-size: 0.7rem;
        color: #fff;
        padding: 2px 5px;
        border: 1px solid #f4f4f4;
      }
    }
  }
}
.cursor-pointer {
  cursor: pointer;
}
.vendor_member_row{
    margin: 0;
    padding: 0.4rem 0.4rem;
    list-style-type: none;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    width: 100%;
    font-family: 'Montserrat';
    border-radius: 7px;
    background: #ffffffc9;
    margin-bottom: 0.5rem;
    color: #646464;
    transition: all 0.3s ease-in;
    &:hover{
      background: #fffffff8;
    }
    li {
      width: 30vw;
      min-width: 7vw;
      &:not(:first-child,:last-child){
        border-right: 1px solid rgba($primary, 0.5);
      }
      p {
        margin: 0;
        line-height: normal;
        font-size: 0.9rem;
        display: flex;
        align-items: center;
        > span{
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          p{
            margin-bottom: 0;
            font-size: 0.8rem;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
          }
        }
        .user_details_field{
          display: flex;
          flex-direction: column;
        }
        svg {
          width: 1.2rem;
          height: 1.2rem;
          margin-right: 0.3rem;
          flex: none;
        }
      }
      &.relation_badge {
        width: 10%;
        display: flex;
        justify-content: center;
        span {
          background: #f4433621;
          padding: 0.2rem 0.4rem;
          line-height: normal;
          border-radius: 5px;
          font-size: 0.9rem;
          font-weight: 500;
        }
      }
      &.store_hra_id {
        width: 8vw;
        min-width: 7vw;
        display: flex;
        justify-content: center;
        span {
          background: rgba(0, 46, 112, 0.0784313725);
          color: #002e70;
          padding: 0.2rem 0.3rem;
          line-height: normal;
          border-radius: 3px;
          border: 1px dashed #002e70;
        }
      }
      &.representative_field{
        svg{
          width: 1.6rem;
          height: 1.6rem;
        }
      }
      &.member_date_field{
        width: 19vw;
        min-width: 7vw;
      }
      &.member_user_name{
        width: 33vw;
        min-width: 7vw;
      }
    }
    .store-hra-action{
      display: flex;
      align-items: center;
      justify-content: end;
      gap: 0.6rem;
      width: 8%;
      .approve_btn{
        background: #02a7b5;
        border-color: #02a7b5;
        display: flex;
        gap: 0.3rem;
        align-items: center;
        padding: 0.1rem 0.4rem !important;
        font-size: 0.8rem;
        min-width: 6.2rem;
        svg{
          width: 1rem;
          height: 1rem;
        }
        &:hover{
          background: #06828d;
         border-color: #06828d;
        }
        &.approved_show{
          background: #c9e49f;
          border: none;
          color: #80b425;
          &:hover{
            transform: none;
          }
        }
      }
      .reject_btn{
        background: #cf3636;
        border-color: #cf3636;
        display: flex;
        gap: 0.3rem;
        align-items: center;
        padding: 0.1rem 0.4rem !important;
        font-size: 0.8rem;
        svg{
          width: 0.7rem;
          height: 0.7rem;
        }
        &:hover{
          background: #a82a2a;
         border-color: #a82a2a;
        }
        &.reject_show {
          background: #f56c6c;
          border: none;
          color: #cf3636;
          &:hover{
            transform: none;
          }
        }
      }
    }
    &.approverd_row{
      background: #e0e9d1;
    }
    &.rejected_row{
      background: #ffe3e3;
    }
    &.member_row_head{
      background: $primary;
      padding: 0.3rem 0.4rem;
      color: #fff;
      .store_hra_id_name{
        width: 8vw;
        min-width: 7vw;
      }
      .store-hra-action{
        width: 13%;
        justify-content: center;
      }
      .member-hra_id{
        width: 6.6rem;
        min-width: 6.6rem;
        display: flex;
        // justify-content: center;
        align-items: center;
      }
      .member-name{
        width: 30.7%;
        min-width: inherit;
      }
      .member-email{
        width: 31.5%;
        min-width: inherit;
      }
      .member-phoneno{
        width: 31.5%;
        min-width: inherit;
      }
      .member-representative{
        width: 31.5%;
        min-width: inherit;
      }
      .member-relation{
        width: 12.5%;
        display: flex;
        justify-content: center;
        min-width: inherit;
      }
      .member-action-btn{
        width: 22.6rem;
      }
      li{
        display: flex;
        align-items: center;
        justify-content: space-between;
        &:not(:last-child){
          border-right: 1px solid rgba(255, 255, 255, 0.534);
          padding-right: 0.2rem;
        }
        .btn{
          padding: 0;
          color: #fff;
          background: #fff3;
          border: none;
          &:is(:hover,:focus){
            transform: none;
            background: rgba(255, 255, 255, 0.322);
          }
          svg{
            width: 1.4rem;
            height: 0.8rem;
          }
        }
      }
    }
    &.vendor_row_head{
      background: #002E70;
      padding: 0.3rem 0.4rem;
      color: #fff;
      .vendor_name{
        width: 20.5%;
      }
      .vendor_number{
        width: 13.5%;
      }
      .vendor_address{
        width: 21.7%;
      }
      .vendor_zohoid{
        width: 9.7%;
      }
      .vendor_doctype{
        width: 10%;
        display: flex;
        justify-content: center;
      }
      li{
        border-right: 1px solid #fff;
      }
    }
}
.interested_row_head{
  background: #e7e7e7 !important;
  font-weight: 500;
  .interested_id{
    width: 8.6rem !important;
  }
  .interested_user{
    width: 33%;
  }
  .interested_email{
    width: 33%;
  }
  .interested_phone{
    width: 33%;
  }
  .interested_representative{
    width: 33%;
  }
  .store-hra-action{
    width: 10rem !important;
  }
  li{
    border-right: 1px solid #fff !important;
    &:first-child{
      border-right: 1px solid #fff !important;
    }
  }
}
.select_doctype{
  h2{
    text-align: center;
    font-size: 1.6rem;
    color: #000;
    margin-bottom: 1rem;
  }
  ul{
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    li{
      width: 11vw;
      a{
        padding: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
        text-decoration: none;
        background: #00bcd436;
        border-radius: 10px;
        transition: all 0.3s ease-in-out;
        &:hover{
          background: #00bbd488;
          transform: scale(1.02);
        }
        img{
          max-width: 5rem;
        }
        span{
          font-size: 1.2rem;
          color: #000;
          font-weight: 600;
        }
      }
    }
  }
  &.select_doctype_open{
    h2{
      display: none;
      transition: all 0.3s ease-in-out;
    }
    ul{
      justify-content: flex-start;
      transition: all 0.3s ease-in-out;
      li{
        width: 7.5vw;
        transition: all 0.3s ease-in-out;
        a{
          transition: all 0.3s ease-in-out;
          flex-direction: row;
          gap: 0.5rem;
          padding: 0.5rem 0.8rem;
          border-radius: 8px 8px 0 0;
          img{
            max-width: 1.8rem;
          }
          span{
            font-size: 1rem;
          }
          &.active_tab{
            background: #9bd732;
            img{
              filter: brightness(1) invert(1);
            }
            span{
              color: #fff;
            }
          }
        }
      }
    }
  }
}
.selected_doctab{
  border-radius: 0 8px 8px 8px;
  .banner_dropzone{
    .dropzone-container{
      padding: 0;
      min-height: 70vh;
      &:is(:hover,:focus){
        background-color: #ffd90011;
      }
      .attachment-data{
        max-width: 100%;
        max-height: 100%;
        .attachment-head{
          max-height: 100%;
          max-width: 100%;
          position: relative;
          img{
            max-width: 100%;
          }
          .remove-box{
            background: #ec4343;
            position: absolute;
            top: 0.5vw;
            z-index: 9;
            right: 0.6vw;
            color: #fff;
            border-radius: 100px;
            width: 3rem;
            height: 3rem;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 0.3s ease-in-out;
            svg{
              width: 1.5rem;
              height: 1.5rem;
            }
            &:hover{
              background: #c93939;
              transform: scale(1.02);
            }
          }
        }
      }
    }
  }
  .vendors_dropzone{
    ul{
      display: flex;
      flex-wrap: wrap;
      li{
       width: 11.5rem;
       .attachment-data{
        height: 100%;
        .attachment-head{
          height: 100%;
          .file-box{
            display: flex;
            align-items: center;
            justify-content: center;
            background: #f1f1f1;
            padding: 0.3rem;
          }
        }
       }
      }
    }
  }
}
.html_card{
  .html_title{
    margin-bottom: 0.2rem;
    color: #002e70;
    font-weight: 500;
  }
  .html_text_editor{
    background: #000;
    padding: 1rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .jodit-react-container{
      width: 100%;
      .jodit-toolbar__box{
        &:not(:empty){
          &:not(:empty){
            background-color: #000;
            color: #fff;
          }
        }
        .jodit-ui-group{
          .jodit-toolbar-button{
            .jodit-toolbar-button__button{
              .jodit-toolbar-button__icon{
                svg{
                  fill: #fff;
                }
              }
              &:hover{
                &:not([disabled]){
                  background-color: #dcdcdc61;
                }
              }
            }
            .jodit-toolbar-button__trigger{
              svg{
                fill: #fff;
              }
              &:hover{
                &:not([disabled]){
                  background-color: #dcdcdc61;
                }
              }
            }
          }
        }
      }
      .jodit-workplace{
        background-color: #000;
        color: #fff;
        p{
          // background: transparent !important;
            // color: #fff !important;
          span{
            // background: transparent !important;
            // color: #fff !important;
          }
          a{
            // color: #7bb1ff !important;
            transition: all 0.3s ease-in-out;
            &:hover{
              // color: #4782d4 !important;
            }
          }
        }
      }
      .jodit-wysiwyg{
        color: #fff;
      }
    }
  }
  .vendor_images{
    display: flex;
    flex-direction: column;
    align-items: center;
    .dropzone{
      width: 52%;
    }
  }
}
.back-arrow_button{
  .btn{
    background: transparent;
    border: 1px solid #002e70;
    padding: 0;
    width: 2rem;
    height: 2rem;
    border-radius: 100px;
    transition: all 0.3s ease-in-out;
    margin-right: 0.6rem;
    svg{
      color: #002e70;
      width: 1.2rem;
    }
    &:hover{
      background: #002e70;
      svg{
        color: #fff;
      }
    }
  }
}
.vendor_details{
  .brand_logo{
    background: #002e700a;
    border-radius: 8px;
    padding: 0.2rem 0.7rem;
    .form-group{
      height: 100%;
      .form-label{
        position: relative;
        top: 0;
        left: 0;
        margin: 0;
        margin-bottom: 0.2rem;
        color: #002e70;
        font-weight: 500;
      }
      .vendor_logo_file{
        display: flex;
        justify-content: center;
        height: 100%;
        .dropzone{
          min-height: 136px;
          max-height: 83%;
          .dropzone-container{
            height: 100%;
            .dropzone-title{
              text-align: center;
              font-size: 0.8rem;
            }
          }
        }
        img{
          width: 13vw;
          height: 14vw;
          max-width: 100%;
          object-fit: contain;
        }
        .remove-box{
          position: absolute;
          top: 0.4vw;
          right: 0;
          background: #ff4141;
          color: #fff;
          border-radius: 100px;
          width: 1.8rem;
          height: 1.8rem;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: all 0.3s ease-in-out;
          cursor: pointer;
          &:hover{
            transform: scale(1.02);
            background: #b92b2b;
          }
        }
      }
    }
  }
  .vendor_details_row{
    margin-bottom: 1rem !important;
    display: flex;
    align-items: center;
    > div{
      .form-group{
        margin-bottom: 0;
      }
    }
  }
  .select_category_box{
    display: flex;
    background: #f3f3f3;
    padding: 0.4rem 0.5rem;
    align-items: center;
    border-radius: 6px;
    .form-group{
      width: 100%;
      margin-right: 1rem;
    }
  }
}

.offcanvas-header{
  background-color: $primary;
  color: $white;
  .btn-close{
    filter: invert(1) brightness(1);
  }
}
.offcanvas-body{
  padding-top: 0 !important;
  .add_caregory_field{
    position: sticky;
    top: 0;
    background: #fff;
    padding: 1rem 0;
    z-index: 1;
  }
  form{
    display: flex;
    align-items: flex-start;
    .form-group{
      margin: 0;
      width: 100%;
      margin-right: 1rem;
    }
    .btn-primary{
      width: 3.2rem;
      height: 3rem;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .category_list{
    ul{
      margin: 0;
      padding: 0;
      list-style-type: none;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      li{
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #002e701c;
        padding: 0.4rem 0.7rem;
        border-radius: 6px;
        transition: all 0.3s ease-in-out;
        p{
          margin: 0;
          width: 70%;
          line-height: normal;
        }
        >div{
          width: 25%;
          display: flex;
          align-items: center;
          justify-content: end;
          gap: 0.6rem;
          .react-switch-bg{
            z-index: 0;
          }
        }
        .edit_btn{
          padding: 0;
          color: $info;
        }
        &:hover{
          background: rgba($primary, 0.35);
        }
      }
    }
  }
}
.members-modal {
  .dropzone {
    .dropzone-container {

      &:hover,
      &:focus {
        border-color: lighten($orange, 37%);

        .file-icon {
          color: lighten($orange, 37%);
        }

        .dropzone-title {
          color: lighten($orange, 37%);
        }
      }
    }
  }
}

/*====================================================================================================================================*/
/*====================================================================================================================================*/
/*====================================================================================================================================*/


/*==========================   fornt_login-page  ==============================*/

.front_login_page {
  min-height: 100vh;
}

.login_box {
  display: flex;
  background: #fff;
  height: 100vh;

  .login_bg_fornt {
    max-width: 100%;
    width: 70%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .login_field_fornt {
    max-width: 30%;
    padding: 2rem;
    display: flex;
    align-items: center;
    position: relative;

    .login-form {
      .loging-head {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;

        img {
          max-width: 65%;
        }

        h2 {
          margin-bottom: 1.3rem;
          color: #002e70;
        }
      }
      .login_member {
        background: rgba($primary, 0.15);
        border-radius: 1.5rem;
        padding: 1rem 0.5rem 0;
        .form-control:not(:placeholder-shown) ~ .form-label {
          background: linear-gradient(270deg, hsla(215, 29%, 88%, 1) 40%, hsla(0, 0%, 98%, 1) 50%);
          background: -moz-linear-gradient(270deg, hsla(215, 29%, 88%, 1) 40%, hsla(0, 0%, 98%, 1) 50%);
          background: -webkit-linear-gradient(270deg, hsla(215, 29%, 88%, 1) 40%, hsla(0, 0%, 98%, 1) 50%);
        }             
        .sticky_label {
          .form-label {                   
            background: -webkit-linear-gradient(270deg, hsl(215, 29%, 88%) 40%, hsl(0, 0%, 98%) 50%);
            color: #002E70 !important;        
          }
        }             
      }
      .help_btn {
        text-align: center;
        margin-top: 0.5rem;
        margin: 0.5rem auto 0;
        display: table;
        text-decoration: none;
        &:is(:hover, :focus) {
          color: $info;
        }
      }

      .form-control {
        -webkit-appearance: auto;
        appearance: auto;
        &::placeholder {
          color: #b4b4b4 !important;
          display: block;            
        }
      }   

      .pass_eye_btn {
        padding: 0;
        position: absolute;
        right: 1rem;
        top: 50%;
        transform: translate(0, -50%);
      }

      .remember_me {
        display: flex;
        align-items: center;
        gap: 0.1rem;
        margin-left: 0.3rem;
        margin-bottom: 0.4rem;
        .form-label {
          top: inherit;
          left: inherit;
          margin-bottom: 0;
          position: inherit;
          cursor: pointer;
          pointer-events: inherit;
        }

        input[type=checkbox] {
          position: relative;
          border: 2px solid #002e70;
          border-radius: 2px;
          background: none;
          cursor: pointer;
          line-height: 0;
          margin: 0 .4em 0 0;
          outline: 0;
          padding: 0 !important;
          vertical-align: text-top;
          height: 18px;
          width: 18px;
          -webkit-appearance: none;
          opacity: .5;

          &:hover {
            opacity: 1;
          }

          &:checked {
            background-color: #002e70;
            opacity: 1;
            &::before {
              border-color: #fff;
            }
          }

          &::before {
            content: '';
            position: absolute;
            right: 50%;
            top: 50%;
            width: 4px;
            height: 10px;
            border-style: solid;
            border-color: transparent;
            border-width: 0 2px 2px 0;
            margin: -1px -1px 0 -1px;
            transform: rotate(45deg) translate(-50%, -50%);
            z-index: 2;
          }
        }
      }

      .forgot_btn {
        display: flex;
        justify-content: center;
        padding: 0.4rem 0 0.5rem;
        margin-bottom: 0.5rem;

        a {
          min-width: 9rem;
          padding: 0 0.8rem;
          transition: all 0.3s ease-in;
          font-weight: 500;
          text-decoration: none;
          font-size: 1.1rem;
          color: #002d70;

          // &:first-child {
          //   border-right: 1px solid #000;
          //   text-align: end;
          // }

          &:hover,
          &:focus {
            text-decoration: underline;
            color: #8a0a0a;
          }
        }
      }

      .become_link {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1rem 0;
        gap: 0.7rem;

        span {
          white-space: pre;
        }

        a {
          font-weight: 500;
          text-decoration: none;
          font-size: 1.1rem;
          color: #0648F1;
          transition: all 0.3s ease-in;

          &:hover,
          &:focus {
            text-decoration: underline;
            color: #13317c;
          }
        }
      }
    }
  }
  .policy-links {         
    position: absolute;
    bottom: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    right: 0;    
    a {
      text-decoration: none;
      padding: 0 0.8rem;
      transition: all 0.2s ease;
      color: $primary;
      font-size: 0.9rem;

          &:first-child {
            border-right: 1px solid #000;
            text-align: end;
          }

          &:hover,
          &:focus {
            text-decoration: underline;
            color: $info;
          }
    }
  }
}

/*------------------ Header-front ------------------*/

.front_header {
  .navbar {
    background: #E5EDFA;
    padding: 0.6rem 5.5rem !important;

    &::before {
      background: #E5EDFA;
      width: 100%;
    }

    .navbar-brand {
      img {
        width: 14rem;
        max-width: 100%;
      }
    }

    .navbar-nav {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.5rem;

      >.nav-item {
        &.login_btn {
          >a.active {
            background: transparent;
          }
        }

        &.promotion-story {
          >a {
            svg {
              width: 1.3rem;
              height: 1.3rem;
            }
          }
        }

        > a {
          font-size: 1rem;
          display: flex;
          align-items: center;
          gap: 0.3rem;
          padding: 0.3rem;
          border-radius: 5px;
          color: #5B5B5B;
          transition: all 0.3s ease;
          &:is(:hover, :focus) {
            color: $success;
            transition: all 0.3s ease;
          }
          &.active {
            background: $primary;
            color: $white;
          }
          &.vendor_menu {
            gap: 0.05rem;
            svg {
              width: 1.4rem;
              height: 1.4rem;
            }
          }

          svg {
            width: 1rem;
            height: 1rem;
          }
        }
      }

      .dropdown-menu {
        position: absolute;
        right: 0;
      }

      a {
        text-decoration: none;
      }

      .become_btn {
        a {
          background: #002E70;
          color: #fff;
          padding: 0.4rem 0.7rem;
          border-radius: 5px;
        }
      }

      .store_dropdown {
        width: 12rem;

        .form-control {
          height: 2rem;
          font-size: 0.8rem;
          line-height: 1.3rem;

          &.selectbox {
            >[class*=control] {
              min-height: 2rem;
              max-height: 2.2rem;

              >div {
                max-height: 2.3rem;
                padding-top: 0;
                padding-bottom: 0;
              }
            }

            >div {
              &:first-child {
                min-height: 2.6rem !important;
                max-height: 2rem !important;
              }
            }
          }
        }

        &.show_parents {
          .form-control {
            #react-select-3-option-0 {
              background: #f4cb56;
            }
          }
        }
      }

      .notification_bell {
        .dropdown-toggle {
          color: $primary;
          padding: 0;
          position: relative;
          width: 1.5rem;
          height: 1.5rem;
          display: flex;
          align-items: center;

          svg {
            width: 1.4rem;
            height: 1.4rem;
          }

          &::after {
            display: none;
          }

          .notification_badge {
            position: absolute;
            background: #ff0303;
            color: #fff;
            border-radius: 50px;
            width: 1.3rem;
            height: 1.3rem;
            display: flex;
            justify-content: center;
            align-items: center;
            top: -0.3rem;
            right: 0;
            font-size: 0.7rem;
          }
        }

        &:hover {
          .dropdown-toggle {
            color: $orange;
          }
        }

        .dropdown-menu {
          width: 30rem;

          ul {
            padding: 0.5rem;
            margin: 0;
            height: 100%;
            max-height: 20rem;
            min-height: 15.5rem;
            overflow-x: auto;
            width: 100%;
            display: inline-block;

            li {
              display: inline-block;
              width: 100%;
              background-color: rgba(79, 45, 127, 0.05);
              border-radius: 0.25rem;
              position: relative;
              border-bottom: 0;
              overflow: hidden;
              padding: 0;
              transition: all 0.3s ease;

              &.read-notification-box {
                .notification_item {
                  background-color: rgb(246 246 246);

                  .chat-title {
                    .assigne-box {
                      &.green-round {
                        border: 1px solid #ffffff;
                        background-color: rgb(140 140 140 / 24%);
                        color: #6b6e6e;
                      }
                    }
                  }
                }
              }

              &.unread-notification-box {
                .notification_item {
                  background-color: #d7ebf0;
                }
              }

              &:last-of-type {
                border-bottom: 0;
              }

              &:hover {
                background-color: #e6e7e7;
              }

              .mark_asread {
                position: absolute;
                right: 0;
                padding: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 2rem;
                border-radius: 0rem 0.4rem 0.4rem 0rem;
                height: 100%;
                top: 0;
                background: $primary;
                color: $white;

                &:hover,
                &:focus {
                  background: darken($primary, 8%);
                }
              }

              .notification_item {
                display: flex;
                gap: 0.5rem;
                width: 100%;
                align-items: flex-start;
                padding: 0.8rem 0.7rem;
                transform: translateX(0rem);
                transition: all 0.3s ease;
                background: #f6f6f6;

                .chat-title {
                  gap: 1rem;
                  display: flex;
                  justify-content: center;
                  align-items: center;

                  >div {
                    .small-title {
                      display: inline-block;

                      small {
                        font-size: 0.8rem;
                        color: #6a6a6a;
                      }
                    }
                  }

                  .assigne-box {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 3rem;
                    height: 3rem;
                    border-radius: 50px;
                    padding: 0.3rem;

                    &.green-round {
                      border: 1px solid #ffffff;
                      background-color: #1fb1be3d;
                      color: #1fb1be;
                    }
                  }
                }

                .notification_detail {
                  width: 100%;
                  display: inline-block;

                  div {
                    .small-title {
                      display: flex;
                      justify-content: flex-end;
                      gap: 0.6rem;

                      small {
                        color: #000000ba;
                      }
                    }
                  }
                }
              }

              &.no_notification {
                background: transparent;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 15rem;
                max-height: 100%;

                .no_notification_img {
                  opacity: 0.6;
                  max-width: 100%;
                  width: 15rem;
                }
              }

              p {
                line-height: normal;
                margin-bottom: 0 !important;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
              }
            }
          }
        }
      }

      .login_btn {
        a {
          color: #002E70;
          display: flex;
          align-items: center;
          gap: 0.4rem;
          font-size: 1.2rem;
          font-weight: 500;
          padding: 0;

          .loggd_in_svg {
            width: 2.3rem;
            height: 2.3rem;
            margin: 0;
            border: 1px solid #002E70;
            border-radius: 50px;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
              display: block;
              width: 1.3rem;
              height: 1.3rem;
            }
          }

          &:hover {
            .loggd_in_svg {
              background: lighten($orange, 30%);
            }

            .loggd_in_text {
              h5 {
                color: $orange;
              }

              span {
                color: $orange;
              }
            }
          }
        }

        &.loggd_in_btn {
          .loggd_in_svg {
            background: #B7DC4C;
            color: #fff;
            border: 1px solid #ffffff;
            font-size: 1rem;
            transition: all 0.3s ease;
            padding-top: 0;
            line-height: 2;
          }

          .loggd_in_text {
            display: flex;
            flex-direction: column;
            gap: 0;

            h5 {
              margin: 0;
              font-size: 1.2rem;
              transition: all 0.3s ease;
            }

            span {
              line-height: normal;
              font-size: 0.9rem;
              color: $primary;
              font-weight: 700;
              transition: all 0.3s ease;

              small {
                font-weight: normal;
              }
            }
          }
        }
      }

      .menu_dropdown {
        .dropdown-toggle {
          padding: 0;
          color: #002E70;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          font-size: 0.7rem;
          gap: 0;

          &::after {
            display: none;
          }

          svg {
            width: 1.7rem;
            height: 1.7rem;
          }

          span {
            font-weight: 500;
          }
        }

        &:is(:hover, :focus) {
          .dropdown-toggle {
            color: $orange;
          }
        }

        .dropdown-menu.show {
          min-width: 19rem;
          max-height: 25rem;
          overflow: auto;
          padding: 0.7rem;
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
          background: lighten($primary, 65%);
          border-radius: 12px;
          border: none;
          box-shadow: 0 0.3rem 0.5rem #0000003b;

          .dropdown-item {
            padding: 0.5rem 0.5rem;
            display: inline-flex;
            gap: 0.5rem;
            background: #fff;
            color: $primary;
            border-radius: 7px;
            transition: all 0.3s ease;
            &:hover,
            &:focus {
              background: #dbe2f0;
              color: $primary;
            }
            &.active {
              color: $white;
              background-color: $primary;
              cursor: default;
              &.vendor-menu {
                svg{
                  width: 1.7rem;
                  height: 1.7rem;
                }
              }
            }
            &.vendor-menu {
              gap: 0.2rem;
              &.active {
                svg {
                  fill: $white;
                }
              }
              svg {
                width: 1.7rem;
                fill: $primary;
                height: 1.7rem;
              }
            }
            svg {
              width: 1.3rem;
              height: 1.3rem;
            }
          }
        }

        .mobile-view-user {
          display: none !important;
        }
      }
    }
  }
}

.promotion_slide {

  // .slick-list {
  //   padding: 0 !important;
  // }
  .slick-slide {
    img {
      max-width: 100%;
      width: 100%;
    }
  }
}


/*==========================   fornt_login-page=End  ==============================*/


/*==========================   fornt_home-page=Start  ==============================*/

.front_main {
  .container {
    max-width: calc(100% - 10rem) !important;
    margin: 0 5rem !important;
    padding: 0 !important;
  }

  .front-home {
    background: #f4f4f4;
    height: calc(100vh - 9rem);
    margin-bottom: -1.5rem;
    padding-bottom: 1.5rem;

    >h2 {
      text-align: center;
      margin: 2rem 0;
      display: inline-block;
      width: 100%;

      span {
        color: $primary;
      }
    }

    .container {
      >ul {
        padding: 0;
        margin: 0;
        list-style: none;
        width: 100%;
        display: grid;
        gap: 1rem;
        grid-template-columns: repeat(auto-fill, minmax(7rem, calc(33.33% - 0.67rem)));

        li {
          >a {
            display: inline-flex;
            align-items: center;
            gap: 1rem;
            background: $white;
            color: $primary;
            width: 100%;
            height: 100%;
            padding: 2rem 1rem;
            font-size: 1.3rem;
            font-weight: 600;
            text-decoration: none;
            border-radius: 10px;
            box-shadow: $shadow;
            transition: all 0.3s ease;

            &:is(:hover, :focus) {
              background: $primary;
              color: $white;

              span {
                background: rgba($white, 0.15);
              }
            }

            span {
              width: 6rem;
              height: 6rem;
              display: flex;
              padding: 0.5rem;
              border-radius: 10px;
              background: transparent;
              display: flex;
              align-items: center;
              justify-content: center;

              img {
                max-width: 100%;
                max-height: 100%;
              }
            }
          }
        }
      }
    }
  }

  .product-card-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1.1rem;

    .product-card {
      margin: 0;

      .product-card-img {
        position: relative;
        flex: none;

        .product-card-overlay {
          position: absolute;
          bottom: -1%;
          width: 100%;
          margin-bottom: 0;
          text-align: center;
          padding: 0.3rem 0;
          background-color: rgb(255 255 255 / 54%);
          backdrop-filter: blur(10px);
          line-height: 1.4;
          font-size: 0.9rem;
          z-index: 2;

          b {
            margin-left: 0.4rem;
          }
        }
      }
    }
  }

  .product-card {
    background-color: #f0f1f7;
    border-radius: 1rem;
    margin: 0 2%;
    overflow: hidden;
    transition: all 0.4s ease;
    border: 1px solid #00000005;
    height: 100%;
    display: flex;
    flex-direction: column;

    &:hover {
      box-shadow: 0.1rem 0.1rem 0.5rem #00000013;

      .product-card-img {
        img {
          transform: rotate(3deg) scale(1.07);
          transform-box: border-box;
        }

        .view_more {
          transform: translateX(0);
          transition: all 0.3s ease;
        }
      }
    }

    .product-card-img {
      overflow: hidden;
      position: relative;
      flex: none;

      img {
        width: 100%;
        aspect-ratio: 2/1;
        object-fit: cover;
        transition: all 0.3s ease;
      }

      .product-card-overlay {
        position: absolute;
        bottom: -1%;
        width: 100%;
        margin-bottom: 0;
        text-align: center;
        padding: 0.3rem 0;
        background-color: rgba(255, 255, 255, 0.54);
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
        line-height: 1.4;
        font-size: 0.73rem;
        z-index: 2;
      }

      .view_more {
        position: absolute;
        left: 0;
        border: none;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba($primary, 0.8);
        display: flex;
        align-items: center;
        justify-content: center;
        transform: translateX(-100%);
        transition: all 0.3s ease;
        z-index: 1;

        svg {
          color: $success;
          width: 1.5rem;
          height: 1.5rem;
        }
      }
    }

    .product-card-body {
      padding: 1rem;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .product-data {
        display: flex;
        justify-content: space-between;
        margin-top: 0.4rem;

        .cost-discount-box {
          width: 100%;
          max-height: 2.7rem;
          overflow: hidden;
          position: relative;
        }

        p {
          margin-bottom: 0;
          font-size: 0.9rem;
          color: #535353;
          line-height: 1.4;
          margin: 0;
          width: 100%;
          white-space: pre;
          overflow: hidden;
          text-overflow: ellipsis;

          span {
            font-size: 0.9rem;
            margin-right: 0.2rem;
            display: inline-block;
            hyphens: auto;
            text-transform: capitalize;
            max-width: 50%;
            white-space: pre;
            text-overflow: ellipsis;
            overflow: hidden;
            vertical-align: middle;
          }

          &:last-of-type {
            margin-bottom: 0;
          }
        }

        b {
          color: $black;
        }

        span {
          font-size: 1.5rem;
          color: $primary;
          font-weight: 500;
        }

        .product-more-btn {
          display: inline-block;
          padding-left: 0.5rem;
          font-size: 0.8rem;
          color: #C24200;
          position: absolute;
          bottom: 8%;
          right: 0.2rem;
          background-color: #f0f1f7;

          &:hover {
            color: $primary;
            text-decoration: underline;
          }
        }
      }

      .product-expire-date {
        margin-bottom: 0;
        font-size: 0.9rem;
        font-weight: 600;
        color: $black;
        line-height: normal;

        span {
          color: $gray-700;
          font-weight: normal;
        }
      }
    }
  }

  .like-expire-box {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.bottom_btn {
      .btn {
        font-size: 0.9rem;
        display: flex;
        align-items: center;
        gap: 0.2rem;
        padding: 0.3rem 0.8rem;
        margin-top: 0.6rem;
        margin-bottom: -0.4rem;
        transform: none;

        &:is(:hover, :focus) {
          background: transparent;
          color: $primary;
        }

        svg {
          width: 1.1rem;
          height: 1.1rem;
        }

        &.active {
          background: $danger;
          border-color: $danger;

          &:is(:hover, :focus) {
            background: transparent;
            color: $danger;

            svg {
              fill: $danger;
            }
          }

          svg {
            fill: $white;
          }
        }
      }
    }
  }

  .section-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1rem 0;

    >div {
      gap: 0.3rem;

      .back_btn {
        width: auto;
        height: auto;
        margin: 0;
        border: 0;
        flex: none;

        svg {
          width: 1.3rem;
          height: 1.3rem;
        }

        &:hover,
        &:focus {
          background-color: unset;
          color: $orange;
        }
      }

      .dropdown {
        .dropdown-toggle {
          gap: 0.1rem;
          padding: 0.5rem;
          font-size: 0.85rem;
          line-height: normal;

          svg {
            width: 0.8rem;
            height: 0.8rem;
          }
        }
      }
    }

    h3 {
      font-size: 1.7rem;
      margin: 0;
      color: $black;
      list-style: 1;
    }

    .dropdown {
      .btn {
        background-color: lighten($warning, 28%);
        color: $primary;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.3rem;
        padding: 0.4rem 0.5rem;
        font-size: 1rem;
        border: none;
        font-weight: 500;
        line-height: 1;

        &:hover,
        &:focus {
          transform: none;
        }
      }
    }
  }

  .support-list {
    .support-data {
      display: inline-flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      gap: 0.2rem;
      height: 100%;
      width: 100%;
      text-align: center;
      text-decoration: none;
      background-color: #d7e7ff;
      border-radius: 0.8rem;
      padding: 1.2rem;
      transition: all 0.3s ease;
      &:hover {
        box-shadow: 0px 0px 6px 1px #d2d2d2;
        h6 {
          color: $orange;
        }
        span,a {
          color: $orange;
        }
      }
      &:last-child {
        justify-content: space-evenly;
      }
      .support_img {
        width: 6rem;
        height: 6rem;
        border-radius: 100px;
        display: inline-block;
        overflow: hidden;
        margin-bottom: 0.6rem;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      > div {
        span.d-inline-flex, a  {
          font-size: 0.85rem !important;
          gap: 0.4rem;
          align-items: center;
          justify-content: center;
          text-decoration: none;
          font-weight: 500;
          transition: all 0.3s ease;
        }
      }
      h5 {
        margin-top: 1.3rem;
        margin-bottom: 0.5rem;
        font-size: 1.3rem;
        color: $primary;
        letter-spacing: 0.01rem;
        transition: all 0.3s ease;
      }

      p {
        line-height: normal;
        margin-bottom: 0.6rem;
      }

      span {
        font-size: 1.5rem;
        text-decoration: none;
        line-height: normal;
        font-weight: 500;
        transition: all 0.3s ease;
      }

      h6 {
        font-size: 1.2rem;
        margin-bottom: 0.5rem;
        display: block;
        line-height: 1;
        transition: all 0.3s ease;
      }

      .alternate_number {
        a {
          text-decoration: none;
        }
      }

      &.corporate {
        .support_img {
          width: 100%;
          height: 6.4rem;
          border-radius: 0;
          display: inline-flex;
          align-items: center;
          justify-content: center;

          img {
            width: 10rem;
            height: auto;
          }
        }
      }
    }
    > div:nth-last-child(2) .support-data .support_img {
      width: 100%;
      height: 6.4rem;
      border-radius: 0;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      img {
        width: 10rem;
        height: auto;
      }
    }
  }

  footer {
    .fix-footer {
      display: none;
    }
  }

  .news-letter-list {
    .product-card {
      .product-card-body {
        justify-content: normal;
      }

      .product-data {
        .cost-discount-box {
          max-height: 4.8rem;
        }

        p {
          white-space: initial;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }

        h4 {
          white-space: initial;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          font-size: 1rem;
          color: $primary;
          font-weight: 600;
        }

        .product-more-btn {
          bottom: 2%;
        }
      }

      .product_svg {
        height: 23vh;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 11.8%;
        background-color: rgb(221, 221, 221);
        text-align: center;

        svg {
          width: 5rem;
          height: 5rem;
        }
      }
    }
  }

  .rebate-tables {
    &:not(:last-of-type) {
      margin-bottom: 1rem;
    }
    h6 {
      margin-bottom: 0.8rem;
    }
  }
}

.newsletter_date {
  display: inline-flex;
  align-items: center;
  line-height: normal;
  gap: 0.5rem;
  font-weight: bold;
  color: $primary;

  span {
    display: inline-flex;
    align-items: center;
    gap: 0.2rem;
    font-weight: 500;
    font-size: 0.8rem;
    color: #777;

    svg {
      width: 0.9rem;
      color: $primary;
    }
  }
}

.newsletter_assigned {
  .card-header {
    padding: 0.8rem 0.8rem;
    background: #002e70;

    h6 {
      color: #fff;
    }
  }

  .card-body {
    padding: 1rem !important;

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      li {
        // display: flex;
        // flex-direction: column;
        padding-bottom: 0.3rem;

        &:not(:last-child) {
          border-bottom: 1px solid $gray-400;
        }
        span {
          &::after {
            content: ",";
            display: inline-block;
            padding: 0 0.2rem;
          }         
          &:last-of-type {
            &::after {
              display: none;
            }
          } 
        }        
        label {
          font-weight: bold;
          text-transform: capitalize;
          font-size: 0.8rem;
          line-height: normal;
          color: $primary;
          display: block;
        }
      }
    }
  }
}

.newsletter-detail {
  .img-box {

    // background-color: #e5edfa;    
    img {
      max-width: 100%;
      display: block;
      // margin: 0 auto;
      height: 100%;
      width: 100%;
    }
  }

  .card {
    padding: 0;
    margin-top: 0rem;
  }

  .card-body {
    padding: 2rem;

    h4 {
      font-size: 1.3rem;
      color: $primary;
      font-weight: 600;
      margin-bottom: 0.3rem;
    }

    p {
      margin-bottom: 0;
    }

    .card_date {
      display: inline-flex;
      gap: 0.5rem;
      align-items: center;
      margin-bottom: 0.7rem;
      color: rgba(54, 54, 54, 0.9);

      p {
        font-size: 0.9rem;
        line-height: normal;
      }
    }
  }

  .other-news-letter {
    position: sticky;
    top: 7rem;
    >h4 {
      font-size: 1.4rem;
      margin-bottom: 1rem;
      color: $primary;
      font-weight: 600;
    }
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      background-color: #efefef;
      padding: 0.6rem;
    }

    li {
      &:not(:last-of-type) {
        margin-bottom: .4rem;
      }
      a {
        text-decoration: none;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        background: $white;
        border-radius: 0.5rem;
        cursor: pointer;
        padding: 0.2rem;
        .img-box {
          max-width: 7rem;
          flex: none;
          display: inline-flex;
          .docfile {
            padding: 0.7rem;
          }
        }

        .docfile {
          svg {
            width: 6rem;
          }
        }

        .news-letter-content {
          flex: 1;

          h4 {
            font-size: 1rem;
            font-weight: 600;
            white-space: nowrap;
            overflow: hidden;
            max-width: 17.3vw;
            text-overflow: ellipsis;
            line-height: normal;
            margin-bottom: 0.2rem;
          }

          p {
            margin: 0;
            white-space: nowrap;
            overflow: hidden;
            max-width: 16vw;
            text-overflow: ellipsis;
            line-height: normal;
            font-size: 0.9rem;
          }
        }

        &:hover,
        &:focus {
          background-color: #effeff;
        }
      }
    }
  }
}

.total-amount {
  width: 100%;
  display: inline-block;
  font-weight: 700;
  color: $black;
  text-align: right;
  position: sticky;
  bottom: -1.1rem;
}

.support-request-modal {
  .modal-dialog {
    --bs-modal-width: calc(100vw - 58%);
  }
  .field_disabled{
    [class*="control"] {
        [class*="singleValue"] {
          color: hsl(0, 0%, 60%) !important;
        }
      }
  }
  .support-request-modal-list {
    .support-request-modal-data {
      background-color: #eeeeee;
      border-radius: 0.2rem;
      display: flex;
      gap: 0.6rem;
      align-items: center;
      margin-bottom: 1rem;
      padding: 0.4rem;

      .support-icon-box {
        width: 2.2rem;
        height: 2.2rem;
        flex: none;
        align-items: center;
        justify-content: center;
        display: flex;
        background-color: #ffffff;
        color: $primary;
        border-radius: 3rem;

        svg {
          width: 1.2rem;
          height: 1.2rem;
        }
      }
      p {
        font-size: 0.9rem;
        margin-bottom: 0;
        color: $primary;
        font-weight: 500;
      }
    }
  }
  .include-cc {
    background: #eeeeee;
    padding-top: 0.4rem;
    border-radius: 0.5rem;
    margin: 0.5rem 0 1rem;
  }
}

.front-dropdown {
  padding: 0 !important;
  min-width: auto !important;
  top: 1px !important;
  overflow: hidden;

  .dropdown-item {
    padding: 0.2rem 0.7rem;
    font-size: 0.9rem;
    color: $primary;

    &:not(:last-child) {
      border-bottom: 1px solid $gray-200;
    }
  }
}

// profilw page


.head_hraid {
  span {
    background: #D4F9FE;
    color: #367D8C;
    padding: 0.1rem 0.8rem;
    font-size: 1.2rem;
    font-weight: 500;
    border-radius: 6px;
    border: 1px dashed #367D8C;
  }
}

.profile_section {
  display: flex;
  gap: 1rem;
  position: relative;

  .tabs_section {
    width: 20%;
    background: #FFF2E9;
    height: calc(100vh - 15.2rem);
    border-radius: 10px;
    position: sticky;
    top: 10rem;
    z-index: 1;

    .tabs_head {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 0.3rem;
      padding: 1rem;
      text-align: center;

      >span {
        background: #B7DC4C;
        color: #fff;
        border: 1px solid #ffffff;
        font-size: 1.2rem;
        width: 2.5rem;
        height: 2.5rem;
        margin: 0;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0.5rem;
      }

      h3 {
        margin: 0;
        line-height: normal;
        color: #002E70;
        font-size: 1.4rem;
      }

      p {
        margin: 0;
        line-height: 1;
        font-size: 1.1rem;
        font-weight: 400;
        color: #7889A0;
      }

      .contact_person {
        background: #002e700f;
        width: 100%;
        padding: 0.3rem 0;
        display: inline-flex;
        flex-direction: column;
        color: #002e70;
        font-size: 1rem;
        font-weight: 500;

        span {
          font-size: 0.8rem;
          margin-bottom: 0.3rem;
          color: #9a9a9d;
          font-weight: 500;
        }
      }
    }

    .nav-tabs {
      display: flex;
      flex-direction: column;
      gap: 0.3rem;
      background: transparent;
      padding: 0 0.6rem;
      border: none;

      .nav-item {
        .nav-link {
          background: #ffffff33;
          color: #0000009c;
          border-radius: 6px;
          border: none;
          transition: all 0.2s ease-in;
          cursor: pointer;

          &.active {
            background: #ffffff;
            color: #000000;

            &:hover {
              background: #fff;
            }
          }

          &:hover {
            background: rgb(254 255 250);
          }
        }
      }
    }
  }

  .content_section {
    width: 80%;

    .tab-content {
      height: 100%;

      .tab-pane {
        height: 100%;

        .tab-card {
          padding: 1rem;
          background: #eaf2ff;
          // border: 1px solid #0000003b;
          border-radius: 10px;
        }

        .tab_content_head {
          display: flex;
          justify-content: space-between;
          margin-bottom: 1rem;

          >div {
            display: flex;
            align-items: center;

            .back_btn {
              display: none;
            }
          }

          .btn {
            gap: 0.3rem;
            display: flex;
            align-items: center;

            svg {
              width: 0.9rem;
              height: 0.9rem;
            }

            &.btn-secondary {
              background: #002e701c;
              color: #002e70;
              font-weight: 500;
            }
          }

          h4 {
            color: #8FC000;
            margin: 0;
          }
        }
      }
    }
  }

  .secondary-form-check {
    display: flex;
    gap: 0.6rem;
    align-items: center;
  }
}

.hra-light-form-box {
  background: $white;
  border-radius: 5px;
  padding: 0.5rem 0.7rem;
  margin: 0px -0.2rem 0.6rem;
  min-height: calc(100% - 0.6rem);
  .form-group {
    input {
      height: 2.5rem;
      // &:focus,
      // &:-webkit-autofill,
      // &:not(:placeholder-shown) {
      //   -webkit-box-shadow: none !important;
      // }
      &::placeholder{
        color: #000 !important;
        opacity: 0.3;
      }
    }
  }

  .form-label {
    top: 0.5rem !important;
    left: 0.7rem !important;
  }

  label {
    display: block;
    color: lighten($gray-600, 20%);
    font-size: 0.8rem;
    margin-bottom: 0.5rem;
    line-height: normal;
    white-space: nowrap;
    font-weight: 500;
    text-transform: capitalize;
  }

  span {
    // display: inline-block;
    margin: 0;
    color: $primary;
    font-size: 1rem;
    font-weight: 500;
  }
}

.profile-field-list-dark {
  background-color: lighten($primary, 69%);
  border-radius: 5px;
  padding: 0.4rem 0.2rem;

  h5 {
    color: $primary;
    margin-bottom: 0.4rem;
    margin-top: 0.3rem;
    font-size: 1rem;
  }

  .hra-light-form-box {
    background-color: lighten($primary, 75%);

    .form-control {
      background-color: transparent;
    }

    label {
      background-color: lighten($primary, 75%) !important;
    }
  }
}

.profile-store-field-list {
  background-color: lighten($gray-200, 20%);
  border-radius: 5px;
  padding: 0.4rem 0.2rem;

  &:not(:last-child) {
    margin-bottom: 1rem !important;
  }

  h5 {
    color: $primary;
    margin-bottom: 0.4rem;
    margin-top: 0.3rem;
    font-size: 1.1rem;
  }

  .profile-store-field-head {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .store-hraid {
      background: lighten($danger, 50%);
      color: $danger;
      padding: 0.1rem 0.6rem;
      font-size: 0.8rem;
      font-weight: 500;
      border-radius: 6px;
    }
  }

  .profile-store-data {
    background-color: #f2f2f2;
    border-radius: 0.2rem;
    display: flex;
    gap: 0.6rem;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    padding: 0.4rem;

    >div {
      display: flex;
      gap: 0.6rem;
      align-items: center;

      .profile-store-icon-box {
        width: 1.8rem;
        height: 1.8rem;
        flex: none;
        align-items: center;
        justify-content: center;
        display: flex;
        background-color: #ffffff;
        color: $primary;
        border-radius: 3rem;

        svg {
          width: 1rem;
          height: 1rem;
        }
      }

      p {
        font-size: 0.9rem;
        margin-bottom: 0;
        color: $primary;
        font-weight: 500;
        overflow-wrap: anywhere;
        line-height: normal;
      }
    }

    .badge {
      display: inline-block;
      background: lighten($primary, 67%);
      color: $primary;
      padding: 0.2rem 0.3rem;
      font-size: 0.7rem;
      font-weight: 500;
      border-radius: 6px;

      &.badge-info {
        background: lighten($info, 53%);
        color: $info;
      }

      &.badge-warning {
        background: lighten($warning, 38%);
        color: $warning;
      }
    }
  }
}

.promotion-detail-box {
  .promotion-detail-data {
    .img-box {
      background-color: #e5edfa;

      img {
        // max-width: calc(100vw - 44rem);     
        max-width: 100%;
        display: block;
        margin: 0 auto;
        height: 100%;
        width: 100%;
      }
    }

    .like-expire-box {
      display: flex;
      justify-content: space-between;
      margin: 1rem 0;

      .product-like-box {
        text-decoration: none;
        font-size: 1.2rem;
        color: #000;
        background: transparent;
        border-color: transparent;
        padding: 0;
        display: flex;
        align-items: end;
        gap: 0.1rem;
        line-height: normal;
        font-weight: 500;

        svg {
          width: 1.5rem;
          height: 1.3rem;
        }
      }

      .promotion-expire-date {
        span {
          color: #000;
        }
      }

      &:is(:hover, :focus) {
        color: $danger;

        .product-like-box {
          color: $danger;
        }

        svg {
          fill: $danger;
          color: $danger;
        }
      }
    }

    .promotion-expire-date {
      margin: 0;
      font-size: 0.9rem;
      color: #767676;
      font-weight: 500;
    }

    .promotion-contact {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #ffedc8;
      padding: 0.3rem 0.7rem;
      border-radius: 0.3rem;
      margin-bottom: 0.6rem;

      p {
        margin: 0;
      }
    }

    .requirements-list {
      h4 {
        color: #002e70;
        font-size: 1.5rem;
      }

      ul {
        padding: 0;
        margin: 0;
        list-style: none;

        // li {
        //   padding-left: 0.9rem;
        //   position: relative;
        //   &::before {
        //     content: "";
        //     width: 0.3rem;
        //     height: 0.3rem;
        //     background-color: #000;
        //     border-radius: 1rem;
        //     display: block;
        //     position: absolute;
        //     top: 0.7rem;
        //     left: 0;
        //   }
        // }
        li {
          &:not(:last-child) {
            margin-bottom: 0.4rem;
          }

          p {
            font-size: 0.9rem;
            color: #535353;
            line-height: 1.4;
            margin: 0;

            span {
              font-size: 0.9rem;
              margin-right: 0.2rem;
              display: inline-block;
              color: #002E70;
              font-weight: 500;
              padding-right: 0.3rem;
            }
          }
        }
      }
    }

    .btn-box {
      text-align: center;
      margin-top: 1rem;

      .btn-primary {
        display: inline-flex;
        align-items: center;
        gap: 0.3rem;

        svg {
          width: 1.1rem;
          height: 1.5rem;
        }
      }
    }
  }

  .promotion-detail-images {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 0.7rem;
    padding: 0;
    margin: 0;
    list-style: none;
    margin-top: 1rem;
    li{
      background: #f1f1f1;
      border-radius: 10px;
      padding: 0.5rem;
    }
    img {
      width: 100%;
      height: 100%;
      cursor: pointer;
      transition: 0.3s all ease-in-out;
      object-fit: contain;

      &:hover {
        transform: scale(0.95);
      }
    }
  }
}

//---------  html-page  ---------------

.front_main{
  .vendor_site_details,.vender_card_list,.vender_card_list_tab{
    .container{
      max-width: 1200px !important;
      margin: 0 auto !important;
    }
  }
}
.vender_card_list_tab{
  background: #002e702b;
  padding-top: 3rem;
  padding-bottom: 3rem;
  h1{
    font-size: 2rem;
    text-align: center;
  }
  ul{
    display: flex;
    flex-wrap: wrap;
    gap: 0.7rem;
    margin: 0;
    padding: 1rem 0;
    padding-bottom: 0.5rem;
    list-style-type: none;
    justify-content: center;
    max-width: 100%;
    width: 100%;
    &::-webkit-scrollbar {
      height: 7px;
      background: rgba(19, 47, 78, 0.3);
    }
    
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px #5e72e41a;
      -webkit-border-radius: 10px;
      border-radius: 10px;
    }
    
    &::-webkit-scrollbar-thumb {
      -webkit-border-radius: 10px;
      border-radius: 10px;
      background: #002E70;
    }
    
    &::-webkit-scrollbar-thumb:window-inactive {
      background: darken($primary, 20%);
    }
    li{
      .btn{
        padding: 0.3rem 0.6rem;
        line-height: normal;
        font-size: 0.9rem;
      }
      &.active{
        .btn{
          background: #b7dc4c;
          border-color: #b7dc4c;
          color: $primary;
          font-weight: 500;
        }
      }
    }
    
  }
}
.vender_card_list {
  .vendor_cat_title{
    background: #000;
    color: #fff;
    padding: 1.8rem 1rem;
    position: relative;
    margin: 2rem 0;
    h2{
      text-align: center;
      font-size: 2.2rem;
    }
    .logo_small{
      position: absolute;
      top: 50%;
      left: 1rem;
      transform: translate(0 , -50%);
      width: 12vw;
      max-width: 100%;
    }
  }
  .card {
    background: #002e701c;
    border: none;
    padding: 0.7rem 0.7rem 0.5rem;
    border-radius: 15px;
    transition: all 0.3s ease-in-out;
    border: 1px solid transparent;
    height: calc(100% - 1.8rem);
    &:hover{
      background: #002e702b;
      border: 1px solid #002e7045;
    }
    .logo_vendor{
      border-radius: 7px;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 5rem;
      img{
        width: 100%;
        height: 9rem;
        max-width: 100%;
        object-fit: contain;
      }
      border-radius: 7px;
    }
    .card-body{
      padding: 1rem 0.2rem;
      padding-bottom: 0.3rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 0.5rem;
      h3 {
        text-align: center;
        font-size: 1.3rem;
        font-weight: 600;
        margin: 0;
        color: #002e70;
      }
      a {
        background: #d0e2ba;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 0.3rem;
        padding: 0.3rem 0.4rem;
        font-weight: 500;
        text-decoration: none;
        transition: all 0.3s ease-in-out;
        &.btn-success {
          background: $primary;
          border-color: $primary;
          color: $white;
        }
        svg {
          height: 0.9rem;
          width: 0.9rem;
        }
        span {
          background: #fff;
          border-radius: 100px;
          width: 1.8rem;
          height: 1.8rem;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #fff;
          padding: 0.2rem;
          border-radius: 100px;
          transform: rotate(140deg);
          transition: all 0.3s ease-in-out;
          svg{
            width: 1rem;
            height: 1rem;
          }
        }
        &:hover{
          background: #c3d6ac;
          span{
            transform: rotate(180deg);
          }
        }
      }
      > span {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.3rem;
        color: $primary;
        font-size: 0.9rem;
        svg {
          width: 0.9rem;
          height: 0.9rem;
        }
      }
    }
  }
}
.vendor_site_details{
  margin-bottom: -1.5rem;
  .html_hero{
    position: fixed;
    top: 7vw;
    z-index: -1;
    height: 100vh;
    width: 100%;
    img{
      max-height: 100%;
      object-fit: cover;
      max-width: 100%;
      height: 100%;
    }
  }
  .html_hero_logos{
    margin-top: 28vw;
    background: #ffffff3a;
    backdrop-filter: blur(6px);
    padding: 1.5rem 0;
    position: relative;
    .back-button{
      position: absolute;
      left: 5vw;
      top: 50%;
      transform: translateY(-50%);
      background: #ffffff4f;
      border-radius: 100px;
      width: 3rem;
      height: 3rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #002e70;
      transition: all 0.3s ease-in-out;
      &:is(:hover,:focus){
        background: #002e70;
        color: #fff;
      }
    }
    .html_main_head{
      display: flex;
      justify-content: end;
      h1{
        background: linear-gradient(90deg, rgba(0, 47, 112, 0.815) 0%, rgba(0, 47, 112, 0.651) 50%, rgba(255, 255, 255, 0) 100%);
        padding: 0.6rem 2.9rem 0.6rem 1rem;
        color: #fff;
        border-left: 10px solid #002e70;
      }
    }
     .row{
      display: flex;
      align-items: center;
    }
    img{
      max-width: 100%;
      width: 11rem;
    }
  }
  .html_textarea{
    background: #000;
    .product_text{
      padding: 1.5rem 8rem 3rem 8rem;
      color: #fff;
      p{
        // background: transparent !important;
          // color: #fff !important;
        span{
          // background: transparent !important;
          // color: #fff !important;
        }
        a{
          // color: #7bb1ff !important;
          transition: all 0.3s ease-in-out;
          &:hover{
            // color: #4782d4 !important;
          }
        }
      }
    }
  }
  .html_lightbox{
    background: #fff;
    .promotion-detail-box{
      padding: 3rem 5rem;
      .promotion-detail-images{
        gap: 1.2rem;
        grid-template-columns: repeat(4, 1fr);
        li{
          background: #b4c9e73d;
          padding: 0.9rem;
          border-radius: 8px;
          &:hover{
            background: #b4c9e762;
          }
        }
      }
    }
  }
  .like_btn{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 2rem;
    .btn{
      transition: all 0.3s ease-in-out;
      position: relative;
      &.btn-success {
        background: #83b925;
      }
      svg{
        width: 1.4rem;
        height: 1.4rem;
      }
    }
  }
  .html_footer{
    background: #000;
    padding: 1.3rem 0;
    > div{
      background: #000;
      border-top:1px solid #fff;
      border-bottom:1px solid #fff;
      img{
        max-width: 100%;
      }
      .btn-link{
        color: #fff;
        text-decoration: none;
        padding: 0;
        padding-right: 1rem;
        padding-left: 1rem;
        font-size: 0.9rem;
        transition: all 0.3s ease-in-out;
        &:hover{
          color: #6ba3ff;
        }
      }
      ul{
        list-style-type: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        li{
          p{
            margin: 0;
            color: #fff;
            font-size: 0.9rem;
          }
          .btn{
            padding-left: 0;
            transition: all 0.3s ease-in-out;
            &:last-child{
              padding-right: 1rem;
            }
            &:hover{
              color: #6ba3ff;
            }
          }
          &:first-child{
            width: 100%;
          }
        }
      }
    }
  }
  
}
.agreement_container {
  .agreement_head {
    display: flex;
    gap: 1rem;

    .hra-light-form-box {
      background: #f4f4f4;
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
  }
}

.view_btn {
  .badge {
    font-size: 0.9rem;
    font-weight: 500;

    svg {
      width: 1.2rem;
      height: 1.2rem;
      margin-right: 0.2rem;
    }

    &.approved_badge {
      color: #18AB15;
      background-color: rgba(#18AB15, 0.2);
    }

    &.rejected_badge {
      color: $danger;
      background-color: rgba($danger, 0.2);

      svg {
        width: 0.8rem;
      }
    }

    &.pendding_badge {
      color: $warning;
      background-color: rgba($warning, 0.2);
    }
  }

  .small_btn {
    border-radius: 50px;
    margin-left: 1rem;
    width: 2.5rem;
    height: 2.5rem;
    padding: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
}

.ach_details {
  background: #F4F5F6;
  padding: 0.5rem 1rem;
  border-radius: 10px;

  h3 {
    font-size: 1.2rem;
  }

  .account_name {
    font-size: 1rem;
  }

  .hra-light-form-box {
    label {
      color: #002e70;
    }

    span {
      color: #373737;
    }
  }

  .delete_document {
    background: #fff;
    border-radius: 50px;
    color: $danger;
    border: none;
    width: 2.5rem;
    height: 2.5rem;
    padding: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;

  }

  .ach_document {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 1rem;

    img {
      max-width: 100%;
    }

    .dropzone {
      width: 100%;
    }

    .dropzone-title {
      text-align: center;
    }
  }
  &.view-ach-details {
    .hra-light-form-box {
      img {
        width: 100%;
      }
    }
  }
}

.ach_action {
  .view_btn_icon {
    padding: 0;
    border: none;
    color: $success;
    svg {
      max-height: 100%;
    }
    &:is(:hover, :focus) {
      background: none !important;
      color: darken($success, 0.5);
    }
  }

  .btn-primary {
    width: 1.7rem;
    height: 1.7rem;
    border-radius: 50px;
    padding: 0;
    color: #fff;
    border: none;
    margin: 0.2rem 0;
    margin-left: 0.7rem;
  }

  .approved_btn {
    background: $success;

    svg {
      width: 1rem;
      height: 1rem;
    }

    &:is(:hover, :focus) {
      background-color: darken($success, 0.7);
      color: #fff;
    }
  }

  .reject_btn {
    background: $danger;

    svg {
      width: 0.7rem;
      height: 0.7rem;
    }

    &:is(:hover, :focus) {
      background-color: darken($danger, 0.7);
      color: #fff;
    }
  }

  .badge {
    background: none;
    margin-left: 0.7rem;
    font-weight: 600;

    &.confirmed_badge {
      color: #18AB15;
      padding: 0.55rem 0;
      display: flex;
      align-items: center;
      gap: 0.2rem;
      margin: 0;
      svg {
        width: 1.1rem;
      }
    }

    &.reject_badge {
      color: $danger;
      padding: 0.05rem 0;

      svg {
        width: 0.8rem;
        margin-right: 0.5rem;
        margin-left: 0.2rem;
      }
    }
  }
}

.modal {
  .ach_modal_header {
    span {
      font-size: 1rem;
      font-weight: 400;
      border: 1px dashed #fff;
      border-radius: 4px;
      padding: 0.2rem 0.5rem;
      margin-left: 0.7rem;
      background: #ffffff26;
      display: flex;
      align-items: center;
    }
  }

  .modal-body {
    .ach_details_body {
      margin-top: 1rem;
      // margin-bottom: 1rem;
      ul {
        margin: 0;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        gap: 0.8rem;

        li {
          width: calc(50% - 0.5rem);
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 0.4rem !important;
          margin-bottom: 0;
          background-color: #f5f5f5 !important;
          gap: 0.2rem;

          // &:last-child{
          //   width: 100%;
          // }
          label {
            color: #002e70;
          }
        }
      }

      .ach_check_img {
        padding: 0.7rem 0.2rem;

        h3 {
          font-size: 1.2rem;
          color: #002e70;
        }

        img {
          max-width: 65%;
        }
      }
    }

    .current {
      h4 {
        display: none;
      }
    }

    .Previous {
      .ach-modal_content {
        display: flex;
        gap: 1.2rem;

        .ach_details_body {
          margin-top: 0.5rem;
          padding: 0.5rem;
          width: calc(50% - 0.6rem);

          &:first-child {
            background: #FFDADA;
          }

          &:last-child {
            background: #D9F6C2;
          }

          h4 {
            margin-bottom: 0.2rem;
            font-size: 1.1rem;
            font-weight: 600;
            color: $primary;
          }

          ul {
            li {
              width: 100%;
              gap: 0;
              padding: 0 0.4rem !important;
              background-color: rgba($white, 0.55) !important;
            }
          }

          .ach_check_img {
            img {
              max-width: 100%;
            }
          }
        }

      }
    }

    .ach_action {
      .btn-primary {
        width: auto;
        height: auto;
        border-radius: 50px;
        padding: 0.2rem 0.6rem;
        color: #fff;
        border: none;
        margin: 0.2rem 0;
        margin-left: 0.7rem;

        svg {
          margin-right: 0.2rem;
        }
      }

      .approved_btn {
        background: $success;

        svg {
          width: 1rem;
          height: 1rem;
        }

        &:is(:hover, :focus) {
          background-color: darken($success, 0.7);
          color: #fff;
        }
      }

      .reject_btn {
        background: $danger;

        svg {
          width: 0.7rem;
          height: 0.7rem;
        }

        &:is(:hover, :focus) {
          background-color: darken($danger, 0.7);
          color: #fff;
        }
      }

      .badge {
        margin-left: 0;
        padding: 0.3rem 0.4rem !important;
        line-height: normal;

        &.confirmed_badge {
          color: #18AB15;
          background: rgba(#18AB15, 0.15);
          padding: 0.55rem 0;

          svg {
            width: 1.2rem;
            height: auto;
            margin-right: 0.2rem;
          }
        }

        &.reject_badge {
          color: $danger;
          background: rgba($danger, 0.15);
          padding: 0.05rem 0;

          svg {
            width: 0.8rem;
            height: auto;
            margin-right: 0.5rem;
            margin-left: 0.2rem;
          }
        }
      }
    }
    .export-data {
      display: flex;
      flex-direction: column;
      align-items: center;
      h6 {
        font-size: 1rem;
      }
      .export-options {
        display: flex;
        gap: 0.5rem;
        .form-check {
          width: 8rem;
          margin-bottom: 1rem;
          padding: 0;
          position: relative;
          .form-check-input {
            position: absolute;
            left: 0;
            top: 0;
            margin: 0;
            opacity: 0;
            &:checked[type=radio] {
              ~ .form-check-label {
                background: $primary;
                color: $white;
              }
            }
          }
          .form-check-label {
            display: inline-flex;
            flex-direction: column;
            gap: 0.5rem;
            align-self: center;
            justify-content: center;
            align-items: center;
            font-size: 0.8rem;
            line-height: normal;
            text-align: center;
            padding: 0.8rem 0.5rem;
            color: $primary;
            border-radius: 0.6rem;
            border: 1px solid rgba($primary, 0.15);
            background: rgba($primary, 0.10);
            &:is(:hover, :focus) {
              cursor: pointer;
              background: rgba($primary, 0.3);
            }
            svg {
              width: 1.8rem;
              height: 1.8rem;
            }
          }
        }
      }
    }
  }
}

.agree_dropdown {
  background: #002e7021;
  border-radius: 7px;
  cursor: pointer;

  &.active {
    >.dropdown-item {
      background: $primary !important;
      color: $white !important;

      &:is(:focus, :hover) {
        color: #fff !important;
      }
    }

    .arrow {
      color: #fff;
      transform: rotate(180deg);
    }
  }

  .arrow {
    margin-left: auto;
    color: #838383;
    transition: all 0.3s ease;

    svg {
      width: 0.8rem !important;
      height: 0.8rem !important;
    }
  }

  ul {
    margin: 0;
    padding: 0.5rem;
    list-style-type: none;
    display: inline-flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;

    li {
      display: inline-block;
      width: 100%;

      .dropdown-item {
        display: inline-block;
        float: left;
        width: 100%;
        font-size: 0.85rem;
        padding: 0.3rem 0.4rem !important;

        >span {
          display: none;
        }
      }
    }
  }
}

/*---------------------------------- light-box -----------------------------------*/

.yarl__container {
  background: #000000d1 !important;
}

.yarl__button {
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  border-radius: 50px;
  padding: 0.2rem !important;
  transition: 0.3s all ease-in;

  &.yarl__navigation_prev,
  &.yarl__navigation_next {
    background-color: #8BC34A;

    &:hover {
      background-color: #679632;
    }
  }

  &.yarl__navigation_prev {
    left: 2%;
  }

  &.yarl__navigation_next {
    right: 2%;
  }

  svg {
    width: 3rem;
    height: 3rem;
    display: block;
    padding: 0;
  }
}


.no-content-img {
  min-height: calc(100vh - 10rem);
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    img {
      max-width: 100%;
    }
  }
}

.member_form_btn {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  margin-top: 0.6rem;

  .btn {
    background-color: #002e702b !important;
    color: $primary !important;
    border: none !important;
    padding: 0.4rem 0.5rem !important;

    &:is(:hover, :focus) {
      background: $primary !important;
      color: $white !important;
    }
  }
}

.agreemet_pdf_container {
  position: relative;

  .agreement_loader {
    position: absolute;
    top: 50%;
    z-index: 9;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
    background: #00000017;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.download_button {
  display: block;
  background: #002e70;
  padding: 0.2rem 0.5rem;
  color: #fff;
  position: absolute;
  right: 0.8rem;
}


.slider-client-logo {
  background-color: #f4f5ff;
  margin-top: 2rem;
  border-radius: 0.5rem;
  padding: 1rem;

  h4 {
    color: #8FC000;
    margin-bottom: 1rem;
  }

  .slick-list {
    img {
      margin: 0 auto;
    }
  }

  .slick-arrow {

    &.slick-prev,
    &.slick-next {
      width: 2.8rem;
      height: 2.8rem;
      background: $primary;
      position: absolute;
      z-index: 10;
      border-radius: 2rem;
      transition: all 0.4s ease;
      display: none;

      &::before {
        content: "" !important;
        background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="15" height="26" viewBox="0 0 15 26" fill="none"><path d="M1.05099 25L13.1562 12.8947L1.05099 0.789474" stroke="white" stroke-width="2"/></svg>');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 0.7rem;
        height: 1.1rem;
        margin: 0 auto;
        transition: all 0.4s ease;
      }

      &:hover {
        background-color: rgb(255 180 177);
      }
    }

    &.slick-prev {
      left: 0%;

      &::before {
        transform: rotate(180deg);
        margin-left: 0.8rem;
      }
    }

    &.slick-next {
      right: 0%;

      &::before {
        margin-left: 1rem;
      }
    }
  }
}


.add-newsletter {
  .dropzone.dropzone_img {
    ul {
      grid-template-columns: repeat(7, 1fr);
    }
  }

  .attachment-data {
    padding: 0;
    width: 100%;
    margin: 0;
    border-radius: 10px;
    overflow: hidden;
    background: #f2f2f2;
    position: relative;

    .attachment-head {
      .file-box {
        padding: 0.5rem;
        width: 100%;
        display: inline-block;
        text-align: center;

        img {
          max-width: 100%;
          max-height: 15rem;
        }
      }

      .remove-box {
        top: 1.3rem;
        transform: translate(0, -50%);
        color: #ec3838;
        right: 0.6rem;
        background: #aaa;
        padding: 0.3rem;
        border-radius: 100px;
        display: flex;
        justify-content: center;
        position: absolute;

        &:is(:hover, :focus) {
          cursor: pointer;
          background: #ec3838;
          color: $white;
        }
      }
    }

    .docfile {
      ~.remove-box {
        position: absolute;
        right: 0.5rem;
        top: 1.3rem;
        z-index: 1;

        &:is(:hover, :focus) {
          cursor: pointer;
          color: $danger;
        }
      }
    }

    h6 {
      max-width: 20rem;
    }
  }

  .nav-tabs {
    background: rgba($primary, 0.3);
    display: inline-flex;
    align-items: center;
    flex-direction: row;
    border-radius: 0.5rem;
    padding: 0.15rem 0.2rem;

    .nav-item {
      border: none;
      display: inline-block;

      span {
        text-decoration: none;
        padding: 0.25rem 0.6rem;
        line-height: normal;
        display: inline-block;
        border-radius: 0.25rem;
        font-size: 0.9rem;
        text-align: center;
        transition: all 0.3s ease;

        &:is(:hover, :focus) {
          background: rgba($primary, 0.35);
          transition: all 0.3s ease;
          cursor: pointer;
        }

        &.active {
          background: $primary;
          color: $white;
        }
      }
    }
  }
}

.table-img-box {
  max-height: 100%;
  max-width: 100%;
  overflow: hidden;
  width: 5rem;

  img {
    border-radius: 0.3rem;
    height: 100%;
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
  }
}

.newsletter-table {
  td {
    p {
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      line-height: 1.4;
      overflow: hidden;
      white-space: pre-wrap;
    }

    b {
      display: block;
      color: #212529;
      margin-bottom: .2rem;
    }
  }
}

.docfile {
  display: inline-block;
  padding: 0.7rem 2rem 0.7rem 0.7rem;
  background-color: rgba(0, 188, 212, 0.1607843137);
  border-radius: 0.5rem;
  overflow: hidden;
  white-space: pre;
  text-overflow: ellipsis;
  svg {
    width: 2.5rem;
    height: 2.5rem;
  }
}


.vendor_site_back_btn {
  position: absolute;
  top: 8.4vw;
  left: 5.4vw;
  background: rgb(255 255 255 / 61%);
  border-radius: 100px;
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #002e70;
  transition: all 0.3s ease-in-out; 
  .back_btn {
    svg {
      width: 1.8rem !important;
      height: 1.8rem !important;
    }
  }
  &.pdf_back{
    position: unset;
    margin-left: 1rem;
    margin-top: 0.5rem;
    width: 2.7rem;
    height: 2.7rem;
    .btn{
      padding: 0.1rem;
    }
  }
}

.vender_section_head {
  background-color: rgba(0, 46, 112, 0.168627451);
  padding: 1rem 0 !important;
  margin: 0 !important;
}

// ============= dashboard-customize============

.add-image{
  display: flex;
  gap: 1rem;
}
.add-image {
  .dropzone {
    height: 100%;
  }
}
 .dashboard-drag-img{
  position: absolute;
  z-index: 1;
  padding: 0;
  inset: 0.2rem 0.2rem 0.2rem 0.2rem;
  background-color: #e7e7e7;
  border-radius: 0.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  .dashboard-img-inner{
    max-width: 100%;
    max-height: 100%;

  }
 }

.status-switch {
  display: flex;
  align-items: center;
  gap: 0.4rem;
  label {
    font-size: 1rem;
    color: $gray-700;
  }
  .react-switch-bg {
    height: 1.1rem !important;
    width: 2.9rem !important;
  }
  .react-switch-handle {
    height: 16px !important;
    width: 16px !important;
  }
}


  .dashboard-model-button{
    display: flex;
    justify-content: end;    
    gap: 0.7rem;
  }
 .dashboard-image {
  height: 4.5rem;
  aspect-ratio: 1 / 1;
  max-width: 5rem;
  border-radius: 0.6rem;
  display: flex;
  place-items: center;
  .dashboard-image-inner{
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }
 }
 .dashboard-main-inner {
  position: relative;
  height: 100%;
  .dashboard-deactive{
    position: absolute;
    bottom: 0.5rem;
    right: 0.6rem;
    font-size: 0.6rem;
    font-weight: 600;
  }
  &:is(:hover, :focus) {
    .dashboard-inner-part {
      background: rgba($primary, 0.3);;
      transition: all 0.3s ease;
      color: $primary;
    }
    
    .edit-delete-icon {
      display: inline-flex;
      .view_more {
        &:is(:hover, :focus) {
          background: #fff !important;
        }
        .edit-hover-icon {
          color: #198754;
          visibility:visible;
        }
        .delete-hover-icon {
          color: #b51c1c;
          visibility:visible;
        }
      }
    }
  }
}



  .dashboard-inner-part{
    background-color: #fff;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    text-decoration: none;
    overflow: hidden;
    position: relative;
    padding: 0.5rem;
    height: 100%;
  }
  .edit-delete-icon{
    position: absolute;
    top: 0.6rem;
    right: 1rem;
    display: flex;
    gap: 0.6rem;
    display: none;
    .edit-hover-icon{
      background-color: #b7f847c9;
      border-radius: 0.4rem;
      padding: 0.2rem;
      display: flex;
      border: none;
      color: #026e3c;
    }
    // svg{
    //   width: 50%;
    // }
    .delete-hover-icon{
      background-color: rgb(237 137 137 / 86%);
      border-radius: 0.4rem;
      padding: 0.2rem;
      display: flex;
    }
  }
  // .dashboard-deactive{
  //   position: absolute;
  //   bottom: 0.5rem;
  //   right: 0.5rem;
  // }
 


@keyframes pulse-process {
  0% {
    box-shadow: 0 0 0 0px rgba($danger, 0.3);
  }

  100% {
    box-shadow: 0 0 0 0.4rem rgba($danger, 0);
  }
}
@keyframes ripple{
  0% { transform: scale(1); }
  50% { transform: scale(1.3); opacity:1; }
  100% { transform: scale(1.6); opacity:0; }
}


@import 'responsive';


.front_main {
  #signature-pad { 
    position: relative;
    display: block; 
    .saved-signature {
      position: relative;
      display: block;
      
      @media only screen and (max-width: 575px) {
        width: 100%;
       }
      canvas,
      .signature-image {        
        border: 1px solid $gray-300;
        border-radius: 10px;
        padding: 0;
        // height: 12rem;
        // width: 100%;
        max-width: 100%;
      
      }
      button {
        transform: unset;
        top: 0.7rem;
        left: 1rem;
        position: absolute;
        width: 1.4rem;
        height: 1.4rem;
        padding: 0;
        border: none;
        background-color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid transparent;
        box-shadow: 0px 0px 10px rgb(130 130 130 / 37%);
        &:hover {
          border-color: #012c6c;
        }
        svg {
          width: 1.2rem;
          height: 1.2rem;
        }
      }
    }
   
  }
  .card {
    background-color: #002f701a;
    border: none;
    .card-header {
      background-color: #012c6c;
      h3 {
        font-size: 1.2rem;
        margin-bottom: 0;
        color: $white;
      }
      button.btn-primary {
        background-color: hsl(216deg 38.83% 40.39%);
        transform: unset;
        display: flex;
        align-items: center;
        gap: 0.3rem;
      }
    }
    .card-body {
      .ach_document {       
        img {
          width: 100%;
          height: auto;
          object-fit: cover;
          border: 1px solid $gray-300;
          padding: 0.4rem;
          border-radius: 10px;
        }
      }
      .form-login {
        .account-name {
          display: flex;
          align-items: center;
          gap: 1rem;
          @media only screen and (max-width: 1439px) {
            flex-direction: column;
            height: auto;
            align-items: flex-start;
          }
          .form-label {
            position: relative;
            margin: 0;
            top: unset;
            left: unset;
            font-weight: 600;
          }
        }
        .form-control:not(:placeholder-shown) ~ .form-label {
          background: linear-gradient(to top, #fafafa, #edefff00);
          font-weight: 500;
          color: #002E70 !important;
        }
        .form-group {
          margin-bottom: 0.8rem;
        }
      }
    }
  }
}