/* ==============
  Form-elements
===================*/

label {
  font-weight: 500;
}
.form-group {
  position: relative;
}
.form-control {
  font-size: $base-font;
  display: inline-block;
  width: 100%;
  padding: 0.5rem 0.75rem;
  font-weight: 400;
  color: #212121;
  height: 3rem;
  background-color: $white;
  background-clip: padding-box;
  border: 1px solid #ccc;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0.5rem;
  transition: box-shadow .15s ease,border-color .15s ease;
  outline: 0;
  &:focus {
    border-color: $primary;
    box-shadow: none;
    border-color: $primary !important;
  }
  &::placeholder {
    color: transparent !important;
  }
  &:focus, &:-webkit-autofill, &:not(:placeholder-shown) {
    
    -webkit-box-shadow: 0 0 0px 1000px #fafafa inset !important;
    // box-shadow: 0 3px 9px #32320900, 3px 4px 8px #5e72e41a;
    ~ .form-label {
      position: absolute;
      top: calc(0% - 0.4rem) !important;
      display: block;
      transition: 0.2s;
      font-size: .9rem;
      padding: 0 0.4rem;
      background: #fff;
      color: $primary !important;
      pointer-events:none;
      line-height: 1;
    }
  }
  &.dashboard-date {
    &:not(:placeholder-shown) {
      border-color: #d2d6da;
      -webkit-box-shadow: none;
    }
  }
  &.custom-select {
    &:not(:placeholder-shown) {
      border-color: #d2d6da;
      -webkit-box-shadow: 0 0 0px 1000px #fafafa inset;
    }
  }
  &:placeholder-shown ~ .form-label {
    cursor: text;
    top: 0.8rem;
    background: #fff;
    margin: 0;
    left: 1.3rem;
    color: #808080;
    font-weight: 400;
    padding: 0 0.4rem;
    pointer-events:none;
    font-size: 0.9rem;
    text-transform: capitalize;
  }
  &.selectbox {
    border: 0;
    // height: 100%;
    padding: 0;
    ~ .form-label {
      position: absolute;
      top: 0.8rem !important;
      left: 1.6rem;
      display: block;
      transition: 0.2s;
      font-weight: 400;
      color: #383739;
      pointer-events:none;
    }
    [class*="control"] {
      box-shadow: none;
      min-height: 3rem;
      max-height: 3.9rem;
      border-radius: 0.5rem;
      >div{
        max-height: 3.7rem;
        overflow-y: auto;
      }
      [class*="ValueContainer"] {
        
        [class*="multiValue"] {
          &:first-child {
            margin-top: 0.7rem;
          }
          margin-top: 0.7rem;
        }
      }
    }
    [class*="control"]:hover, [class*="control"]:focus {
      border-color: $primary !important;
      box-shadow: none !important;
    }
    [class*="menu"] {
      z-index: 999;
    }
    &:focus, &:-webkit-autofill, &:not(:placeholder-shown) {
      ~ .form-label {
        position: absolute;
        top: calc(0% - 0.4rem) !important;
        display: block;
        transition: 0.2s;
        font-size: 0.9rem;
        padding: 0 0.3rem;
        background: #fff;
        color: $primary;
        pointer-events:none;
      }
    }
    &.report-dropdown{
      [class*="control"] {
        align-items: baseline;
        padding-top: 0.6rem;
      }
    }
  }
}
.react-datepicker-wrapper {
  &:not(:placeholder-shown) {
    ~ .form-label {
      position: absolute;
      top: calc(0% - 0.4rem) !important;
      display: block;
      transition: 0.2s;
      font-size: .9rem;
      padding: 0 0.4rem;
      background: #fff;
      color: $primary !important;
      pointer-events:none;
      line-height: 1;
      font-weight: 400;
    }
  }
}
textarea.form-control {
  height: auto;
}
.form-check-input[type=radio] {
  border-color: $warning !important;
  &:focus {
    box-shadow: none;
  }
  &:checked {
    background-color: $warning !important;
    border-color: $warning !important;
  }  
}
.sticky_label {
  .form-label {
    position: absolute;
    top: calc(0% - 0.7rem) !important;
    display: block;
    transition: 0.2s;
    font-size: 0.9rem;
    padding: 0 0.3rem;
    background: #fff;
    color: $primary !important;
    pointer-events:none;
    z-index: 1;
  }
  &.radio_btn {
    display: inline-flex;
    flex-wrap: wrap;
    align-content: baseline;
    .form-label {
      position: initial;
      padding: 0;
      order: 0;
      margin: 0 0 0.2rem;
      background: transparent;
      width: 100%;
    }
    .form-check {
      order: 1;
      &:hover, &:focus {
        cursor: pointer;
        color: #00A7B5;
        label {
          cursor: pointer;
          color: #00A7B5;
        }
      }
      label {
        margin: 0;
      }
    }
  }
}
.status_list {
  margin: 0;
  padding: 0;
  list-style: none;
  width: 100%;
  display: inline-flex;
  flex-wrap: wrap;
  gap: 0.8rem;
  .custom-control {
    display: inline-block;
    padding-left: 1.3rem;
    .custom-control-input:focus ~ .custom-control-label::before {
      box-shadow: none;
    }
    .custom-control-label {
      white-space: pre;
      &::before {
        top: 0.1rem;
        left: -1.3rem;
        box-shadow: none;
      }
      &::after {
        top: 0.1rem;
        left: -1.3rem;
      }
    }
  }
}
.custom-radio-group {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  .custom-radio {
    padding-left: 1.3rem;
    .custom-control-input:focus ~ .custom-control-label::before {
      box-shadow: none;
    }
    .custom-control-label {
      &::before {
        top: 0.15rem;
        left: -1.3rem;
      }
      &::after {
        top: 0.15rem;
        left: -1.3rem;
      }
    }
  }
}
.form-label {
  position: absolute;
  top: 0.8rem;
  left: 1.3rem;
  display: block;
  transition: 0.2s;
  font-weight: 400;
  color: #383739;
  pointer-events:none;
}

.form-control-sm {
  font-size: .9rem;
}

.custom-select-sm {
  font-size: 85%;
}

.custom-control-input:checked~.custom-control-indicator {
  background-color: $primary;
}

.custom-control-input:focus~.custom-control-indicator {
  -webkit-box-shadow: 0 0 0 1px $white, 0 0 0 3px $primary;
  box-shadow: 0 0 0 1px $white, 0 0 0 3px $primary;
}

.has-success {
  .form-control {
    border-color: $success;
    box-shadow: none;
  }
}

.has-warning {
  .form-control {
    border-color: $warning;
    box-shadow: none;
  }
}

.has-error {
  .form-control {
    border-color: $danger;
    box-shadow: none;
  }
}

.input-group-text{
  font-size: 14px;
}
.input-group {
  .input-group-prepend {
    .input-group-text {
      height: 100%;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  .form-control {
    &:focus {
      border-color: var(--bs-border-color);
    }
    &:placeholder-shown ~ .form-label {
      left: 3.3rem;
      z-index: 9;
    }
  }
  .form-label {
    left: 3.3rem;
    z-index: 9;
  }
}

.filter-form {
  .form-control {
    &.selectbox {
      height: auto;
    }
  }
}
.form-btn {
  margin: 1rem 0;
  gap: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.form-control.selectbox [class*=control] [class*=ValueContainer] [class*=multiValue]{
  margin: 0.3rem 0.2rem 0 !important;
  &:first-child{
      margin-top: 0; 
  }
}

.country_field .form-group, .header_select.form-group,  .hra_approve_select.form-group {
  margin: 0;
  .form-control {
    height: 2rem;
    [class*=control] {
      min-height: 2rem;
      max-height: 2.9rem;
      width: 12rem;
      [class*=indicatorContainer] {
        padding-block: 6px;
      }
    }
  }
  .form-label {
    left: 0.9rem;
    background: linear-gradient(to top, #ffffff, #edefff) !important;
  }
}
.general-content {  
  .form-control.selectbox {
    height: auto;
    min-height: 2rem;
    max-height: 4.1rem;
    [class*=control] {
      min-height: 2rem;
      max-height: 4.1rem;
      width: 100%;
      overflow: hidden;
      [class*=indicatorContainer] {
        padding-block: 6px;
      }
      > div {
        max-height: inherit;
      }
    }
  }  
  .add_tags_field {
    .form-control, input {
      height: 2.3rem !important;
    }
  }
}

.hra-sidebar-list .report-filter .hra-create {
  .form-control {
    height: 2.2rem;
    [class*=control] {
      min-height: 2.2rem;
      max-height: 2.9rem;
      width: 100%;
      [class*=indicatorContainer] {
        padding-block: 6px;
      }
    }
  }
  .form-label {
    left: 0.9rem;
  }
}
.header_select.form-group , .hra_approve_select.form-group{
  .form-label {
    top: -0.7rem;
    z-index: 1;
  }
}
.hra_approve_select.form-group{
  .form-control {
    height: 2.5rem;
    [class*=control] {
      min-height: 2.5rem;
      max-height: 2.9rem;
      width:100%;
    }
  }
}
.form-select {
  &:focus {
    box-shadow: none !important;
  }
}