/* ==============
  Card
===================*/

.card {
    // box-shadow: $shadow;
    margin-bottom: 30px;
    border: none;
    border-radius: 8px;
    box-shadow: 0 0.2rem 0.5rem #00000012;
    .card-header {
        background: $white;
        padding: 1.2rem 1.5rem;
        border-radius: 8px 8px 0 0;
        border-bottom: 1px solid #e8ecf2;
        display: flex;
        align-items: center;
        justify-content: space-between;
        > h6 {
            color: $primary;
        }
        &.dashboard-head {
            padding: 0.8rem 1rem;
            .page-header {
                h4 {
                    font-size: 1rem;
                }
            }
            .Compliances_title{
                h4{
                    color: #5e35b1;
                }
            }
        }
        .page-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            h4 {
                margin: 0;
                font-size: 1.3rem;
                font-weight: 500;
                letter-spacing: 0.4px;
            }
            .with-back-btn {
                display: flex;
                align-items: center;
                >a {
                    font-size: 1.5rem;
                    display: inline-flex;
                    align-items: center;
                    line-height: normal;
                    margin-right: 0.3rem;
                    color: $dark;
                    width: 1.8rem;
                    height: 1.8rem;
                    justify-content: center;
                    background: transparent;
                    transition: all 0.3s ease;
                    border-radius: 100px;
                    &:hover,
                    &:focus {
                        color: $primary;
                        background: lighten($dark, 70%);
                        cursor: pointer;
                        transition: all 0.3s ease;
                    }
                }
            }
        }
        .page-action {
            display: flex;
            align-items: center;
            .btn {
                white-space: pre;
            }
        }
    }
    .card-body {}
}

.filter_section {
    margin-bottom: 1rem;
    width: 100%;
    display: inline-block;
    .card-body {
        .separate-head {
            margin: 0rem 0 0.5rem;
        }
    }
    .cancel_filter {
        position: absolute;
        right: 1rem;
        top: 0.5rem;
        color: $primary;
        border-radius: 100px;
        padding: 0;
        margin: 0;
        width: 1.5rem;
        height: 1.5rem;
        background: rgba($primary, 20%);
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.3s ease;
        svg {
            max-width: 45%;
        }
        &:hover, &:focus {
            background: $primary;
            color: $white
        }
    }
    .clear_btn{
        position: absolute;
        right: 3.4rem;
        top: 0.5rem;
        background: none !important;
        border: none !important;
        padding: 0;
        text-decoration: underline !important;
        color: $primary !important;
        &:hover,&:focus{
            background: none !important;
            border: none !important;
            color: #000 !important;
        }
    }
    &.user_filter{
        position: absolute;
        z-index: 99;
        width: 17rem;
        right: 2rem;
        .form-group{
            margin: 0;
        }
    }
    &.vendor_filter {
        width: calc(100% - 4rem);
    }
    &.rebatefilter_section {
        .filter-section-head {
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            margin-bottom: 1rem;
            .sync-box {
                display: block;
                width: 100%;
                .btn{
                    > div{
                        display: flex;
                        align-items: center;
                        gap: 0.5rem;
                        margin-right: 1.7rem;
                        svg{
                            flex: none;
                        }
                        span{
                            .text-muted{
                                text-align: left;
                            }
                        }
                    }
                    > span{
                        display: flex;
                        gap: 0.3rem;
                        align-items: center;
                        padding: 0.2rem 0.4rem;
                        background: #ffffff1c;
                        border-radius: 4px;
                        font-size: 0.8rem;
                    }
                    
                }
                &:not(:last-of-type) {
                    // border-right: 1px solid #a0a0a0;
                    padding-right: 1rem;
                        flex: 0;
                }
            }
            .btn-box {
                display: flex;            
                align-items: center;
                justify-content: flex-end;
                small {
                    font-size: 0.7rem;
                    display: block;
                    text-align: end;
                    line-height: 1;
                }   
                .btn-link {
                    display: flex;
                    align-items: center;
                    gap: 0.4rem;
                    padding-bottom: 0;
                    padding-right: 0;
                    text-decoration: none;
                    border: none;
                    outline: none;
                    text-align: left;
                    &:hover, &:focus {
                        background-color: unset;
                        color: $info;
                    }                
                }
                .btn-primary {
                    display: flex;
                    align-items: center;
                    text-align: left;
                    gap: 0.4rem;
                    padding: 0.1rem 0.3rem 0.3rem;
                    small {
                        color: $white !important;
                    }
                }
            }
        }
        .hra-create {
            .row {
                row-gap: 0.5rem;
            }
        }
    }    
}
.support-request-store {
    border-collapse: separate;
    border-spacing: 0 0.4em;
    thead {
        tr {
            overflow: hidden;
            th {
                overflow: hidden;
                &:first-child {
                    border-radius: 5px 0 0 5px;
                }
                &:last-child {
                    border-radius: 0 5px 5px 0;
                }
            }
        }
    }
    tbody {
        tr {
            overflow: hidden;
            &:nth-child(even) {
                background-color: transparent !important;
            }
            &:is(:hover, :focus) {
                td {
                    background: rgba($primary, 0.20) !important;
                    transition: all 0.3s ease;
                }
            }
            td {
                background: rgba($primary, 0.15) !important;
                overflow: hidden;
                transition: all 0.3s ease;
                &:first-child {
                    border-radius: 5px 0 0 5px;
                }
                &:last-child {
                    border-radius: 0 5px 5px 0;
                }
                .action_col {
                    button {
                        display: inline-flex;
                        align-items: center;
                        padding: 0.1rem 0.2rem;
                        svg {
                            width: 0.8rem;
                            height: 0.8rem;
                            margin: 0.1rem;
                        }
                    }
                }
            }
        }
    }
}
@media (min-width: 576px) {
    .card-columns {
        -webkit-column-gap: 30px;
        -moz-column-gap: 30px;
        column-gap: 30px;
    }
}

.card-columns {
    .card {
        margin-bottom: 30px;
    }
}