

/* ==============
  Responsive
===================*/


@media (min-width: 768px) and (max-width: 991px) {
  body {
    overflow-x: hidden;
  }
}

@media screen and (min-width: 768px){
  .enlarged {
    .slimscroll-menu {
      overflow: inherit !important;
    }
  }
  body {
    overflow-x: hidden;
  }
  .navbar-custom {
    margin-left: 70px !important;
  }
  
}

@media screen and (max-width:1919px) {
  .login-page {
    > .row {
      height: 76vh;
      padding: 1.2rem;
    }       
  }
  .promotion_card {
    .card-body {
      .card_content {
        .creat_btn {
          font-size: 0.8rem;
          padding: 0.4rem;
        }
      }
    }
  }
  .front_main {
    .rebate_history-tables {    
      &.rebate_history {
        .table {
          tbody {         
            tr {
              &.rebate-detail-total {                   
                  td {                 
                    &:nth-child(2){
                      width: 30.1rem;
                    }                                              
                  }         
                }
            }
          }  
        }
      }  
    }
  }
}
@media screen and (max-width:1599px) {
  .navbar {
    .container {
      .navbar-nav {
        gap: 1.1rem !important;
        .nav-link{
          white-space: pre;
        }
      }
    }
  }
  .front-end{
    &.home-page {
      .front-head {
        .container {
          ul {
            gap: 1.7rem !important;
          }
        }         
      } 
    }   
    .front-head {
      .container {
        ul {
          gap: 1.7rem !important;
        }
      }
    }    
  }  

  .dashboard-charts{
    .card{
      h5{
        font-size: 1rem !important;
      }   
    }   
  }   

  .login-page {
    > .row {
      .login-main {
        .loging-head {
          img {
            max-height: 2.9rem;
          }
        }
        .loging-content {
          h2 {
            font-size: 1.7rem;
          }
        }
      }
    }    
  }
 

.promotion_card{
  .card-body{
    .card_content{
      div{
        .card_date{
          p,b{
            font-size: 0.65rem !important;
          }
        }
      }
    }
  }
}

.profile_section {
  .tabs_section {
    .nav-link {   
      font-size: 0.8rem;
    }
  }
}

.tab-pane{
  .Promotion_section{
    // grid-template-columns: repeat(auto-fit, minmax(250px, calc(25% - 1.15rem))) !important;
    grid-template-columns: repeat(4 , 1fr);
  }
}

.vendor_site_back_btn { 
  top: 9vw;
  left: 7vw;
}
.front_main {  
  .rebate_history-tables {    
    &.rebate_history {
      .table {
        tbody {         
          tr {
            &.rebate-detail-total {                   
                td {               
                  &:nth-child(2){
                    width: 21.1rem;
                  }   
                  &:nth-last-of-type(1) {
                    width: 8.2rem;
                  }                                            
                }         
              }
          }
        }  
      }
    }  
  }
}

  
}
@media screen and (max-width:1439px) {
  .login-page {
    > .row {
      height: 76vh;   
      max-width: 55rem;
      .login-main {
        overflow: visible;
      }
    }       
  }
  .navbar {
    .container {
      .navbar-nav {
        gap: 0.8rem !important;
        .right_header{
          gap: 1rem;
        }
      }
    }
  }
  .footer {   
    padding: 10px 0px 10px !important;
  }  
  .Promotion_section {
    grid-template-columns: repeat(auto-fill, minmax(18.5rem, 1fr));
  }
  .store_accordion {
    .accordion-item {      
        li {
          p {            
            font-size: 0.7rem !important;
            svg {
              width: 1rem;
              height: 1rem;
            }
          }
        }      
    }
  }

  .front_main {
    .front_header {
      .navbar {
        padding: 0.6rem 2rem !important;
        .navbar-brand {
          img {
            width: 12rem !important;
          }
        }
      }
    }
    .main-inner {
      padding-top: 6rem !important;
      .container {
        max-width: calc(100% - 6rem) !important;
        margin: 0 3rem !important;     
      }
    }    
    .product-card {
      .product-card-body {
        .like-expire-box {
          .product-like-box {
            font-size: 1.1rem !important;
            svg {
              width: 1.3rem !important;
              height: 1.3rem !important;
            }
          }
        }
      }
    }
  }  
  .support-list {
    .support-data {
      span {
        font-size: 1.3rem !important;
      }
      h6 {
        font-size: 1.1rem !important;
      }
    }
  }
  .rebate_chart{
    .rebate_data{
      grid-template-columns: repeat(3, 1fr);
      .rebate_list{
        > div{
          width: 100%;
          max-width: 100%;
        }
      }
    }
  }  
  .vendor_member_row{
    li {
      p {
        font-size: 0.7rem;
        >span{
          p{
            font-size: 0.6rem;
          }
        }
      }
    }
    &.member_row_head{
      li{
        span{
          font-size: 0.8rem;
        }
      }
    }
  }
  .vendor_site_back_btn {   
    left: 3vw;
  }

  .front_main {    
    .rebate_history-tables {    
      &.rebate_history {
        .table {
          tbody {         
            tr {
              &.rebate-detail-total {                   
                  td {               
                    &:nth-child(2){
                      width: 20.5rem;
                    }   
                    &:nth-last-of-type(1) {
                      width: 9.3rem;
                    }    
                    &:nth-child(5), &:nth-child(6), &:nth-child(7), &:nth-child(8) {
                      width: 6.1rem;
                    }                                        
                  }         
                }
            }
          }  
        }
      }  
    }
  }
}

@media screen and (max-width: 1365px){
  .navbar {
    .container {
      .navbar-brand{
        height: 65px !important;
        width: 253px !important;
      }
    }       
  }
  .no-data-found {
    svg{
      width: 5rem;
      height: 5rem;
    }
    h1{
      font-size: 1.7rem;
      margin-bottom: 0.4rem !important;
    }
    p{
      font-size: 1rem !important;
    }    
    &.modal-data {
      svg {
        width: 3.9rem;
        height: 3.9rem;
      }
      h4 {
        font-size: 1.2rem;   
      } 
    }
  }
  .login-page {
    > .row {
      padding: 1rem !important;
      height: 67vh;
      .login-main {
        .loging-head {
          img {
            max-height: 2.5rem !important;
          }
        }
        .loging-content {
          h2{
            font-size: 1.6rem !important;
          }
        }
        .form-control{            
          height: 2.3rem !important;
          font-size: 0.7rem !important;
          &:placeholder-shown ~ .form-label {              
            top: 0.6rem !important;              
            left: 0.5rem !important;
          }
        }
        .form-label{
          left: 0.5rem !important;
          font-size: 0.8rem !important;
        }
        .copy-right{
          p{
            font-size: 0.8rem !important;
          }
        }
        .btn-primary{
          padding: 0.4rem !important;
          font-size: 0.9rem !important;
        }
      }
    }    
  }  
  .main-inner {
    padding-top: 4rem !important;
  }
  .hra-table {
    tr {
      td {
        &:first-child {
          p {
            max-width: calc(100vw - 36rem) !important;
          }
        }
      }
    }    
  }
  .Promotion_section {
    grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
  }
  .login-page {
    .login-footer {
      padding: 0.3rem 0 !important;
      p {
        font-size: 0.7rem;
      }
    }
  }
  .backend-footer {
    padding: 0.3rem 0 !important;
    p {
      font-size: 0.7rem;
    }
  }
  .main-inner {  
    min-height: calc(100dvh - 1.6rem) !important;
  }
  .login_box{
    .login_field_fornt{
      .login-form{
        .become_link{
          span{
            font-size: 0.9rem;
          }
          a{
            font-size: 1rem !important;
          }
        }
      }
    }
  }
  .product-card {
    .product-card-body {
      padding:0.6rem 0.6rem 1rem !important;      
        .product-like-box {
          font-size: 1rem !important;
        }      
    }
  }
  .head_hraid {
    span {   
      padding: 0.1rem 0.6rem !important;
      font-size: 0.9rem !important;
    }
  }
  .front_main {
    .title-box {
      h3 {
        font-size: 1.7rem !important;
      }
    }
    h4 {
      font-size: 1.2rem;
    }
  }
  .profile-store-field-list {
    .profile-store-data {
      > div {
        p {
          font-size: 0.8rem !important;
        }
      }
    }
  }
  .backend{
    .promotion_img_upload{
      .card-body{
        .uploads_btn{
          gap: 0.4rem;
          span{
            font-size: 0.7rem;
          }
          .btn-link{
            font-size: 0.6rem;
            white-space: pre;
          }
        }
      }
    }
  }
  .promotion_card {
    .card-body {
      .card_content {
        .card_content_text {
          h4 {   
            width: 18.4ch;
          }
        }
      }
    }
  }
  .rebate_chart{
    .rebate_data{
      grid-template-columns: repeat(3, 1fr);
      .rebate_list{
        > div{
          width: 100%;
          max-width: 100%;
        }
      }
    }
  }
  // .rebate_history-tables {   
  //   &.rebate_history {
  //     .table {
  //       tbody {          
  //         tr {
  //           &.rebate-detail-total {                  
  //               td {
  //                 width: 10.2rem;                  
  //               }                                                 
  //             }
  //         }
  //       }  
  //     }
  //   }  
  // }
  .front_main {    
    .rebate_history-tables {    
      &.rebate_history {
        .table {
          tbody {         
            tr {
              &.rebate-detail-total {                   
                  td {               
                    &:nth-child(2){
                      width: 14.3rem;
                    }   
                    &:nth-last-of-type(1) {
                      width: 8.8rem;
                    }    
                    &:nth-child(5), &:nth-child(6), &:nth-child(7), &:nth-child(8) {
                      width: 6.1rem;
                    }                                        
                  }         
                }
            }
          }  
        }
      }  
    }
  }
}

@media screen and (max-width: 1279px){
  .login-page {
    > .row {
      height: calc(100vh - 10rem) !important;
    }
  }  
  .store_accordion {
    .accordion-item {      
      .store_hra_id span {       
        font-size: 0.8rem !important;
      } 
      li {
        p {
          svg {
            width: 0.9rem !important;
            height: 0.9rem !important;
          }
        }
        &.store-hra-contact {
          width: 18%;
        }
        &.store-hra-mail {
          width: 40%;
        }
      }
    }
  }
  .login_box{
    .login_field_fornt{
      .login-form{
        .become_link{
          flex-direction: column;
          gap: 0 !important;
        }
      }
    }
  }
  .front_main {
    .product-card-list {     
      grid-template-columns: repeat(3, 1fr) !important;
    }
    .support-list {
      .support-data {
        padding: 1rem 0.6rem !important;
        h5 {
          font-size: 1.1rem !important;
        }
        span {
          font-size: 1.1rem !important;
        }
      }
    }
    .title-box {
      h3 {              
        font-size: 1.4rem !important;
      }
    }
    .profile_section {
      .tabs_section {
        h3 {
          font-size: 1.1rem !important;
        }
        p {
          font-size: 0.9rem !important;
        }
      }
    }
    .main-inner {
      padding-top: 5rem !important;
    }
    .front_header {
      .navbar {
        padding: 0.6rem 1rem !important;
        .navbar-brand {
          .logo_main {
            width: 9rem !important;
          }
        }
      }
    }
    .front-home {
      min-height: calc(100vh - 6.5rem);
      display: inline-block;
      margin-bottom: -1.5rem;
      width: 100%;
      padding-bottom: 1.5rem;
    }
  }
  .support-request-modal {
    .modal-dialog {
      --bs-modal-width: calc(100vw - 40%);
    }
  }
  .login-page {
    > .row {
      height: calc(100vh - 7rem) !important;
      max-width: 45rem;
      .login-main {
        .loging-head{
          img{            
            max-height: 2.4rem;
          }
        }
        .loging-content {
          padding-left: 1rem;
          h2 {           
            font-size: 1.4rem;
          }
          p{
            font-size: 0.8rem;
          }
          .form-control{            
            height: 2rem;
            font-size: 0.7rem !important;
            &:placeholder-shown ~ .form-label {              
              top: 0.3rem;              
              left: 0.4rem;
            }
          }
          .form-label{
            left: 0.8rem;
            font-size: 0.8rem;
          }
        }
        .btn-primary{
          font-size: 0.8rem;
        }
        a{
          font-size: 0.7rem;
        }
        .copy-right{
          p{
            font-size: 0.7rem;
          }
        }
      }
    }
  }  
  .navbar {
    .container{
      margin: 0 2rem !important;
    }
  }  
  .container {
    max-width: calc(100% - 4rem) !important;
    margin: 0 2rem !important;
  }
  h2{
    font-size: calc(0.925rem + 0.9vw) !important;
  }
  .tab-pane{
    .Promotion_section{    
      grid-template-columns: repeat(3 , 1fr);
    }
  }
  .dropzone.dropzone_pdf .dropzone-container .attachment-data h6{
    max-width: 13rem;
  }
  .login_box{
    .login_field_fornt{
      max-width:35% !important;
      .login-form{
        .forgot_btn{
          a{
            font-size: 0.9rem;
          }
        }
      }
    }
  }
  .member_form_btn{
    grid-template-columns: repeat(2, 1fr);
  }
  .rebate_list {
    > div {
      margin: 0;
      width: calc(33.33% - 0.7rem);
    }
  }
  .rebate_chart{
    .rebate_data{
      grid-template-columns: repeat(2, 1fr);
      .rebate_list{
        > div{
          width: 100%;
          max-width: 100%;
        }
      }
    }
  }
  .vendor_site_back_btn {
    left: 2.7vw;
    top: 10vw;
    &.pdf_back{
      margin-top: 1.2rem;
    }
  }  
  .rebate_history {
    &.rebate_history-tables {
      .react-bootstrap-table {
        > table {
          > tbody {              
              .expanding-row {
                td {           
                  &.rebates_year {
                    width: 4.5rem;
                  }
                  &.rebates_name {
                    width: 6rem;
                  }
                  &.volume_type {
                    width: 8rem;
                  }
                  &.total_volume {
                    width: 8.7rem;
                  }
                  &.q1_rebate, &.q2_rebate, &.q3_rebate, &.q4_rebate {
                    width: 4.5rem;
                  }
                  &.total_amount {
                    width: 9.2rem;
                  }
                }
              }                              
          }
        }
      }
    }
  }   
  .rebate_history.rebate_history-tables .react-bootstrap-table > table > tbody > tr.expanding-row > td .react-bootstrap-table table tbody tr td.rebates_name {
    padding-left: 0.5rem !important;
  }
    
    .rebate_history-tables {   
      &.rebate_history {
        .table {
          tbody {          
            tr {
              &.rebate-detail-total {                  
                  td {
                    width: 6.2rem;      
                    &:nth-last-of-type(1) {
                      width: 7.2rem;
                    }            
                  }                                                 
                }
            }
          }  
        }
      }  
    }
}
@media screen and (max-width: 1200px){
  .vendor_site_details{
    .html_footer{
      .row{
        padding: 1.2rem 0.5rem !important;
      }
      > div{
        .btn-link{
          white-space: nowrap;
          padding-right: 0;
        }
      }
    }
    
  }  
  .rebate_history-tables {    
    &.rebate_history {
      .table {
        tbody {         
          tr {
            &.rebate-detail-total {                   
                td {               
                  &:nth-child(2){
                    width: 6rem;
                  }                                                           
                }         
              }
          }
        }  
      }
    }  
  }
}
@media screen and (max-width: 1023px){
  .login-page {
    > .row {
      height: calc(100vh - 7rem) !important;
      .login-main {
        overflow: visible;
        padding-top: 2rem;
        .loging-content {
          p {
            margin: 0 0 0.9rem;
          }
        }
      }
    }       
  }
  .navbar {
    .container {
      .navbar-brand {
        padding: 0.5rem 1.2rem !important;
        padding-right: 1.1rem !important;
      }
    }    
  }
  .action_col {  
    min-width: max-content;
  }
  .front_main {
    .product-card-list {     
      grid-template-columns: repeat(2, 1fr) !important;
    }
    .profile_section {
      display: block;
      .tabs_section {
        width: 100%;       
        height: auto;       
        top: 0;
        margin-bottom: 1rem;
        .nav-tabs {
          padding-bottom: 1rem;
          display: block;
          .nav-item {
            width: 100%;
          }
        }
      }
      .content_section {
        width: 100%;
      }
    }
    .section-head {
      h3 {
        font-size: 1.5rem !important;
      }
    }    
  }
  .hra-light-form-box {
    span {   
      font-size: 0.9rem !important;
    }
  }
  .profile_section {
    &.active {
      .tabs_section {
        display: block;
      }
      .content_section {
        display: none;
      }
      .nav-tabs {
        .nav-item {
          .nav-link {
            &.active {
              background: #fff;
              color: rgba(0, 0, 0, 0.6117647059);      
              &:hover {            
                background: #dbe9ff;
                border-color: #dbe9ff;
              }        
            }
            border: 1px solid rgb(213 213 213);
            margin-bottom: 0.5rem;
            background-color: #fff;
            &:hover {            
                background: #dbe9ff;
                border-color: #dbe9ff;
            }
          }
        }
      }
    }
    .back_btn{
      display: inline-block !important;
    }
    .tabs_section {
      display: none;
    }
    .content_section {
      display: inline-block;
    }
  }
  .slider-client-logo {    
    .slick-arrow {
      &.slick-prev,&.slick-next{
        width: 2.1rem;
        height: 2.1rem;
        &::before {
          width: 0.5rem;
          height: 0.9rem;
        }
      }
    }
  }
  .vendor_site_back_btn {   
    top: 13vw;
  }
  .front_main {
    .rebate_history-tables {
      margin-bottom: 0;
    }  
  } 
}
/*---------------------------------------------------------------------------------------------------------*/

/*------------------------------- max-width:992px ----------------------------*/

@media screen and (min-width: 992px) {
  .rebate_history-tables {
    &.rebate_history {
      .table {
        tbody {          
          tr {
            &.expanding-row {
              td {
                &.rebates_year {
                  font-size: 0;
                }
              }
            }  
          }
        }
      }
    }
  }
}


@media screen and (max-width: 992px){
  .login_box{
    .login_bg_fornt{
      width: 100% !important;
    }
    .login_field_fornt{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      background: #fff;
      max-width: 85% !important;
      width: 30rem;
      border-radius: 10px;
    }
  }
  .front_login-page{
    > .row{
      width: 90vw;
    }
  }
  .promotion-detail-box {
    .promotion-detail-images {      
      grid-template-columns: repeat(3, 1fr);
    }
  }
  .front_header {
    .navbar {
      .navbar-nav {
        li {
          &.store_dropdown{
            display: none;
          }
          &.login_btn {
            .loggd_in_text {
              display: none !important;
            }
            .login_text {
              display: none !important;
            }
          }
        }
        .menu_dropdown {
          .dropdown-menu{
            &.show {
              max-height: 35rem;
              .mobile-view-user {
                display: block !important;
                &.drop_down{
                  height: 3.2rem;
                }
                .store_dropdown{
                  width: 100% !important;
                }
                &:hover {
                  background-color: $white !important;
                }
                .nav-item {
                  display: block !important;
                  .loggd_in_text {
                    display: block !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .profile_section{
    .tabs_section{
      top: -2.2rem !important;
      .nav-tabs{
        flex-direction: row;
        gap: 0;
        .nav-item{
          width: 50%;
          a{
            text-align: center;
          }
        }
      }
    }
  }
  .support-request-modal {
    .modal-dialog {
      --bs-modal-width: calc(100vw - 25%);
    }
  }  
  .rebate_list {
    > div {
      margin: 0;
      width: calc(50% - 0.5rem);
    }
  }
  .rebate_chart{
    .rebate_data{
      grid-template-columns: repeat(1, 1fr);
      .rebate_list{
        > div{
          width: 100%;
          max-width: 100%;
        }
      }
    }
  }
  .front_main {
    .main-inner {
      padding-top: 5rem !important;
    }
    .front_header {
      .navbar {
        padding: 0.6rem 1rem !important;
        .navbar-brand {
          .logo_main {
            width: 9rem !important;
          }
        }
        .navbar-nav {
          li {
            > a {
              font-size: 0.9rem;
            }
          }
        }
      }
    }
    .front-home {
      > h2 {
        padding: 0 1.5rem;
        text-align: left;
        margin: 1.5rem 0;
      }
      .container {
        margin: 0 1rem !important;
        max-width: calc(100% - 2rem) !important;
        > ul {
          > li {
            > a {
              padding: 1rem;
              flex-direction: column;
              gap: 0.5rem;
              text-align: center;
              line-height: normal;
            }
          }
        }
      }
    }
  }
  .rebate_history {
    thead {
      display: none;
    }    
    tbody{
      padding: 0rem !important;
      display: block;
      background: unset;
      border-radius: 10px;
    }
    tr {
      display: flex;
      flex-direction: column;
      gap: 0.8rem;
      padding: 0.9rem;
      margin-bottom: 1rem;
      border-radius: 10px;
      border: 1px solid black;
      box-shadow: $shadow;
      background-color: #e0e8f3 !important;
      > td {
        background-color: #fff !important;
        padding: 0.5rem !important;
        box-shadow: 0 0 0.6rem #00000017 !important;
        border-radius: 7px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        &.rebates_year::before {
          content: 'Year : ';
          font-weight: 600;
          color: #002e70;
        }
        &.rebates_name::before {
          content: 'Rebate Class : ';
          font-weight: 600;
          color: #002e70;
        }
        &.volume_type::before {
          content: 'Volume type : ';
          font-weight: 600;
          color: #002e70;
        }
        &.total_volume::before {
          content: 'CY-Volume : ';
          font-weight: 600;
          color: #002e70;
        }
        &.q1_rebate::before {
          content: 'Q1 : ';
          font-weight: 600;
          color: #002e70;
        }
        &.q2_rebate::before {
          content: 'Q2 : ';
          font-weight: 600;
          color: #002e70;
        }
        &.q3_rebate::before {
          content: 'Q3 : ';
          font-weight: 600;
          color: #002e70;
        }
        &.q4_rebate::before {
          content: 'Q4 : ';
          font-weight: 600;
          color: #002e70;
        }
        &.total_amount::before {
          content: 'Total Amount : ';
          font-weight: 600;
          color: #002e70;
        }
        &.text-end {
          text-align: left !important;
        }
      }          
    }
  }
  .rebate_history-tables {
    &.rebate_history {
      .table {
        tbody {
          tr.rebate-detail-total {
            display: none !important;            
            &:last-child {
              display: flex !important;
              background-color: #bfd9ff !important;
              border-radius: 10px;
              position: static;
              &:hover{
                td{
                  background: #fbfcff !important;
                }
              }
              td {
                background: #ebf3ff !important;
                display: flex !important;
                justify-content: space-between !important;
                flex-wrap: wrap;
                border-radius: 7px;
                span{
                  display: block;
                }
                &:first-child {
                  border-radius: 0 !important;
                  &::before {
                    content: 'Total of rebate : ';
                  }
                }
                &:nth-child(2), &:nth-child(3), &:nth-child(4) {
                  display: none !important;
                }
                &:first-letter {
                  font-size: 1em;
                }
              }
              }
          }
          tr {
            &.expanding-row {
              td {
                &.rebates_name {
                  &::before {
                    content: 'Rebate Sub Class : ';
                    font-weight: 600;
                    color: #002e70;
                  }
                }
              }
            }  
          }
        }
        tfoot {
          tr.rebate-detail-total {
            // display: none !important;    
            border-radius: 10px;        
            &:last-child {
              // display: flex !important;
              background-color: #bfd9ff !important;
              // border-radius: 10px;
              // position: static;
              &:hover{
                td{
                  background: #fbfcff !important;
                }
              }
              td {
                background: #ebf3ff !important;
                display: flex !important;
                justify-content: space-between !important;
                flex-wrap: wrap;
                border-radius: 7px;
                span{
                  display: block;
                }
                &:first-child {
                  border-radius: 0 !important;
                  &::before {
                    content: 'Total of rebate : ';
                  }
                }
                &:last-child {
                  border-radius: 0;
                }
                &:nth-child(2), &:nth-child(3), &:nth-child(4) {
                  display: none !important;
                }
                &:first-letter {
                  font-size: 1em;
                }
              }
              }
          }
        }
      }
    }
  }
  .newsletter-detail {
    .card-body {
      padding: 1rem;
    }
  }
  .newsletter-detail {
    .other-news-letter {
      li {
        .news-letter-content {
          p, h4 {
            max-width: 45vw !important;
          }
        }
      } 
    }
  }
  .vender_card_list_tab{
    h1{
      font-size: 2rem;
    }
  }
  .html_hero_logos{
    backdrop-filter: blur(3px);
    .row{
      > div{
        display: flex;
        justify-content: center;
        &:not(:last-child){
          margin-bottom: 1rem;
        }
      }
    }
  }
  .vendor_site_details{
    .html_textarea{
      .product_text{
        padding: 3rem 3rem;
      }
    }
    .html_lightbox{
      .promotion-detail-box{
        padding: 1rem;
        .promotion-detail-images{
          margin-top: 0;
          grid-template-columns: repeat(2, 1fr);
          gap: 0.8rem;
        }
      }
    }
    .html_footer{
      .row{
        > div{
          display: flex;
          justify-content: center;
          margin-bottom: 1rem;
          img{
            max-width: 30%;
          }
          ul{
            justify-content: center;
            li{
              text-align: center;
            }
          }
        }
      }
    }
  }  
  .rebate_history {
    &.rebate_history-tables {
      .react-bootstrap-table {
        width: 100%;
        > table {
          > tbody {
            > tr {
              > td {
                width: 100% !important;
              }
              &.even-row ,&.odd-row {
                background-color: #d6e7ff !important;
                &:hover {
                  background-color: #c4ddff !important;
                }
              }              
              &.expanding-row {
                background-color: #e1e1e1 !important;
                tbody {
                  background-color: #e1e1e1 !important;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width : 992px) and (max-height : 992px) { 
  .login_box {
    background: url(../assets/images/memberportal.webp) no-repeat fixed center center/cover;
    height: auto;
    min-height: 100vh;
    display: flex;
    align-items: center;
    .login_bg_fornt {
      display: none;
    }
    .login_field_fornt {
      position: inherit;
      transform: none;
      margin: 2rem auto;
    }
    .policy-links
    {
      position: relative;
      bottom: auto;      
    }
  }
}

/*------------------------------- max-width:768px ----------------------------*/

@media screen and (max-width: 768px) {
  .login_box{
    height: 100vh;
    .login_field_fornt{
      .login-form{
        .forgot_btn{
          a{
            min-width: 9.5rem !important;
          }
        }
      }
    }
  }
  .rebate_list {
    > div {
      margin: 0;
      width: calc(50% - 0.5rem);
    }
  }
  .front_main {
    .front_header {
      .navbar {
        max-width: 100%;
        width: 100%;        
        > .navbar-nav {
          > li {
            &:nth-child(-n+4) {
              display: none;
            }
            &.login_btn {
              .loggd_in_text {
                display: none !important;
              }
              .login_text {
                display: none !important;
              }
            }
          }
          .menu_dropdown {
            .dropdown-menu{
              &.show {
                left: 0;
                right: 0;
                position: fixed;
                top: 4.7rem;
                max-height: calc(100vh - 7rem);
                .mobile-view-user {
                  display: block !important;
                  &.drop_down{
                    height: 3.2rem;
                  }
                  .store_dropdown{
                    width: 100% !important;
                  }
                  &:hover {
                    background-color: $white !important;
                  }
                  .become_btn {
                    text-align: center;
                  }
                }
              }
            }
          }
        }
      }
    }
    .front-home {
      min-height: calc(100vh - 6.5rem);
      display: inline-block;
      margin-bottom: -1rem;
      width: 100%;
      padding-bottom: 2rem;
      .container {
        > ul {
          grid-template-columns: repeat(auto-fill, minmax(7rem, calc(50% - 0.5rem)));
          li {
            a {
              font-size: 1rem;
              span {
                width: 4.5rem;
                height: 4.5rem;
              }
            } 
          }
        }
      }
    }
    .title-box {      
      margin-bottom: 1rem !important;
    }
    .agreement_container {
      .section-head {
        flex-direction: column;
        align-items: flex-start;
        gap: 0.6rem;
      }
    }
    .product-card-list {     
      grid-template-columns: repeat(2, 1fr) !important;
    }
    footer {
      .fix-footer {
        display: inline-block;
        width: 100%;
        position: fixed;
        bottom: 0;
        top: auto;
        box-shadow: 0rem -0.3rem 0.6rem rgba($black, 0.15);
        > ul {
          display: inline-flex;
          justify-content: space-evenly;
          gap: 0;
          margin: 0 auto;
          width: 100%;
          background: #F0F8D9;
          height: 100%;
          .nav-item {
            &.promotion-story {
              .nav-link { 
                padding: 0.1rem 0.5rem;
                svg {
                  width: 1.7rem;
                  height: 1.7rem;
                }
              }
            }
            .nav-link {
              gap: 0.5rem;
              font-size: 0.85rem;
              padding: 0.5rem 0.3rem;
              height: 100%;
              display: flex;
              align-items: center;
              &.active {
                border-top: 3px solid $primary;
              }
              svg {
                width: 1.3rem;
                height: 1.3rem;
              }
            }
          }
        }
      }
    }
  }
  .hra-light-form-box {
    label {   
      white-space: unset !important;    
      overflow-wrap: anywhere;
    }
  }
  .promotion-detail-box {
    .promotion-detail-data {
      .promotion-contact {
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }
  .rebate_btn {
    .btn {
      font-size: 0;
      gap: 0;
    }
  }
  .vender_card_list_tab{
    h1{
      font-size: 1.8rem;
    }
  }
  .vendor_site_details{
    .html_hero{
      height: 60vh;
    }
    .html_hero_logos{
      padding: 1rem 0;
    }
    .html_textarea{
      .product_text{
        padding: 1.5rem;
      }
    }
    .html_lightbox{
      .promotion-detail-box{
        padding: 1rem;
        .promotion-detail-images{
          grid-template-columns: repeat(2, 1fr);
          gap: 0.8rem;
        }
        img{
          width: 100%;
          max-width: 100%;
        }
      }
    }
    .html_footer{
      .row{
        > div{
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          img{
            max-width: 30%;
          }
          ul{
            li{
              width: 100%;
              text-align: center;
            }
          }
        }
      }
      .like_btn{
        bottom: 3.5rem !important;
        // .btn{
        //   .like_fill_svg{
        //     padding: 0.2rem 0.4rem;
        //     font-size: 0.8rem;
        //     svg{
        //       width: 1.7rem;
        //       height: 1.7rem;
        //     }
        //   }
        // }
      }
      
    }
  }  
  .vendor_site_back_btn {
    top: 15vw;
    width: 2.5rem;
    height: 2.5rem;
    .btn {
      padding: 0.4rem;
    }
  }
}

@media screen and (max-width: 639px) {
  .mo-mb-2 {
    margin-bottom: 10px;
  }
  
  .mo-mt-2 {
    margin-top: 10px !important;
  }

  .ex-pages{
    padding: 24px 0px;
  }

  .front_login-page{
    height: calc(100% - 4rem);
    padding: 2rem 0;
  }
  .front_main {
    .main-inner {
      .container {
        max-width: calc(100% - 2rem) !important;
        margin: 0 1rem !important;
      }
    }
    .front_header {
      .navbar {
        padding: 0.6rem 1rem !important;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        .navbar-brand {
          img {
            width: 9rem !important;
          }
        }
        .navbar-nav{
          gap: 0.7rem;
          .become_btn{
            a{
              font-size: 0.9rem;
            }
          }
          .notification_bell{
            .dropdown-menu{
              width: calc(100vw - 1rem);
              right: calc(100% - 9.6rem);
              top: 3.4rem;
            }
          }
        }
      }
    }
    .section-head {
      h3 {
        font-size: 1rem !important;
      }
    }
    .product-card-list {     
      grid-template-columns: repeat(1, 1fr) !important;
    }  
    .title-box {
      .link-primary {
        font-size: 1rem;
      }
    }
    footer {
      .fix-footer {
        > ul {
          .nav-item {
            .nav-link {
              gap: 0;
              font-size: 0;
            }
          }
        }
      }
    }
  }  
  .no-content-img {
    min-height: 30vh;
  }
  .content_section{
    .owner_view_section{
      .tab_content_head{
        .back_btn{
          display: flex;
        }
      }
    }
  }
  .promotion-detail-box {
    .promotion-detail-images {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  .ach_section_head{
    flex-direction: column;
    align-items: flex-start !important;
    gap: 0.6rem;
    .view_btn{
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
  }  
  .member_form_btn{
    grid-template-columns: repeat(2, 1fr);
    gap: 0.7rem;
    margin-bottom: 0.8rem;
    .btn{
      font-size: 0.8rem !important;
    }
  }
  .agreemet_pdf_container{
    overflow: auto;
    iframe{
      max-height: calc(100vh - 17rem);
    }
  }
  .promotion_section_head{
    flex-direction: column;
    align-items: flex-start !important;
    gap: 0.6rem;
    .gap-2 {
      width: 100%;
      justify-content: space-between;
    }    
  }  
  .rebate_bg {
    padding-inline: 0;
    > div {
      padding: 0.8rem 0.8rem;
      .rebate_list {
        gap: 0.5rem;
        > div {
          width: 100%;
        }
      }
    }
  }
  .chart_btns{
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    padding-inline: 0 !important;
    .rebate-filter {
      display: flex;
      gap: 0.5rem;
      align-items: center;
      width: 100%;
      .form-group {
        flex: 1;
      }
      .form-group {
        flex: 1;
        .form-control {
          height: 2.5rem !important;
          [class*=control] {
            min-height: 2.5rem;
            max-height: 2.5rem;
          }
        }
        .form-label {
          left: 0.5rem;
          background: linear-gradient(180deg, hsla(0, 0%, 94%, 1) 50%, hsla(0, 0%, 100%, 1) 60%) !important;
        }
      }
    }
  }
  .vender_card_list_tab{
    h1{
      font-size: 1.6rem;
    }
  }
  .vender_card_list{
    .card{
      .logo_vendor{
        padding: 1rem;
        img{
          height: 10rem;
        }
      }
    } 
  }
  .vendor_site_details{
    .html_hero_logos{
      padding: 0.7rem 0;
      margin-top: 40vw;
      img{
        width: 8rem;
      }
      .html_main_head{
        h1{
          font-size: 1.5rem;
        }
      }
    }
    .html_textarea{
      .product_text{
        padding: 1rem;
        overflow: hidden;
        p{
          span{
            font-size: 1rem !important;
          }
          a{
            font-size: 1.1rem !important;
            text-overflow: ellipsis;
            width: 100%;
            max-width: 100%;
            display: inline-block;
            overflow: hidden;
          }
        }
      }
    }
    .html_lightbox{
      .promotion-detail-box{
        padding: 1rem;
        .promotion-detail-images{
          grid-template-columns: repeat(1, 1fr);
          gap: 0.8rem;
        }
      }
    }
  }  
  .vendor_site_back_btn {
    top: 20vw;
  }
}

/*------------------------------- max-width:475px ----------------------------*/

@media screen and (max-width: 475px){
  .front_main {
    .main-inner {
      padding-top: 4.8rem !important;
      }
    .front_header {
      .navbar {
        .navbar-brand {
          img {
            width: 9rem !important;
          }
        }
        .navbar-nav{
          gap: 0.35rem;
          .become_btn{
            a{
              font-size: 0.8rem;
              height: 100%;
              display: inline-block;
              width: auto;
              text-align: center;
            }
          }
          .menu_dropdown{
            .dropdown-toggle{
              svg{
                width: 1.3rem;
                height: 1.3rem;
              }
              span{
                font-size: 0.7rem;
              }
            }
          }
          .notification_bell{
            .dropdown-menu{
              right: calc(100% - 7.9rem);
            }
          }
        }
      }
    }
    .title-box {
      .link-primary {
        white-space: nowrap;
      }
    }  
  } 
  .rebate_list {
    > div {
      margin: 0;
      width: calc(100% - 0.5rem);
    }
  }
  .chart_btns{
    flex-direction: column;
    align-items: center;
    .form-group {  
      flex: 0.2;
    }
    .rebate-filter {
      width: 100%;
      .form-group {
        margin-bottom: 0;
      }
    }
    .rebate_btn {
      .btn {
        font-size: 0.85rem;
        gap: 0.3rem;
      }
    }
  }
  .vendor_site_back_btn {
    top: 26vw;
  }
}

/*------------------------------- max-width:420px ----------------------------*/

@media screen and (max-width: 420px){
  .login_box{
    .login_field_fornt{
      padding: 1.5rem !important;
      max-width: 90% !important;
      .login-form{
        .forgot_btn{
          a{
            min-width: 8rem !important;
          }
        }
      }
    }
  }
  .front_main{
    .front_header{
      .navbar{
        .navbar-brand{
          img{
            width: 8rem !important;
          }
        }
        .navbar-nav{
          .login_btn{
            a{
              .loggd_in_svg{
                width: 2rem;
                height: 2rem;
                font-size: 0.9rem;
              }
            }
          }
          .notification_bell{
            .dropdown-menu{
              right: calc(100% - 7.4rem);
            }
          }
        }
        
      } 
    }
  } 
  .front_login-page{
    > .row{
      padding: 0.5rem 0;
    }
  }
  .agreement_container{
    .agreement_head{
      display: flex;
      gap: 1rem;
      justify-content: center;
      width: 100%;
      .hra-light-form-box{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0;
      }
    }
  } 
  .member_form_btn{
    grid-template-columns: repeat(1, 1fr);
    font-size: 0.8rem;
    margin-bottom: 0.8rem;
  }
}

/*------------------------------- max-width:375px ----------------------------*/

@media screen and (max-width: 375px){
  .login_box{
    .login_field_fornt{
      padding: 1.5rem !important;
      max-width: 90% !important;
      .login-form{
        .forgot_btn{
          a{
            min-width: 7rem !important;
            font-size: 0.8rem;
          }
        }
      }
    }
  }  
  .login_box {
    .policy-links
    {
      a
      {
        padding: 0 0.5rem;
      }  
    }
  }  
  // .front_header{
  //   .navbar{
  //     .navbar-brand{
  //       img{
  //         width: 6rem !important;
  //       }
  //     }
  //   } 
  // }
  
}