/* ==============
  Form-Upload
===================*/
/* Dropzone */
.dropzone {
    min-height: 70px;
    // border: 2px dashed rgba(0, 0, 0, 0.3);
    background: #fff;
    // padding: 1rem;
    border-radius: 6px;

    // &:hover, &:focus {
    //   background: lighten($primary, 62%);
    //   border-color: $primary;
    //   transition: all 0.3s ease;
    //   .dz-message {
    //     cursor: pointer;
    //     h3 {
    //       color: $primary;
    //     }
    //   }
    // }
    .dz-message {
        font-size: 1.3rem;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        h3 {
            font-size: 1.3rem;
        }
    }
}

.dropzone {
    input[type=file] {
        display: none
    }

    .dropzone-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 1rem;
        border: 2px dashed #CCCCCC;
        border-radius: 0.4rem;
        background-color: $white;
        cursor: pointer;
        position: relative;

        &:hover,
        &:focus {
            border-color: #ffd900;

            .file-icon {
                color: #ffd900;
            }

            .dropzone-title {
                color: #ffd900;
            }
        }

        input[type=file] {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            height: 100%;
            left: 0;
            background: transparent !important;
            background-color: transparent !important;
            border-color: transparent !important;
            color: transparent !important;
            font-size: 0px !important;
            -webkit-box-shadow: 0 0 0px 1000px #00000000 inset !important;
            display: block;
            &:hover,
            &:focus {
                background: transparent !important;
                background-color: transparent !important;
            }
            &::-webkit-file-upload-button {
                display: none;
             }
              &::file-selector-button {
              display: none;
              }
        }

        .file-icon {
            color: #CCCCCC;
            margin-bottom: 0.6rem;

            svg {
                width: 2.5rem;
                height: 2.5rem;
            }
        }

        .dropzone-title {
            color: #CCCCCC;

            .browse {
                color: #9bd732;
                font-weight: 600;
            }
        }
    }
    .dropzone-icon{
        padding: 0.3rem;
        // position: absolute;
        // top: 0rem;
        // z-index: -1;
    }

    .attachment-data-box {
        svg {
            width: 2rem;
            height: 2rem;
        }

        h6 {
            font-size: 1.1rem;
            color: $primary;
            font-weight: 500;
        }
    }
    
    &.dropzone_pdf {
        min-height: 45px;
        &.dropzone_hear{
        position: relative;
            .remove-box{
                position: absolute;
                top: 0;
                bottom: 0;
                display: flex;
                color: $danger;
                right: 0.5rem;
                margin: auto;
                align-items: center;
                padding: 5px;
                cursor: pointer;
            }

        }
        .dropzone-container {
            display: flex;
            flex-direction: row;
            justify-content: inherit;
            align-items: center;
            padding: 0;
            border: 1px solid #dadada;
            transition: all 0.2s ease-in;

            &:hover,
            &:focus {
                border-color: $primary;

                .file-icon {
                    color: #fff;
                    background: $primary;
                }

                .dropzone-title {
                    color: $primary;
                }
            }

            .file-icon {
                background: #002e7024;
                border-radius: 50px;
                padding: 0.3rem 0.4rem;
                margin: 0.2rem;
                color: $primary;
                transition: all 0.2s ease-in;

                svg {
                    width: 1.8rem;
                    height: 1.8rem;
                }
            }

            .dropzone-title {
                transition: all 0.2s ease-in;
                font-weight: 400;
            }

            .attachment-data {
                padding: 0;
                width: 100%;
                margin: 0.2rem;
                background: #f2f2f2;
                .attachment-head {
                    .file-box {
                        padding: 0.5rem;
                    }
                    .remove-box {
                        top: 47%;
                        transform: translate(0, -50%);
                        right: 0.6rem;
                    }
                }

                h6 {
                    max-width: 20rem;
                }
            }
        }

        &.uploaded_pdf {
            .dropzone-title {
                display: none;
            }
            .attachment-data {        
                height: 2.3rem;
                border-radius: 0.3rem;
                position: relative;
                display: inline-block;
                height: 100%;
                width: calc(100% - 3.3rem);
                max-height: 100%;
                background: #ececec;
                .file-box{
                    max-height: 100%;
                    height: 100%;
                    display: block;
                    text-decoration: none;
                }
                //   margin-bottom: 0.8rem;
                &:hover {
                    background-color: #002e7024;
                }
            
                svg {
                    width: 1.6rem;
                    height: 1.6rem;
                }
            
                h6 {
                    font-size: 1rem;
                    max-width: calc(100% - 2rem);
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    line-height: 1.4;
                    margin: 0;
                }
                .remove-box{
                    color: #ec3838;
                    &:hover{
                        color: #8b2121;
                    }
                }
            }
        }
    }

    &.dropzone_img {
        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            display: grid;
            grid-gap: 0.57rem;
            grid-template-columns: repeat(3, 1fr);

            li {
                .attachment-data {
                    background: none;
                    padding-bottom: 0;
                    &:hover{
                        background: none;
                    }
                    &.setbanner{
                        background: #f2d3d3;
                        border: 1px solid #d81010;
                        padding: 0.3rem;
                        border-radius: 5px;
                    }
                    .attachment-head {
                        position: relative;
                        .remove-box {
                            background: #fff;
                            border-radius: 50px;
                            position: absolute;
                            top: 0.24rem;
                            right: 0.3rem;
                            cursor: pointer;
                            width: 1.7rem;
                            height: 1.7rem;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                        .file-box{
                            overflow: hidden;
                            transition: all 0.3s ease-in-out;
                            padding: 0;
                            height: 100%;
                            width: 100%;
                            display: block;
                            border-radius: 5px;
                            &:hover{
                                img{
                                    transform: scale(1.1);
                                    border-radius: 5px;
                                }
                            }
                            img{
                                transition: all 0.3s ease-in-out;
                                max-width: 100%;
                                border-radius: 5px;
                            }
                        }
                        .check_file{
                            display: inline-block;
                            position: absolute !important;
                            top: 0.34rem;
                            left: 0.7rem;
                            input{
                                width: 1rem;
                                height: 1rem;
                            }
                        }
                        .vieweye-box{
                            color: $primary;
                            display: inline-block;
                            position: absolute;
                            top: 0.3rem;
                            right: 0.5rem;
                            cursor: pointer;
                            svg {
                                width: 1.2rem;
                                height: 1.2rem;
                            }
                            &:hover {
                                color: $primary;
                            }
                        }
                        .progress_bar {
                            margin: 0 0.4rem;
                        }
                    }
                    .remove-box {
                        color: $danger;
                        display: inline-block;
                        cursor: pointer;
                        svg {
                            width: 1.2rem;
                            height: 1.2rem;
                        }
                        &:hover {
                            color: $primary;
                        }
                    }
                }
            }
        }
    }
    &.thumbnail-dropzone {
        position: relative;
        .delete-dashboard-button{
            position: absolute;
            background-color: #eb5252;
            color: #ffffff;
            border: 0;
            top: 0.3rem;
            right: 0.3rem;
            border-radius: 50%;
            height: 1.5rem;
            width: 1.5rem;
            padding: 0.2rem;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          svg {
            width: 0.9rem;
            height: 0.9rem;
        }
        .file-box {
            padding: 0.4rem;
            img {
                max-height: 9.2rem !important;
            }
        }
        .file-icon {
          svg {
            width: 3rem;
            height: 3rem;
          }
        }
        .attachment-data {
            position: absolute;
            top: 0.1rem;
            width: calc(100% - 0.2rem);
            height: 10.1rem;
            left: 0.1rem;
            border-radius: 0.3rem;
        }
    }
}
.attachment-data-list {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 0.8rem;
    gap: 0.5rem;
    .attachment-data {        
        background-color: #bfdc9dda;
        border-radius: 0.3rem;
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        //   margin-bottom: 0.8rem;
        &:hover {
            background-color: #b1d884;
        }
        svg {
            width: 1.6rem;
            height: 1.6rem;
        }
        h6 {
            font-size: 1rem;
            max-width: 24rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            line-height: 1.4;
            margin: 0;
        }
        .file-box {
            padding: 0.3rem 0.4rem;                
            display: flex;
            gap: 0.4rem;
            align-items: center;
            cursor: pointer;
            text-decoration: none;

            svg {
                width: 1.6rem;
                height: 1.6rem;
            }
            h6 {
                font-size: 1rem;
                max-width: 22rem;
                white-space: nowrap;
                overflow: hidden;           
                text-overflow: ellipsis;
                line-height: 1;
            }
        }
        .check_file {
            display: inline-block;
            position: absolute !important;
            top: 0.34rem;
            left: 0.7rem;
        }
        .vieweye-box{
            color: $primary;
            display: inline-block;
            position: absolute;
            top: 0.3rem;
            right: 0.5rem;
            cursor: pointer;
            svg {
                width: 1.2rem;
                height: 1.2rem;
            }
            &:hover {
                color: $primary;
            }
        }
        .progress_bar {
            margin: 0 0.4rem;
        }
    }
    .remove-box {
        color: $danger;
        display: inline-block;
        cursor: pointer;
        svg {
            width: 1.2rem;
            height: 1.2rem;
        }
        &:hover {
            color: $primary;
        }
    }
}