/* ==============
  Widgets
===================*/

.widget-chart li {
    width: 31.5%;
    display: inline-block;
    padding: 0;
    i {
        font-size: 22px;
    }
}

.mini-stat {
    .mini-stat-img{
        width: 58px;
        height: 58px;
        line-height: 58px;
        background: rgba($white, 0.15);
        border-radius: 4px;
        text-align: center;
        img{
            max-width: 32px;
        }
    }
    .mini-stat-label{
        position: absolute;
        right: 0;
        top: 18px;
        padding: 2px 10px 2px 32px;
        clip-path: polygon(100% 0, 100% 50%, 100% 100%, 0% 100%, 24% 50%, 6% 0);
    }
}

.wid-peity{
    border-bottom: 1px solid $gray-200;
}

.ct-chart{
    &.earning{
        height: 284px;
        .ct-grid{
            strokeWidth: 1px;
        }
    }
    &.wid{
        height: 220px;
        .ct-slice-donut{
            strokeWidth: 14px !important;
        }
    }
}
