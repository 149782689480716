/* ==============
  Pagination
===================*/

.table-pagination{
  .pagination{
    margin: 0;
    .page-link {
      color: $primary;
      width: 2.3rem;
      height: 2.3rem;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 0;
      line-height: normal;
      &:focus, &:hover {
        box-shadow: none;
        color: $dark;
        background-color: $gray-200;
      }
      span {
        font-size: 1.5rem;
        position: absolute;
        top: 40%;
        transform: translateY(-50%);
        line-height: 0;
        display: flex;
        align-items: center;
      }
      svg {
        height: 0.75rem;
      }
    }
    .page-item {
      .page-link {
        &:hover, &:focus {
          cursor: pointer;
          background: lighten($primary, 60%);
          color: $primary;
          box-shadow: none;
          outline: none;
          text-decoration: none;
        }
      }
      &.active {
        .page-link {
          background-color: $primary;
          border-color: $primary;
          color: $white !important; 
        }
      }
      &.disabled {
        .page-link {
          background-color: $gray-200;
          pointer-events: none;
          cursor: not-allowed;
        }
      }
    }
  }
}


.dataTables_paginate{
  .pagination{
    .page-link {
      color: $primary;
      &:focus, &:hover {
        box-shadow: none;
        color: $dark;
        background-color: $gray-200;
      }
    }
    .page-item{
      .page-link {
        &:hover, &:focus {
          cursor: pointer;
          background: lighten($primary, 60%);
          color: $primary;
          box-shadow: none;
          outline: none;
          text-decoration: none;
        }
        .sr-only {
          border: 0;
          clip: rect(0,0,0,0);
          height: 1px;
          margin: -1px;
          overflow: hidden;
          padding: 0;
          position: absolute;
          width: 1px;
        }
      }
      &.active{
        .page-link{
          background-color: $primary;
          border-color: $primary;
          color: $white !important; 
        }
      }
    }
  }
}