.top-menu {
  margin-top: 70px;
  // background: lighten($primary, 59%);
  background: #E6E3F0;
  position: sticky;
  top: 70px;
  z-index: 99;
  width: 100%;
  box-shadow: 0 0.2rem 0.5rem 0 rgb(0 0 0 / 12%);
  .metismenu {
    display: flex;
    list-style: none;
    // gap: 1rem;
    padding: 0 0.5rem;
    min-height: 4rem;
    > li {
      display: inline-flex;
      align-items: center;
      position: relative;
      padding: 0.3rem 0;
      line-height: normal;
      &:hover, &:focus {
        > a {
          color: $info;
          transition: all 0.3s ease;
          -webkit-transition: all 0.3s ease;
          -moz-transition: all 0.3s ease;
          -ms-transition: all 0.3s ease;
          -o-transition: all 0.3s ease;
        }
        > .submenu {
          display: inline-block !important;
          height: auto !important;
          &.megamenu {
            display: flex !important;
            height: auto !important;
          }
        }
      }
      &.active, &.mm-active {
        > a {
          background: $primary;
          color: $white;
        }
      }
      > a {
        padding: 0.3rem 0.5rem;
        display: flex;
        align-items: center;
        text-align: center;
        flex-wrap: wrap;
        justify-content: center;
        color: $dark;
        gap: 0.2rem;
        border-radius: 8px;
        -webkit-border-radius: 8px;
        -moz-border-radius: 8px;
        -ms-border-radius: 8px;
        -o-border-radius: 8px;
        height: 100%;
        line-height: normal;
        transition: all 0.3s ease;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        > svg {
          line-height: normal;
          width: 1.3rem;
          height: 1.3rem;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        span {
          display: flex;
          align-items: center;
          line-height: normal;
          .menu-arrow {
            i {
              font-size: 1.2rem;
              line-height: normal;
              transform: rotate(90deg);
              -webkit-transform: rotate(90deg);
              -moz-transform: rotate(90deg);
              -ms-transform: rotate(90deg);
              -o-transform: rotate(90deg);
              transition: all 0.3s ease;
              -webkit-transition: all 0.3s ease;
              -moz-transition: all 0.3s ease;
              -ms-transition: all 0.3s ease;
              -o-transition: all 0.3s ease;
            }
          }
        }
      }
      > .submenu {
        display: none;
        position: absolute;
        left: 50%;
        top: 100%;
        width: auto;
        min-width: 100%;
        background: #fff;
        z-index: 999;
        border-radius: 0 0 8px 8px;
        box-shadow: 0rem 0.2rem 0.4rem hsl(0deg 0% 0% / 20%);
        transform: translateX(-50%);
        &.mm-collapse {
          > .has-submenu.nested-menu {
            position: relative;
            &:hover, &:focus {
              > .submenu.mm-collapse {
                display: inline-block !important;
                height: auto !important;
                transition: all 0.3s ease;
                //-webkit-animation: menu-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
                //animation: menu-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
                -webkit-transition: all 0.3s ease;
                -moz-transition: all 0.3s ease;
                -ms-transition: all 0.3s ease;
                -o-transition: all 0.3s ease;
              }
            }
            > .submenu.mm-collapse {
              position: absolute;
              left: 100%;
              top: 0;
              background: #fff;
              z-index: 999;
              border-radius: 5px;
              box-shadow: 0.2rem 0.2rem 0.4rem hsl(0deg 0% 0% / 20%);
              display: none;
              height: 0;
              transition: all 0.3s ease;
              -webkit-transition: all 0.3s ease;
              -moz-transition: all 0.3s ease;
              -ms-transition: all 0.3s ease;
              -o-transition: all 0.3s ease;
            }
          }
        }
        li {
          a {
            position: relative;
            white-space: pre;
            transition: all 0.3s ease;
            -webkit-transition: all 0.3s ease;
            -moz-transition: all 0.3s ease;
            -ms-transition: all 0.3s ease;
            -o-transition: all 0.3s ease;
            &.mm-active, &.mm-active:hover, &.mm-active:focus {
              background: $primary;
              color: $white;              
            }
            &:hover,&:focus {
              background: lighten($primary, 62%);
              color: $primary;
            }
            .menu-arrow {
              position: absolute;
              right: 0.3rem;
              top: 50%;
              transform: translateY(-50%);
              -webkit-transform: translateY(-50%);
              -moz-transform: translateY(-50%);
              -ms-transform: translateY(-50%);
              -o-transform: translateY(-50%);
            }
          }
        }
      }
    }
  }
}
.submenu li a {
  padding: 8px 20px 8px 10px;
  color: $leftbar-menu-color;
  display: block;
  transition: all 0.5s;
  &:focus {
    background-color: left-bar;
  }

  &:hover {
    background-color: $left-bar;
    color: rgba($white, 0.8);
  }
}
.submenu li.mm-active {
  >a {
    color: $leftbar-menu-active-color;
    background-color: $left-bar;
  }
}
.topbar {
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999;

  .topbar-left {
    background: $left-bar;
    float: left;
    text-align: center;
    height: 72px;
    position: relative;
    width: 240px;
    z-index: 1;
    transition: all 0.3s ease;
    .logo {
      line-height: 70px;
      width: 100%;
      height: 100%;
      display: inline-block;
      transition: all 0.3s ease;
      span {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        padding: 0.5rem;
        transition: all 0.3s ease;
        img {
          max-width: 85%;
          transition: all 0.3s ease;
        }
      }
      i {
        display: none;
      }
    }
  }
}

.navbar-custom {
  background-color: $topbar-background;
  border-radius: 0;
  margin-bottom: 0;
  padding: 0 10px 0 0;
  margin-left: 240px;
  min-height: 70px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .nav-link {
    padding: 0 4px;
  }
  .navbar-right {
    .dropdown-toggle {
      &:after {
        content: initial;
      }
    }
    .notification-list{
      .nav-link{
        line-height: 70px !important;
      }
    }
  }
  .list-inline {
    display: initial;
    .float-left {
      .button-menu-mobile {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        font-family: Roboto, sans-serif;
        line-height: 1;
        user-select: none;
        cursor: pointer;
        border-radius: 8px;
        width: 34px;
        height: 34px;
        margin-top: 1.2rem; 
        font-size: 1.2rem;
        overflow: hidden;
        transition: all 0.2s ease-in-out 0s;
        background: lighten($primary, 60%);
        color: $primary;
        padding-bottom: 2px;
        &:hover, &:focus {
          color: $white;
          background: $primary;
          transition: all 0.2s ease-in-out 0s;
        }
      }
    }
  }
}

.logo {
  color: $dark !important;
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;

  span {
    span {
      color: $primary;
    }
  }
}

.side-menu {
  width: 240px;
  z-index: 10;
  background: $left-bar;
  bottom: 0;
  margin-top: 0;
  padding-bottom: 30px;
  position: fixed;
  top: 70px;
  height: calc(100vh - 70px);
  transition: all 0.3s ease;
  .waves-effect {
    .waves-ripple {
      background-color: rgba($left-bar, 0.4);
    }
  }
}

// .enlarged .side-menu {
//   position: absolute;
// }

.content-page {
  // margin-left: 240px;
  overflow: hidden;
  .content {
    padding: 20px;
    // margin-top: 70px;
    background: $bg-body;
    // border-radius: 8px 8px 0 0;
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    min-height: calc(100vh - 141px);
  }
}

.button-menu-mobile {
  border: none;
  color: lighten($dark, 20%);
  display: inline-block;
  height: 70px;
  width: 60px;
  background-color: $topbar-background;
  font-size: 24px;
}


#sidebar-menu>ul>li>a {
  color: $leftbar-menu-color;
  font-size: 0.9rem;
  transition: all 0.5s;
  font-family: $font-family-secondary;
  position: relative;
  outline: 0px;
  border: 0px;
  margin: 0px 0px 4px;
  cursor: pointer;
  user-select: none;
  appearance: none;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  text-decoration: none;
  box-sizing: border-box;
  text-align: left;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  padding: 0.8rem 1.3rem;
  border-radius: 8px;
  line-height: normal;
  &:hover,
  &:focus,
  &:active {
    color: $leftbar-menu-hover-color;
    text-decoration: none;
    background-color: lighten($primary, 60%);
    // box-shadow: 0 4px 20px rgba($dark, 0.7);
  }
}

#sidebar-menu>ul>li>a>span {
  vertical-align: middle;
  margin-left: 7px;
  width: 100%;
  .menu-arrow {
    margin-right: -1rem;
  }
}

#sidebar-menu {
  padding-top: 10px;

  .badge {
    margin-top: 7px;
  }

  li.mm-active {
    .menu-arrow i {
      -ms-transform: rotate(90deg);
      -webkit-transform: rotate(90deg);
      -o-transform: rotate(90deg);
      transform: rotate(90deg);
    }
  }

  ul {
    li {
      a {
        i {
          vertical-align: middle;
          font-size: 1.1rem;
          display: inline-block;
          margin-right: 0.3rem;
        }
      }
      .submenu {
        li {
          a {
            &:hover, &:focus {
              color: $primary;
            }
          }
        }
      }
    }
  }
}

#sidebar-menu>ul>li>a.mm-active {
  color: $leftbar-menu-active-color !important;
  background-color: lighten($primary, 60%);
  // box-shadow: 0 4px 20px rgba($dark, 0.7);
}

.menu-title {
  padding: 12px 20px !important;
  letter-spacing: 1px;
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  color: lighten($leftbar-menu-color, 15%);
}

.enlarged {
  .slimScrollDiv {
    overflow: inherit !important;
  }

  .slimScrollBar {
    visibility: hidden;
  }
}

.enlarged #wrapper {
  .navbar-custom {
    margin-left: 70px;
  }

  #sidebar-menu {

    .menu-title,
    .menu-arrow,
    .badge {
      display: none !important;
    }

    .mm-collapse.mm-show {
      display: none;
    }
    
    .nav.mm-collapse {
      height: inherit !important;
    }

    ul {
      ul {
        margin-top: -2px;
        padding-bottom: 5px;
        padding-top: 5px;
        z-index: 9999;
        background-color: $left-bar;
      }
    }
  }

  .left.side-menu {
    width: 70px;
    z-index: 5;
    transition: all 0.3s ease;
    .scrollbar-container {
      overflow: visible !important;
    }

    #sidebar-menu>ul>li>a {
      padding: 1rem 0.8rem;
      min-height: 50px;

      &:hover,
      &:active,
      &:focus {
        color: $white !important;
        background-color: lighten($primary, 60%);
        box-shadow: 0 4px 20px rgba($dark, 0.7);
      }

      i {
        font-size: 18px;
        margin-left: 5px;
        margin-right: 20px !important;
      }
    }
    #sidebar-menu {
      ul>li {
        position: relative;
        white-space: nowrap;
        &:hover>a {
          position: relative;
          width: 260px;
          color: $primary !important;
          background-color: lighten($primary, 60%);
        }

        &:hover>ul {
          display: block;
          left: 70px;
          position: absolute;
          width: 190px;
          height: auto !important;

          a {
            box-shadow: none;
            padding: 8px 20px;
            position: relative;
            width: 190px;
            z-index: 6;

            &:hover {
              color: $white;
            }
          }
        }

        &:hover {
          a {
            span {
              display: inline;
            }
          }
        }
      }

      ul {
        ul {
          li {
            &:hover>ul {
              display: block;
              left: 190px;
              margin-top: -36px;
              position: absolute;
              width: 190px;
            }
          }

          li>a {
            span.float-right {
              -ms-transform: rotate(270deg);
              -webkit-transform: rotate(270deg);
              position: absolute;
              right: 20px;
              top: 12px;
              transform: rotate(270deg);
            }
          }

          li.mm-active {
            a {
              color: $white;
            }
          }
        }
      }

      ul>li>a {
        span {
          display: none;
          padding-left: 10px;
        }
      }
    }

    .user-details {
      display: none;
    }
  }

  .content-page {
    margin-left: 70px;
  }

  .footer {
    left: 70px;
  }

  .topbar {
    .topbar-left {
      width: 70px !important;

      .logo {
        span {
          display: none;
          opacity: 0;
        }

        i {
          color: $primary !important;
          display: flex;
          align-items: center;
          justify-content: center;
          line-height: 70px;
          width: 100%;
          height: 100%;
          transition: all 0.3s ease;
          img {
            max-width: 60%;
            transition: all 0.3s ease;
          }
        }
      }
    }

  }

  #sidebar-menu>ul>li {
    &:hover>a.open {
      :after {
        display: none;
      }
    }

    &:hover>a.mm-active {
      :after {
        display: none;
      }
    }
  }
}
.submenu.megamenu {
  > li {
    > .mm-collapse {
      display: inline-block !important;
    }
  }
}
/* Footer */
.footer {
  bottom: 0;
  text-align: center !important;
  padding: 0.7rem 1rem;
  position: absolute;
  background-color: $footer-background;
  right: 0;
  left: 240px;
  font-family: $font-family-secondary;
}

.backend-footer{  
  // position: fixed;
  width: 100%;
  // bottom: 0;
  // left: 0;
  z-index: 99;
  padding: 0.4rem 0;
  background-color: #b4c9e7;
  text-align: center;
  p{
    color: #002E70;
    line-height: normal;
  }
} 
.login-footer{
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 99;
  padding: 0.4rem 0;
  background-color: #b4c9e7;
  text-align: center;
  p{
    color: #002E70;
    line-height: normal;
  }
}

/* Notification */

.notification-item-list {
  max-height: 230px;
}

/* ======== project task maneger btn ======== */

.project-nav-link{
  background: rgba(79, 45, 127, 0.1294117647);
  padding: 0.3rem 0.7rem !important;
  border-radius: 30px !important;
  color: #002E70 !important;
  position: relative;
  border: none;
  transition: all 0.3s ease-in !important;
  box-shadow: 0.1rem 0.1rem 0.1rem rgb(0 0 0 / 21%);
  &:hover,&:focus{
    color: #ffffff !important;
    background: #002E70 !important;
    border: none !important;
    box-shadow: 0.1rem 0.1rem 0.4rem rgb(0 0 0 / 29%);
  }
  svg{
    width: 1.5rem;
    height: 1.4rem;
    margin-right: 0.2rem;
  }
  .project_nav_badge{
    background-color: rgb(222,0,46);
    width: 0.5rem;
    // box-shadow: 0 0 0.1rem 0.3rem #de002e42;
    height: 0.5rem;
    border-radius: 50%;
    animation: animate-pulse 2s linear infinite;
    position: absolute;
    top: 9%;
    left: 95%;
    transform: translate(-50%, -50%);
  }
}
@keyframes animate-pulse{
  0%{
      box-shadow: 0 0 0 0 rgba(222,0,46,0.7),  0 0 0 0 rgba(222,0,46,0.7);
  }
  40%{
      box-shadow: 0 0 0 0.4rem rgba(222,0,46,0.0),  0 0 0 0 rgba(222,0,46,0.7);
  }
  80%{
      box-shadow: 0 0 0 0.4rem rgba(255,109,74,0.0),  0 0 0 0.15rem rgba(222,0,46,0);
  }
  100%{
      box-shadow: 0 0 0 0 rgba(222,0,46,0.0),  0 0 0 0.15rem rgba(222,0,46,0);
  }
  
}
/* ======== project task maneger btn== End ======== */
.notification-list {
  &.list-inline-item:not(:last-child) {
    margin-right: 0;
  }
  .notification-icon {
    font-size: 24px;
    vertical-align: middle;
    color: lighten($dark, 20%);
  }
  .notification-icon-badge {
    display: inline-block;
    position: absolute;
    top: 16px;
    right: 12px;
  }
  .notify-item {
    padding: 10px 20px;
    .notify-icon {
      float: left;
      height: 36px;
      width: 36px;
      line-height: 36px;
      text-align: center;
      margin-right: 10px;
      border-radius: 50%;
      i {
        height: 32px;
        width: 32px;
        border-radius: 50%;
        line-height: 32px;
        margin-top: 2px;
        color: $white;
      }
    }
    .notify-details {
      margin-bottom: 0;
      overflow: hidden;
      margin-left: 45px;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-weight: 600;
      font-family: $font-family-secondary;
      span {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
        font-size: 12px;
        font-weight: normal;
        font-family: $font-family;
      }
    }
  }
  .language-switch {
    a {
      img {
        float: right;
      }
    }
  }
  .profile-dropdown {
    .notify-item {
      padding: 4px 20px;
    }
  }
  .nav-link {
    padding: 0 15px;
    line-height: 70px;
    color: lighten($dark, 20%);
    display: block;
  }
  &.show {
    .nav-link {
      background-color: rgba($dark, 0.05);
    }
  }
  .dropdown-menu-lg.dropdown-menu {
    margin: 0;
    padding: 0;
    box-shadow: 0.1rem 0.1rem 0.5rem rgba($dark, 0.15);
    h6.dropdown-item-text {
      background: #f4f1fa;
      color: $primary;
      margin: 0;
      padding: 0.8rem 1rem;
      font-weight: 500;
      border-bottom: 1px solid #e8e0f4;
    }
    > div {
      height: auto !important;
      max-height: 250px;
      overflow: auto !important;
      > div { 
        overflow: auto !important;
        position: relative !important;
        margin: 0 !important;
        float: left;
        a {
          &:hover, &:focus {
            background: lighten($primary, 63%);
            color: $dark;
            cursor: pointer;
          }
        }
      }
    }
    a.notify-all {
      background: #f4f1fa;
      border-top: 1px solid #e8e0f4;
      &:hover, &:focus {
        cursor: pointer;
        color: $primary;
        background: #fff;
      }
    }
  }
}

.profile-dropdown {
  width: auto;
  padding-top: 0;
  padding-bottom: 0;
  .dropdown-item-text {
    white-space: pre;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 0.6rem 1rem;
    margin-top: 0;
    display: flex;
    align-items: center;
    line-height: normal;
  }
  .dropdown-divider {
    margin: 0;
  }
  .dropdown-item {
    padding: 0.55rem 1rem;
    color: $gray-600;
    transition: all 0.3s ease;
    i {
      font-size: 1rem;
      vertical-align: baseline;
      margin-right: 0.5rem;
    }
    &:hover, &:focus {
      background: lighten($primary, 60%);
      color: $primary;
      transition: all 0.3s ease;
    }
  }

  span {
    margin-top: 3px;
  }
}

.profile-user {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  &:hover, &:focus {
    cursor: pointer;
    color: $info;
    b{
      span{
        color: $info;
      }
    }
  }
  img {
    height: 36px;
    width: 36px;
  }
  b{
    font-size: 0.9rem;
    display: flex;
    flex-direction: column;
    line-height: 20px;
    span{
      font-size: 1.2rem;
      color: #002E70;
    }
  }
}

.arrow-none:after {
  border: none;
  margin: 0;
  display: none;
}

.dropdown-menu-lg {
  width: 300px;
}

.app-search {
  position: relative;
  display: table;
  height: 70px;

  .form-group {
    display: table-cell;
    vertical-align: middle;
  }

  .form-control,
  .form-control:focus {
    border: 1px solid $topbar-search-background;
    font-size: 13px;
    height: 34px;
    padding-left: 18px;
    padding-right: 40px;
    margin-right: 16px;
    background: $topbar-search-background;
    box-shadow: none;
    border-radius: 30px;
    width: 200px;
  }

  button {
    position: absolute;
    top: 26px;
    right: 26px;
    display: block;
    color: $muted;
    font-size: 11px;
    border: none;
    background-color: transparent;
  }
}

.nav-user {
  img {
    margin-top: -3px;
  }
}

// page-title
.page-title-box {
  padding: 20px 0px;

  .page-title {
    font-size: 18px;
    margin: 0;
    line-height: 30px;
    font-weight: 700;
  }

  .breadcrumb {
    padding: 4px 0;
    background-color: transparent;
    margin-bottom: 0;

    a {
      color: $dark;

      &:hover {
        color: rgba($dark, 0.9);
      }
    }

    .active {
      color: rgba($dark, 0.7);
    }
  }
}

@-webkit-keyframes menu-right {
  0% {
    left: 0;
  }
  100% {
    left: 100%;
  }
}
@keyframes menu-right {
  0% {
    left: 0;
  }
  100% {
    left: 100%;
  }
}

@media screen and (max-width: 1580px) {
  .top-menu {
    .metismenu {
      gap: 0.9rem;
      > li {
        > a {
          font-size: 0.8rem;
          > i {
            font-size: 130%;
          }
          > span {
            gap: 0.2rem;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1480px) {
  .top-menu {
    .metismenu {
      gap: 0.8rem;
      > li {
        > a {
          font-size: 0.75rem;
          > i {
            font-size: 120%;
          }
          > span {
            gap: 0.2rem;
            white-space: nowrap;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1380px) {
  .top-menu {
    .metismenu {
      gap: 0.8rem;
      > li {
        > a {
          font-size: 0.68rem;
          > i {
            font-size: 120%;
          }
          > span {
            gap: 0rem;
          }
        }
      }
    }
  }
}