@font-face {
    font-family: 'Montserrat';
    src: url('../assets/fonts/Montserrat-Black.eot');
    src: url('../assets/fonts/Montserrat-Black.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/Montserrat-Black.woff2') format('woff2'),
        url('../assets/fonts/Montserrat-Black.woff') format('woff'),
        url('../assets/fonts/Montserrat-Black.ttf') format('truetype'),
        url('../assets/fonts/Montserrat-Black.svg#Montserrat-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../assets/fonts/Montserrat-SemiBold.eot');
    src: url('../assets/fonts/Montserrat-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/Montserrat-SemiBold.woff2') format('woff2'),
        url('../assets/fonts/Montserrat-SemiBold.woff') format('woff'),
        url('../assets/fonts/Montserrat-SemiBold.ttf') format('truetype'),
        url('../assets/fonts/Montserrat-SemiBold.svg#Montserrat-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../assets/fonts/Montserrat-Bold.eot');
    src: url('../assets/fonts/Montserrat-Bold.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/Montserrat-Bold.woff2') format('woff2'),
        url('../assets/fonts/Montserrat-Bold.woff') format('woff'),
        url('../assets/fonts/Montserrat-Bold.ttf') format('truetype'),
        url('../assets/fonts/Montserrat-Bold.svg#Montserrat-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../assets/fonts/Montserrat-ExtraBold.eot');
    src: url('../assets/fonts/Montserrat-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/Montserrat-ExtraBold.woff2') format('woff2'),
        url('../assets/fonts/Montserrat-ExtraBold.woff') format('woff'),
        url('../assets/fonts/Montserrat-ExtraBold.ttf') format('truetype'),
        url('../assets/fonts/Montserrat-ExtraBold.svg#Montserrat-ExtraBold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../assets/fonts/Montserrat-Light.eot');
    src: url('../assets/fonts/Montserrat-Light.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/Montserrat-Light.woff2') format('woff2'),
        url('../assets/fonts/Montserrat-Light.woff') format('woff'),
        url('../assets/fonts/Montserrat-Light.ttf') format('truetype'),
        url('../assets/fonts/Montserrat-Light.svg#Montserrat-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../assets/fonts/Montserrat-Medium.eot');
    src: url('../assets/fonts/Montserrat-Medium.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/Montserrat-Medium.woff2') format('woff2'),
        url('../assets/fonts/Montserrat-Medium.woff') format('woff'),
        url('../assets/fonts/Montserrat-Medium.ttf') format('truetype'),
        url('../assets/fonts/Montserrat-Medium.svg#Montserrat-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../assets/fonts/Montserrat-Regular.eot');
    src: url('../assets/fonts/Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/Montserrat-Regular.woff2') format('woff2'),
        url('../assets/fonts/Montserrat-Regular.woff') format('woff'),
        url('../assets/fonts/Montserrat-Regular.ttf') format('truetype'),
        url('../assets/fonts/Montserrat-Regular.svg#Montserrat-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}




// Color variables
$white: #ffffff;
$black: #000000;

$primary: #002E70;
$success: #9BD732;
$info: #00A7B5;
$warning: #f8b425;
$orange: #C24200;
$danger: #DE002E;
$dark: #2a3142;
$muted: #9ca8b3;

// Gray Colors
$gray-100:                  #f8f9fa;
$gray-200:                  #e9ecef;
$gray-300:                  #dee2e6;
$gray-400:                  #ced4da;
$gray-500:                  #adb5bd;
$gray-600:                  #6c757d;
$gray-700:                  #495057;
$gray-800:                  #343a40;
$gray-900:                  #212529;

// $bg-body: #E8ECF2;
$bg-body: #eef0fa80;
$left-bar: #fff;
$topbar-background: #fff;
$topbar-search-background: #e0e0ea;
$footer-background: #f1f2f5;

$font-family: 'Montserrat', sans-serif;
$font-family-secondary: 'Helvetica Neue', sans-serif;

$shadow: 0px 0px 13px 0px rgba(236, 236, 241, 0.44);

$base-font: clamp(0.8rem, 0.9vw, 1rem);

// Width variables
$width: 100%;

// Height variables
$height: 100%;

$leftbar-menu-color: #616161;
$leftbar-menu-hover-color: #5e35b1;
$leftbar-menu-active-color: #5e35b1;
